import React, { useState } from 'react';
import axios from 'axios';

import API_ENDPOINTS from '../../const/ApiEndPoints';
import { isValueEmpty } from '../../utils/Utils';

export default function useTemplate() {
  const [entityList, setEntityList] = useState([]);
  const [sectionData, setSectionData] = React.useState();
  const customEntityList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        if (res?.data) {
          setEntityList(isValueEmpty(res.data) ? [] : res.data);
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSingleEntity = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_SINGLE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createCustomEntity = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateCustomEntity = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getCustomEntityTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_ATTRIBUTES}`,
        { params: body }
      )
      .then((res) => {
        setSectionData(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteTemplate = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.DELETE_TEMPLATE}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const reorderTemplate = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.REORDER_TEMPLATE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const checkTemplateForAffectedEntities = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CHECK_TEMPLATE}`,
        {
          params,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    checkTemplateForAffectedEntities,
    reorderTemplate,
    deleteTemplate,
    getCustomEntityTemplate,
    getSingleEntity,
    customEntityList,
    createCustomEntity,
    updateCustomEntity,
    entityList,
    sectionData,
    setSectionData,
    setEntityList,
  };
}
