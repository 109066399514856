// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Stack, Avatar, Checkbox } from '@mui/material';
import moment from 'moment';
import propTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import { MODULE_PATHS } from '../../../const/CommonConst';
import SwalToast from '../../Common/swalToast';
import useCommon from '../../../hooks/useCommon';
import ConfirmModal from '../../Common/Modals/ConfirmModal';

const DATE_FORMAT = 'YYYY / MM / DD';

const NotificationSkeleton = () => {
  return (
    <Stack direction="column" spacing={3}>
      <Box
        sx={{
          padding: '1px',
        }}
      >
        <Stack direction="row" padding={1} spacing={2} width="100%">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Skeleton animation="wave" variant="circular">
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </Skeleton>
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Skeleton animation="wave" height={10} />
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="40%" />
          </Box>
          <Box>
            <Skeleton
              animation="wave"
              variant="circular"
              width={15}
              height={15}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

const Notification = ({
  notification,
  userName,
  handleCloseNotifications,
  handleReadUnreadNotifications,
}) => {
  const [navigateToRoute, setNavigateToRoute] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const history = useNavigate();
  const { common, dispatch, setIsWorkflowCompiled } = useCommon();

  /**
   * If workflow is not compile then show modal for confirmation
   *  @name handleModalOpen
   * @returns {void}
   */
  const handleModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  /**
   * Closes the confirmation modal by setting the state value.
   * @function
   * @name closeConfirmModal
   * @returns {void}
   * @example closeConfirmModal()
   */
  const closeConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  /**
   * Confirms the action, if user have compiled the workflow or not.
   *  @name confirm
   * @returns {void}
   */
  const confirm = useCallback(() => {
    closeConfirmModal();
    handleCloseNotifications();
    setTimeout(() => {
      dispatch(setIsWorkflowCompiled(true));
      history(
        {
          pathname: `/${MODULE_PATHS[navigateToRoute?.entityType]}/${
            navigateToRoute?.uniqueId
          }`,
        },
        {
          state: [
            {
              label: notification?.entity_type,
              path: `/${MODULE_PATHS[navigateToRoute?.entityType]}`,
            },
            {
              label: navigateToRoute?.uniqueId,
              path: `/${MODULE_PATHS[navigateToRoute?.entityType]}/${
                navigateToRoute?.uniqueId
              }`,
              type: navigateToRoute?.notification?.entity_type,
            },
          ],
        }
      );
    }, 500);
  }, [dispatch, setIsWorkflowCompiled, navigateToRoute]);

  const getEntityType = (entityType, requestType) => {
    if (entityType === 'ModelAssociation') {
      return 'model-association';
    }

    if (entityType === 'ModelInventory') {
      switch (requestType) {
        case 'ModelRequest':
          return 'model-request';
        case 'AccessRequest':
          return 'access-request';
        default:
          return 'model-inventory';
      }
    }

    return 'model-inventory';
  };

  const handleClick = useCallback(
    (event) => {
      if (event?.target?.tagName !== 'INPUT') {
        const entityType = getEntityType(
          notification?.entity_type,
          notification?.request_type
        );
        const uniqueId = notification?.unique_id;

        if (notification?.is_deleted) {
          SwalToast({
            icon: 'error',
            title: 'This model is deleted.',
          });
        } else if (common?.isWorkflowCompiled) {
          setTimeout(() => {
            history(
              {
                pathname: `/${MODULE_PATHS[entityType]}/${uniqueId}`,
              },
              {
                state: [
                  {
                    label: notification?.entity_type,
                    path: `/${MODULE_PATHS[entityType]}`,
                  },
                  {
                    label: uniqueId,
                    path: `/${MODULE_PATHS[entityType]}/${uniqueId}`,
                    type: notification?.entity_type,
                  },
                ],
              }
            );
          }, 500);
        } else {
          setNavigateToRoute({ entityType, uniqueId, notification });
          handleModalOpen();
          return;
        }

        // If notification status is unread then make it as read.
        if (!notification?.read_status) {
          const toStatus = notification?.read_status ? 'UNREAD' : 'READ';
          handleReadUnreadNotifications(
            toStatus,
            notification?.notification_id,
            true
          );
        } else {
          handleCloseNotifications();
        }
      }
    },
    [
      handleCloseNotifications,
      handleReadUnreadNotifications,
      setNavigateToRoute,
      handleModalOpen,
      setNavigateToRoute,
    ]
  );
  const handleChangeReadUnread = useCallback(() => {
    const toStatus = notification?.read_status ? 'UNREAD' : 'READ';
    handleReadUnreadNotifications(toStatus, notification?.notification_id);
  }, [handleReadUnreadNotifications]);

  return (
    <Box
      sx={{
        '& :hover': {
          background: (theme) => theme?.palette?.secondary?.light,
          cursor: 'pointer',
          borderRadius: '4px',
          '& .MuiCheckbox-root': {
            color: notification?.read_status
              ? (theme) => theme?.palette?.other?.grey4
              : 'default',
          },
        },
        padding: '1px',
        '& .MuiCheckbox-root': {
          color: notification?.read_status ? 'transparent' : 'default',
        },
      }}
      onClick={handleClick}
    >
      <Stack direction="row" padding={1} spacing={2} width="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
              background: (theme) => theme.palette.primary.main,
            }}
          >
            {userName}
          </Avatar>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '4px',
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                wordBreak: 'break-all',
                overflow: 'hidden',
              }}
            >
              {notification?.msg}
              <Typography
                variant="body2"
                sx={{ marginLeft: '8px', fontSize: '16px' }}
              >
                {moment(notification?.created_at).fromNow()}
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            {notification?.entity_type === 'ModelInventory' ? (
              <ModelTrainingIcon color="primary" />
            ) : (
              <PrecisionManufacturingIcon color="primary" />
            )}
            <Typography
              variant="body2"
              sx={{ marginLeft: '8px', fontSize: '16px' }}
            >
              {notification?.entity_name}
            </Typography>
          </Box>
          <Stack direction="row" gap={0.5}>
            <Typography variant="body2" fontSize="13px">
              {notification?.unique_id || notification?.entity_id}
            </Typography>
            <Typography variant="body2">&#x2022;</Typography>
            <Typography variant="body2" fontSize="13px">
              {notification?.entity_status}
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Checkbox
            ariaLabel="Read Unread Notification Button"
            title={
              notification?.read_status ? 'Mark as unread.' : 'Mark as read.'
            }
            checked={!notification?.read_status}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
            onChange={handleChangeReadUnread}
          />
        </Box>
      </Stack>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        confirm={confirm}
        alertLabelText={
          <Typography>{`You have unsaved changes. Do you really want to leave this page
        without saving?`}</Typography>
        }
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </Box>
  );
};

Notification.defaultProps = {
  notification: {},
  userName: '',
};

Notification.propTypes = {
  notification: propTypes.oneOfType([propTypes.object]),
  userName: propTypes.string,
  handleCloseNotifications: propTypes.func.isRequired,
  handleReadUnreadNotifications: propTypes.func.isRequired,
};

const renderNotifications = (
  notifications,
  handleReadUnreadNotifications,
  handleCloseNotifications
) => {
  return notifications?.map((notification) => (
    <Notification
      key={notification?.notification_id}
      notification={notification}
      userName={
        notification?.updated_by?.user
          ? notification?.updated_by?.user.slice(0, 1)
          : 'NA'
      }
      handleCloseNotifications={handleCloseNotifications}
      handleReadUnreadNotifications={handleReadUnreadNotifications}
    />
  ));
};

/**
 * @function getCount
 * Returns the count of unread notifications from the given data.
 * @param {Object[]} data - An array of notification objects.
 * @return {number} The number of unread notifications.
 */
const getCount = (data) => {
  return data?.filter((notification) => !notification?.read_status)?.length;
};

/**
 * @function showNotificationCategory
 * Returns the category of notifications based on the presence of today's notifications.
 * @param {Object[]} todaysNotifications - An array of today's notification objects.
 * @return {string} The category of notifications, either 'TODAY' or 'LATEST'.
 */
const showNotificationCategory = (todaysNotifications) => {
  return todaysNotifications?.length > 0 ? 'TODAY' : 'LATEST';
};
const Direct = ({
  handleCloseNotifications,
  readUnreadNotification,
  userData,
  markAllReadNotifications,
  calculateUnreadNotifications,
  notificationsData,
  setNotificationsData,
  setUnreadNotificationsCount,
  notificationList,
}) => {
  const [olderNotifications, setOlderNotifications] = useState([]);
  const [todaysNotifications, setTodaysNotifications] = useState([]);

  const handleReadUnreadNotifications = useCallback(
    (toState, notificationId, isClickOnNavigateToModel = false) => {
      if (isClickOnNavigateToModel) {
        setNotificationsData((prev) => {
          const data = prev?.map((notification) =>
            notification?.notification_id === notificationId
              ? { ...notification, read_status: toState !== 'UNREAD' }
              : notification
          );
          const count = getCount(data);
          setUnreadNotificationsCount(count);

          return data;
        });
        handleCloseNotifications();
      } else {
        setNotificationsData((prev) =>
          prev.map((notification) =>
            notification?.notification_id === notificationId
              ? { ...notification, read_status: toState !== 'UNREAD' }
              : notification
          )
        );
      }
      readUnreadNotification({
        toState,
        ids: [notificationId],
        user: userData?.username,
      });
    },
    [
      readUnreadNotification,
      setNotificationsData,
      handleCloseNotifications,
      setUnreadNotificationsCount,
    ]
  );

  useEffect(() => {
    calculateUnreadNotifications(olderNotifications, todaysNotifications);
  }, [olderNotifications, todaysNotifications]);

  const handleMarkReadAllNotifications = useCallback(() => {
    setNotificationsData((prev) =>
      prev?.map((notification) => ({
        ...notification,
        read_status: true,
      }))
    );
    markAllReadNotifications({
      toState: 'READ',
      user: userData?.username,
    });
  }, [setNotificationsData, markAllReadNotifications]);

  useEffect(() => {
    const filteredOlderNotification = notificationsData?.filter(
      (notification) =>
        moment(notification?.created_at).format(DATE_FORMAT) !==
        moment(new Date()).format(DATE_FORMAT)
    );
    setOlderNotifications(filteredOlderNotification);

    const filteredTodaysNotifications = notificationsData?.filter(
      (notification) =>
        moment(notification?.created_at).format(
          DATE_FORMAT === moment(new Date()).format(DATE_FORMAT)
        )
    );
    setTodaysNotifications(filteredTodaysNotifications);
  }, [notificationsData]);

  const hasNotifications =
    todaysNotifications?.length > 0 || olderNotifications?.length > 0;
  const noMoreNotifications =
    notificationList?.length === 0 || notificationsData?.length === 0;
  const hasUnreadNotifications =
    todaysNotifications?.some((n) => !n?.read_status) ||
    olderNotifications?.some((n) => !n?.read_status);

  if (hasNotifications) {
    return (
      <Box>
        {notificationsData?.length > 0 ? (
          <Stack m={1} direction="column" spacing={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>
                {showNotificationCategory(todaysNotifications)}
              </Typography>
              {hasUnreadNotifications && (
                <Typography
                  variant="body4"
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                  onClick={handleMarkReadAllNotifications}
                >
                  Mark all as read
                </Typography>
              )}
            </Box>

            {renderNotifications(
              todaysNotifications,
              handleReadUnreadNotifications,
              handleCloseNotifications
            )}
            {todaysNotifications?.length > 0 &&
              olderNotifications?.length > 0 && <Typography>OLDER</Typography>}
            {renderNotifications(
              olderNotifications,
              handleReadUnreadNotifications,
              handleCloseNotifications
            )}
          </Stack>
        ) : (
          <Box
            display="flex"
            style={{ height: 'calc(99vh  - 200px)' }}
            flexGrow={1}
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="subtitle1">No more notifications.</Typography>
          </Box>
        )}
      </Box>
    );
  }

  if (noMoreNotifications) {
    return (
      <Box
        display="flex"
        style={{ height: 'calc(100vh  - 340px)' }}
        flexGrow={1}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="subtitle1">No more notifications.</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box m={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton animation="wave" height={10} width="20%" />
        <Skeleton animation="wave" height={10} width="30%" />
      </Box>
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
      <NotificationSkeleton />
    </>
  );
};

Direct.defaultProps = {
  notificationsData: [],
  notificationList: [],
  userData: {},
};

Direct.propTypes = {
  notificationsData: propTypes.oneOfType([propTypes.object]),
  userData: propTypes.oneOfType([propTypes.object]),
  notificationList: propTypes.oneOfType([propTypes.object]),
  handleCloseNotifications: propTypes.func.isRequired,
  readUnreadNotification: propTypes.func.isRequired,
  markAllReadNotifications: propTypes.func.isRequired,
  calculateUnreadNotifications: propTypes.func.isRequired,
  setNotificationsData: propTypes.func.isRequired,
  setUnreadNotificationsCount: propTypes.func.isRequired,
};

export default Direct;
