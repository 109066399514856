import React from 'react';
import propTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

const NoDataFoundComponent = ({
  page,
  rows,
  globalFilter,
  data,
  enablePagination,
}) => {
  let message = null;

  if (data?.length <= 0) {
    message = 'No data present.';
  }

  if (!isEmpty(globalFilter) && (enablePagination ? page : rows).length <= 0) {
    message = 'No result found.';
  }

  return (
    <Box>
      {message && (
        <Typography variant="subtitle1" textAlign="center" sx={{ py: '35px' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

NoDataFoundComponent.propTypes = {
  rows: propTypes.oneOfType([propTypes.object]).isRequired,
  page: propTypes.oneOfType([propTypes.object]).isRequired,
  data: propTypes.oneOfType([propTypes.array]).isRequired,
  globalFilter: propTypes.string.isRequired,
  enablePagination: propTypes.bool.isRequired,
};

export default NoDataFoundComponent;
