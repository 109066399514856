// @ts-nocheck
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AccessControl = ({
  renderNoAccess = () => null,
  allowedPermissions,
  children,
}) => {
  const { users } = useSelector((state) => state);
  const checkPermissions = (
    userAllPermissions,
    allowedUserPermissions = []
  ) => {
    if (allowedUserPermissions?.length === 0) {
      return true;
    }
    return userAllPermissions?.some((permission) =>
      allowedUserPermissions?.includes(permission)
    );
  };
  const havePermission = checkPermissions(
    Object.keys(users?.permissions)?.map(
      (permKey) => `${permKey}:${users?.permissions?.[permKey]}`
    ),
    allowedPermissions
  );
  if (havePermission) {
    return children;
  }
  return <>{renderNoAccess()}</>;
};
AccessControl.propTypes = {
  renderNoAccess: PropTypes.func,
  allowedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};
AccessControl.defaultProps = {
  renderNoAccess: () => {},
};

export default AccessControl;
