// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useIssueType() {
  const createIssueType = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ISSUE_TYPE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateIssueType = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.UPDATE_ISSUE_TYPE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllIssueTypesList = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ISSUE_TYPE}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteIssueType = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.DELETE_ISSUE_TYPE}?id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    createIssueType,
    deleteIssueType,
    getAllIssueTypesList,
    updateIssueType,
  };
}
