// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { withTransaction } from '@elastic/apm-rum-react';
import PropTypes from 'prop-types';
import { object, string, ref as yupRef } from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';
import useAuth from '../../hooks/Auth/useAuth';
import useQueryParams from '../../hooks/useQueryParams';
import LoginCarousel from './LoginCarousel';
import REGEX from '../../const/Regex';
import { getErrorHelperText } from '../../utils/Utils';
import { loginSuccess } from '../../store/slices/UserSilcer';
import SwalToast from '../../components/Common/swalToast';

const BOX_SHADOW = '0px 4px 8px rgba(0, 0, 0, 0.2) !important';

const ResetPasswordFormValidationSchema = object({
  password: string()
    .required('Required')
    .min(8, 'Password must contain at least 8 characters.')
    .matches(REGEX.NOT_ONLY_NUMBER, "Password can't be entirely numeric"),
  confirmPassword: string()
    .oneOf([yupRef('password'), null], 'Passwords must match')
    .required('Required'),
});

const formForgetValidationSchema = object({
  email: string().email('Invalid email').required('Required'),
  account_id: string().required('Required'),
});

const ShowWelcomeMsg = () => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      height="100%"
      pl={20}
    >
      <Typography
        sx={{
          fontSize: '2rem',
        }}
        color="text.light"
        variant="h1"
      >
        Welcome
      </Typography>
      <Typography variant="h2" mb={2} color="text.light">
        Login
      </Typography>
    </Stack>
  );
};

const OperationResultMsg = ({ successMessage, errorMessage }) => {
  return (
    <>
      {successMessage && (
        <Alert
          severity="success"
          sx={{ background: (theme) => theme.palette.primary.dark }}
        >
          <Typography variant="body3" color="text.light">
            {successMessage}
          </Typography>
        </Alert>
      )}
      {errorMessage && (
        <Alert
          severity="error"
          sx={{ background: (theme) => theme.palette.primary.dark }}
        >
          <Typography variant="body3" color="text.light">
            {errorMessage}
          </Typography>
        </Alert>
      )}
    </>
  );
};
OperationResultMsg.propTypes = {
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

const SendResetPasswordLink = ({
  handleClose,
  updateErrorMessage,
  updateSuccessMessage,
  forgetPassword,
  errorMessage,
  successMessage,
}) => {
  const handleFormSubmit = (values) => {
    updateSuccessMessage('');
    updateErrorMessage('');

    const body = {
      email: values?.email,
      account_id: values?.account_id,
      domain: window.location.href.split('/')[2],
    };

    forgetPassword(body)
      .then((res) => {
        updateSuccessMessage(res?.data?.success);
      })
      .catch((err) => {
        updateErrorMessage(err?.response?.data?.Failed);
      });
  };

  const ForgetForm = useFormik({
    initialValues: {
      email: '',
      account_id: '',
    },
    enableReinitialize: true,
    validationSchema: formForgetValidationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <Stack direction="column" spacing={3}>
      <Box mb={3}>
        <Typography variant="h3" color="text.light">
          Reset password
        </Typography>

        <Typography color="text.light" variant="body3">
          Please enter your email address. We’ll send you a link to reset your
          password.
        </Typography>
      </Box>
      <OperationResultMsg
        errorMessage={errorMessage}
        successMessage={successMessage}
      />
      <Box mb={3} mt={2}>
        <TextField
          required
          {...ForgetForm?.getFieldProps('email')}
          type="text"
          variant="filled"
          autoComplete="off"
          placeholder="Enter your registered email address"
          label="Enter your email"
          helperText={getErrorHelperText(
            ForgetForm?.errors?.email,
            ForgetForm?.touched?.email
          )}
          error={Boolean(
            getErrorHelperText(
              ForgetForm?.errors?.email,
              ForgetForm?.touched?.email
            )
          )}
        />
      </Box>
      <Box mb={3}>
        <TextField
          required
          {...ForgetForm?.getFieldProps('account_id')}
          type="text"
          variant="filled"
          autoComplete="off"
          label="Enter account Id"
          helperText={getErrorHelperText(
            ForgetForm?.errors?.account_id,
            ForgetForm?.touched?.account_id
          )}
          error={Boolean(
            getErrorHelperText(
              ForgetForm?.errors?.account_id,
              ForgetForm?.touched?.account_id
            )
          )}
        />
      </Box>
      <ActionButtons
        handleClose={handleClose}
        handleSubmit={ForgetForm?.handleSubmit}
      />
    </Stack>
  );
};

SendResetPasswordLink.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateErrorMessage: PropTypes.func.isRequired,
  updateSuccessMessage: PropTypes.func.isRequired,
  forgetPassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

const ActionButtons = ({ handleClose, handleSubmit }) => (
  <Box
    display="flex"
    alignItems="center"
    flexGrow={1}
    flexDirection="column"
    mt={4}
    justifyContent="space-between"
  >
    <Button
      size="large"
      sx={{
        background: (theme) => theme?.palette?.primary.contrastText,
        width: '200px',
        height: '35px',
        '&:hover': {
          backgroundColor: (theme) => theme?.palette?.other?.white,
        },
        boxShadow: BOX_SHADOW,
        borderRadius: '5px',
      }}
      autoFocus
      onClick={handleSubmit}
      variant="contained"
      type="submit"
      fullWidth
    >
      <Typography
        sx={{
          letterSpacing: '2px',
          color: (theme) => theme?.palette?.primary?.dark,
          whiteSpace: 'nowrap',
        }}
        variant="subtitle1"
      >
        Send Reset Link
      </Typography>
    </Button>
    <Button
      size="large"
      sx={{
        marginTop: 2,
        border: (theme) => theme?.palette?.other?.white,
        width: '200px',
        height: '35px',
        borderRadius: '5px',
      }}
      onClick={handleClose}
      variant="outlined"
      fullWidth
    >
      <Typography
        sx={{
          letterSpacing: '2px',
          whiteSpace: 'nowrap',
        }}
        color="other.white"
        variant="subtitle1"
      >
        Back to Login
      </Typography>
    </Button>
  </Box>
);
ActionButtons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const ResetPassword = ({
  handleClose,
  updateSuccessMessage,
  updateErrorMessage,
  resetPassword,
  successMessage,
  errorMessage,
}) => {
  const queryParams = useQueryParams();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
  /**
   * @function changePasswordHidden
   *@description toggle to hide of show password form password field.
   *
   * @returns {void}
   */
  const changePasswordHidden = useCallback(() => {
    setPasswordHidden(!passwordHidden);
  }, [setPasswordHidden, passwordHidden]);

  /**
   * @function changeConfirmPasswordHidden
   *@description toggle to hide of show confirm-password form password field.
   *
   * @returns {void}
   */

  const changeConfirmPasswordHidden = useCallback(() => {
    setConfirmPasswordHidden(!confirmPasswordHidden);
  }, [setConfirmPasswordHidden]);

  const ResetPasswordForm = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: ResetPasswordFormValidationSchema,
    onSubmit: (values) => {
      updateSuccessMessage('');
      updateErrorMessage('');
      const body = {
        password: values.password,
        token: queryParams.get('token'),
        uidb64: queryParams.get('uidb64'),
        account_id: queryParams.get('account_id'),
      };
      resetPassword(body)
        .then((res) => {
          updateSuccessMessage(res.data.message);
        })
        .catch((err) => {
          updateErrorMessage(err.response.data.detail);
        });
    },
  });
  return (
    <Box pb={4} pt={1} mb={4}>
      <Box mb={3}>
        <Typography variant="h3" color="text.light">
          Reset password
        </Typography>

        <Typography variant="body3" color="text.light">
          Enter new password
        </Typography>
      </Box>
      {successMessage || errorMessage ? (
        <OperationResultMsg
          errorMessage={errorMessage}
          successMessage={successMessage}
        />
      ) : null}

      <Box mb={3} mt={3}>
        <TextField
          required
          {...ResetPasswordForm?.getFieldProps('password')}
          label="Password"
          variant="filled"
          type={!passwordHidden ? 'text' : 'password'}
          autoComplete="off"
          helperText={getErrorHelperText(
            ResetPasswordForm?.errors?.password,
            ResetPasswordForm?.touched?.password
          )}
          error={Boolean(
            getErrorHelperText(
              ResetPasswordForm?.errors?.password &&
                ResetPasswordForm?.touched?.password
            )
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  aria-label="toggle password visibility"
                  onClick={changePasswordHidden}
                  edge="end"
                  size="large"
                >
                  {!passwordHidden ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box mb={3}>
        <TextField
          required
          {...ResetPasswordForm?.getFieldProps('confirmPassword')}
          placeholder="Re-enter your new password"
          label="Re-enter new password"
          variant="filled"
          type={!confirmPasswordHidden ? 'text' : 'password'}
          autoComplete="off"
          helperText={getErrorHelperText(
            ResetPasswordForm?.errors?.confirmPassword,
            ResetPasswordForm?.touched?.confirmPassword
          )}
          error={Boolean(
            getErrorHelperText(
              ResetPasswordForm?.errors?.confirmPassword,
              ResetPasswordForm?.touched?.confirmPassword
            )
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  aria-label="toggle password visibility"
                  onClick={changeConfirmPasswordHidden}
                  edge="end"
                  size="large"
                >
                  {!confirmPasswordHidden ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexGrow={1}
        flexDirection="column"
        mt={4}
        justifyContent="space-between"
      >
        <Button
          size="large"
          sx={{
            background: (theme) => theme.palette.primary.contrastText,
            width: '200px', // Increase the width
            height: '35px', // Reduce the height
            '&:hover': {
              backgroundColor: (theme) => theme.palette.other.white,
            },
            boxShadow: BOX_SHADOW,
            borderRadius: '5px',
          }}
          onClick={ResetPasswordForm.handleSubmit}
          variant="contained"
          fullWidth
        >
          <Typography
            sx={{
              letterSpacing: '2px',
              whiteSpace: 'nowrap',
            }}
            color="primary.dark"
            variant="subtitle1"
          >
            Set New Password
          </Typography>
        </Button>

        <Button
          size="large"
          sx={{
            marginTop: 2,
            border: (theme) => theme.palette.other.white,
            width: '200px', // Increase the width
            height: '35px', // Reduce the height
            borderRadius: '5px',
          }}
          onClick={handleClose}
          variant="outlined"
          fullWidth
        >
          <Typography
            sx={{
              letterSpacing: '2px',
              color: (theme) => theme.palette.other.white,
              whiteSpace: 'nowrap',
            }}
            variant="subtitle1"
          >
            Back to Login
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
ResetPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  updateErrorMessage: PropTypes.func.isRequired,
  updateSuccessMessage: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

const Login = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [forgetPasswordForm, setForgetPasswordForm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openResettingPasswordModal, setOpenResettingPasswordModal] =
    useState(false);
  const [hidden, setHidden] = useState(true);

  const dispatch = useDispatch();

  const { login, forgetPassword, resetPassword } = useAuth();
  const queryParams = useQueryParams();

  const { isAuthenticate } = useSelector((state) => state.users);
  // Check if the user is already authenticated

  useEffect(() => {
    if (localStorage.getItem('auth') || isAuthenticate) {
      // Redirect to the dashboard if the user is already logged in
      navigate('/dashboard?value=0', { replace: true });
    }
  }, [isAuthenticate, navigate]);

  const loginForm = useFormik({
    initialValues: {
      username: '',
      password: '',
      account_id: '',
    },
    enableReinitialize: true,
    validationSchema: object({
      username: string().required('Required'),
      password: string().required('Required'),
      account_id: string().required('Required'),
    }),
    onSubmit: (values) => {
      login(values).then(() => {
        navigate('/dashboard?value=0');
      });
    },
  });

  useEffect(() => {
    if (queryParams.get('uidb64') && queryParams.get('token')) {
      setOpenResettingPasswordModal(true);
    }
  }, [queryParams]);
  const base64ToJson = (base64String) => {
    // Decode the Base64 string
    const jsonString = atob(base64String);

    // Parse the JSON string into a JavaScript object
    const jsonObject = JSON.parse(jsonString);

    return jsonObject;
  };

  const loginToWithToken = useCallback(
    (_token) => {
      try {
        const validUserJson = base64ToJson(_token);
        dispatch(
          loginSuccess({
            token: validUserJson?.tokens?.access_token,
            refresh: validUserJson?.tokens?.refresh_token,
            user: validUserJson?.user,
            profile: validUserJson?.profile,
            remember: validUserJson?.remember_me,
          })
        );
        if (window.apm) {
          // www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html#apm-set-user-context
          window.apm.setUserContext({
            username: validUserJson.user?.username,
            id: validUserJson?.user?.id,
            email: validUserJson?.user?.email,
          });
        }
        navigate('/dashboard?value=0');
      } catch (err) {
        SwalToast({
          icon: 'error',
          title: JSON.stringify(err),
        });
      }
    },
    [loginSuccess, dispatch]
  );

  useEffect(() => {
    if (queryParams.get('token')) {
      loginToWithToken(queryParams.get('token'));
    }
  }, [queryParams]);
  /**
   * @function handleClose
   *@description use to reset as prev state when user click on back to login  button.
   *
   * @returns {void}
   */
  const handleClose = useCallback(() => {
    setForgetPasswordForm(false);
    setOpenResettingPasswordModal(false);
    setSuccessMessage('');
    setErrorMessage('');
  }, [
    setForgetPasswordForm,
    setOpenResettingPasswordModal,
    setSuccessMessage,
    setErrorMessage,
  ]);

  /**
   * @function changeHidden
   *@description toggle to hide of show password form password field.
   *
   * @returns {void}
   */
  const changeHidden = useCallback(() => {
    setHidden(!hidden);
  }, [setHidden, hidden]);

  /**
   * @function renderWelcomeMsg
   * @description Conditionally renders the Welcome Message component based on the state.
   * @returns {JSX.Element|null} The Welcome Message component or null.
   */
  const renderWelcomeMsg = () => {
    if (!forgetPasswordForm && !openResettingPasswordModal) {
      return <ShowWelcomeMsg />;
    }
    return null;
  };

  const changeForgetPassword = useCallback(() => {
    setForgetPasswordForm(true);
  }, [setForgetPasswordForm]);

  const handleClickSignInWithMicrosoft = useCallback(() => {
    window.location.href = window.env.REACT_APP_SAML_LOGIN_URL;
  }, []);

  /**
   * @function renderLoginForm
   * @description Conditionally renders the login form based on the state of forgetPasswordForm and openResettingPasswordModal.
   * @returns {JSX.Element|null} The login form component or null.
   */
  const renderLoginForm = () => {
    if (forgetPasswordForm || openResettingPasswordModal) {
      return null;
    }
    return (
      <Stack direction="column" spacing={3}>
        <Box mb={3}>
          <TextField
            required
            {...loginForm?.getFieldProps('username')}
            type="text"
            variant="filled"
            autoComplete="off"
            label="Username"
            helperText={getErrorHelperText(
              loginForm?.errors?.username,
              loginForm?.touched?.username
            )}
            error={Boolean(
              getErrorHelperText(
                loginForm?.errors?.username,
                loginForm?.touched?.username
              )
            )}
          />
        </Box>
        <Box mb={3}>
          <TextField
            required
            {...loginForm?.getFieldProps('account_id')}
            type="text"
            variant="filled"
            autoComplete="off"
            label="Account Id"
            helperText={getErrorHelperText(
              loginForm?.errors?.account_id,
              loginForm?.touched?.account_id
            )}
            error={Boolean(
              getErrorHelperText(
                loginForm?.errors?.account_id,
                loginForm?.touched?.account_id
              )
            )}
          />
        </Box>
        <Box mb={2}>
          <TextField
            required
            {...loginForm?.getFieldProps('password')}
            label="Password"
            type={!hidden ? 'text' : 'password'}
            variant="filled"
            autoComplete="off"
            helperText={getErrorHelperText(
              loginForm?.errors?.password,
              loginForm?.touched?.password
            )}
            error={Boolean(
              getErrorHelperText(
                loginForm?.errors?.password,
                loginForm?.touched?.password
              )
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={changeHidden}
                    edge="end"
                    size="large"
                  >
                    {!hidden ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack direction="row" justifyContent="space-between">
          <Box item style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="checkbox"
              name="remember"
              id="remember"
              checked={checked}
              onChange={() => setChecked(!checked)}
              style={{ marginLeft: '8px', marginRight: '8px' }}
            />
            <Typography
              display="inline"
              htmlFor="remember"
              variant="body2"
              sx={{ cursor: 'pointer' }}
              color="text.light"
            >
              Remember me
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              color: (theme) => theme.palette.text.light,
            }}
            onClick={changeForgetPassword}
          >
            Forgot password?
          </Typography>
        </Stack>
        <Stack justifyContent="center" spacing={2} alignItems="center">
          <Button
            variant="contained"
            size="large"
            onClick={loginForm?.handleSubmit}
            sx={{
              background: (theme) => theme.palette.primary.contrastText,
              height: '35px',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.other.white,
              },
              boxShadow: BOX_SHADOW,
              borderRadius: '5px',
              width: '100% !important',
            }}
          >
            <Typography letterSpacing={3} color="primary.dark" variant="h4">
              LOGIN
            </Typography>
          </Button>
          <Typography color="text.light" variant="body4">
            or sign in with
          </Typography>

          <Button
            variant="contained"
            size="large"
            onClick={handleClickSignInWithMicrosoft}
            sx={{
              background: (theme) => theme.palette.primary.contrastText,
              height: '35px',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.other.white,
              },
              boxShadow: BOX_SHADOW,
              borderRadius: '5px',
              width: '100% !important',
            }}
          >
            <Typography color="primary.dark" variant="h4">
              Microsoft
            </Typography>
          </Button>
        </Stack>
      </Stack>
    );
  };

  /**
   * @function renderSendResetPasswordLink
   * @description Conditionally renders the SendResetPasswordLink component based on the state of forgetPasswordForm and openResettingPasswordModal.
   * @returns {JSX.Element|null} The SendResetPasswordLink component or null.
   */
  const renderSendResetPasswordLink = () => {
    if (forgetPasswordForm && !openResettingPasswordModal) {
      return (
        <SendResetPasswordLink
          handleClose={handleClose}
          updateErrorMessage={setErrorMessage}
          updateSuccessMessage={setSuccessMessage}
          forgetPassword={forgetPassword}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      );
    }
    return null;
  };

  /**
   * @function renderResetPassword
   * @description Conditionally renders the ResetPassword component based on the state of forgetPasswordForm and openResettingPasswordModal.
   * @returns {JSX.Element|null} The ResetPassword component or null.
   */
  const renderResetPassword = () => {
    if (!forgetPasswordForm && openResettingPasswordModal) {
      return (
        <ResetPassword
          handleClose={handleClose}
          updateErrorMessage={setErrorMessage}
          updateSuccessMessage={setSuccessMessage}
          resetPassword={resetPassword}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      );
    }
    return null;
  };

  return (
    <Grid
      container
      sx={{
        position: 'absolute',
        width: '100%',
        minWidth: '400px',
        minHeight: '100vh',
        overflow: 'auto',
      }}
    >
      <Grid container item xs={12} md={6.5} px={3} pt={3}>
        <Box position="fixed" p={1}>
          <NimbusLogo height="4rem" width="7rem" />
        </Box>
        <LoginCarousel />
      </Grid>
      <Grid
        item
        xs={12}
        md={5.5}
        container
        px={10}
        sx={{ background: (theme) => theme.palette.primary.dark }}
        p={3}
      >
        <Grid item xs={12} mt={2}>
          {renderWelcomeMsg()}
        </Grid>
        <Grid
          item
          xs={6}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          my="auto"
        >
          <Grid item xs={12} mb={2}>
            {renderLoginForm()}
            {renderSendResetPasswordLink()}
            {renderResetPassword()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withTransaction('Login', 'component')(Login);
