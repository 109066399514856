import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import PropTypes from 'prop-types';

import { ReactComponent as CentralisedInventory } from '../../assets/images/LoginAssets/CentralisedInventory.svg';
import { ReactComponent as Attestation } from '../../assets/images/LoginAssets/Attestation.svg';
import { ReactComponent as ModelValidation } from '../../assets/images/LoginAssets/ModelValidation.svg';

const carouselItemsList = [
  {
    id: 1,
    title: 'Centralized Inventory',
    info: 'On-demand access to model meta-data,\n records and model history',
    mainImage: <CentralisedInventory style={{ height: '70%', width: '70%' }} />,
  },
  {
    id: 2,
    title: 'Attestation and Governance',
    info: 'Attest and review models, both periodically \nand based on specific-request',
    mainImage: <Attestation style={{ height: '70%', width: '70%' }} />,
  },
  {
    id: 3,
    title: 'Model Validation',
    info: 'Initiate and manage Model \nValidation Workflows',
    mainImage: <ModelValidation style={{ height: '70%', width: '70%' }} />,
  },
];

function CarouselItem({ title, mainImage, info }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifySelf="center"
    >
      <Box mb={3}>
        <Typography
          sx={{ whiteSpace: 'pre-line' }}
          color="primary"
          variant="h1"
          align="center"
        >
          {title}
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography
          sx={{ whiteSpace: 'pre-line' }}
          color="primary"
          variant="h5"
          align="center"
        >
          {info}
        </Typography>
      </Box>
      <Box>{mainImage}</Box>
    </Box>
  );
}

function LoginCarousel() {
  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={12}
        flexDirection="column"
        alignItems="center"
        mx="auto"
        my="auto"
      >
        <Carousel
          navButtonsAlwaysVisible={false}
          autoPlay={true}
          animation="slide"
          navButtonsProps={{
            style: {
              opacity: 0,
              display: 'none',
            },
          }}
        >
          {carouselItemsList.map((item) => (
            <CarouselItem
              key={item.id}
              title={item.title}
              info={item.info}
              mainImage={item.mainImage}
            />
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}

CarouselItem.propTypes = {
  title: PropTypes.string.isRequired,
  mainImage: PropTypes.element.isRequired,
  info: PropTypes.string.isRequired,
};

export default LoginCarousel;
