// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { Box, Toolbar, AppBar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import DropdownMenu from '../Common/DropdownMenu';
import AccessControl from './AccessControl';
import useCommon from '../../hooks/useCommon';
import ConfirmModal from '../Common/Modals/ConfirmModal';

const TopNavBar = ({ SidebarConfig }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [navigateToRoute, setNavigateToRoute] = useState(false);

  const navigate = useNavigate();
  const { common, dispatch, setIsWorkflowCompiled } = useCommon();

  /**
   * If workflow is not compile show modal for confirmation
   *  @name handleModalOpen
   * @returns {void}
   */
  const handleModalOpen = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, [setIsConfirmModalOpen]);

  /**
   * Closes the confirmation modal by setting the state value.
   * @function
   * @name closeConfirmModal
   * @returns {void}
   * @example closeConfirmModal()
   */
  const closeConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  /**
   * Confirms the action, if user have compiled the workflow or not.
   *  @name confirm
   * @returns {void}
   */
  const confirm = useCallback(() => {
    dispatch(setIsWorkflowCompiled(true));
    closeConfirmModal();
    setTimeout(() => {
      navigate(navigateToRoute);
    }, 0);
  }, [dispatch, setIsWorkflowCompiled, navigateToRoute]);

  /**
   * @function onItemClick
   * Handles the click event on an item.
   *
   * @param {Object} _item - The item that was clicked.
   * @param {string} _item.route - The route associated with the item.
   * @param {Array} _item.children - The children of the item.
   *
   * @description
   * If the item has no children, navigates to the item's route.
   * If the item's route is not the edit workflow route, checks if the workflow is compiled.
   * If the workflow is compiled, navigates to the item's route. Otherwise, opens a modal.
   * @returns {void}
   */
  const onItemClick = useCallback(
    (_item) => {
      if (!_item?.children?.length) {
        setNavigateToRoute(_item.route);
        if (_item?.route === '/help') {
          window.open(_item?.route, '_blank');
        }
        // If we are trying to navigate from edit workflow route to any other route then we will check if workflow is compiled or not, if it is compiled then only we can navigate to other routes
        else if (_item?.route !== '/workflows/edit-workflow') {
          if (common?.isWorkflowCompiled) {
            navigate(_item.route);
          } else {
            handleModalOpen();
          }
        } else {
          navigate(_item.route);
        }
      }
    },
    [setNavigateToRoute, handleModalOpen, navigate, common?.isWorkflowCompiled]
  );

  return (
    <AppBar position="static">
      <Toolbar
        variant="dense"
        disableGutters={true}
        sx={{
          borderBottom: '1px solid',
          background: 'white',
          width: '100%',
          overflow: 'auto',
        }}
      >
        {SidebarConfig?.map((item) => {
          return (
            <AccessControl
              key={item?.name}
              allowedPermissions={item?.permissions}
            >
              <Box mx={2}>
                <DropdownMenu menuItem={item} onItemClick={onItemClick} />
              </Box>
            </AccessControl>
          );
        })}
      </Toolbar>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        confirm={confirm}
        alertLabelText={
          <Typography>{`You have unsaved changes. Do you really want to leave this page
        without saving?`}</Typography>
        }
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </AppBar>
  );
};
TopNavBar.propTypes = {
  SidebarConfig: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default TopNavBar;
