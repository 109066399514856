import { useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useNotification() {
  const [notificationList, setNotificationList] = useState([]);
  const getNotification = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.NOTIFICATIONS.GET_NOTIFICATION}`
      )
      .then((res) => {
        setNotificationList(res?.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const clearAllNotification = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.NOTIFICATIONS.CLEAR_NOTIFICATION}`
      )
      .then((res) => {
        setNotificationList(res?.data || []);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const readUnreadNotification = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.NOTIFICATIONS.READ_UNREAD}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const markAllReadNotifications = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.NOTIFICATIONS.MARK_ALL_READ}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    getNotification,
    notificationList,
    clearAllNotification,
    readUnreadNotification,
    markAllReadNotifications,
  };
}
