// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Slide, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactMuiTableColumnHeaderTextEllipsis } from 'solytics-frontend';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContextMenu } from 'react-contexify';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import moment from 'moment';

import Breadcrumb from '../../components/Common/Breadcrumb';
import SearchTextField from '../../components/Common/SearchTextField';
import { NOT_AUTHORIZED_TO_ACCESS_MESSAGE } from '../../const/CommonConst';
import MODULE_SUB_MODULE from '../../const/moduleSubmodule';
import useCommon from '../../hooks/useCommon';
import RegisterAppModal from '../../components/AppManagement/RegisterAppModal';
import useAppManagement from '../../hooks/AppManagement/useAppManagement';
import ReactMuiTableListView from '../../components/Common/ReactMuiTableListView';
import { ContextMenus } from '../../components/Common/ContextMenus';
import DeleteModal from '../../components/Common/Modals/DeleteModal';
import swalToast from '../../components/Common/swalToast';

const WARNING_TEXT = 'This might impact existing models and related entities.';

const getHeaderProps = () => ({
  style: {
    display: 'flex',
    alignItems: 'center',
  },
});

const handleCreatedOnCell = ({ row }) => {
  return moment(row?.original?.created_at).format('MM/DD/YYYY');
};

const columns = [
  {
    heading: 'Client Id',
    accessor: 'client_id',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'App Name',
    accessor: 'name',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'CREATED ON',
    accessor: 'created_at',
    Cell: handleCreatedOnCell,
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
];

const AppManagement = () => {
  const { common, dispatch, setAppManagementSearch } = useCommon();
  const { registerApp, getAllApps, deleteApp, updateApp } = useAppManagement();
  const { show: showRowLevelMenus } = useContextMenu();
  const theme = useTheme();

  const [isRegisterAppModalOpen, setIsRegisterAppModalOpen] = useState(false);
  const [mode, setMode] = useState('create');
  const [appList, setAppList] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAppDeleteModalOpen, setIsAppDeleteModalOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState({});

  const { users } = useSelector((state) => state);
  const { permissions } = users;
  const permission =
    permissions?.[
      `${[MODULE_SUB_MODULE?.AO.key]}_${
        MODULE_SUB_MODULE?.AO?.subModules?.AM?.key
      }`
    ];

  const deleteAppHandler = (appData) => {
    setSelectedApp(appData);
    setIsAppDeleteModalOpen(true);
  };

  const closeDeleteAppModal = useCallback(() => {
    setIsAppDeleteModalOpen(false);
  }, [setIsAppDeleteModalOpen]);

  const handleGetAllApps = () => {
    getAllApps().then((res) => {
      if (res) {
        setAppList(res?.data?.Data);
      }
    });
  };

  const deleteConfirmApp = useCallback(() => {
    deleteApp(selectedApp)?.then(() => {
      swalToast({
        icon: 'success',
        title: 'App deleted successfully.',
      });
      handleGetAllApps();
      closeDeleteAppModal();
    });
  }, [
    deleteApp,
    selectedApp,
    swalToast,
    closeDeleteAppModal,
    handleGetAllApps,
  ]);

  const handleSearchTextChange = useCallback(
    (e) => {
      if (e?.target?.value.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setAppManagementSearch(e?.target?.value));
    },
    [dispatch, setAppManagementSearch]
  );

  const handleChangeMode = useCallback(
    (newMode) => {
      setMode(newMode);
    },
    [setMode]
  );

  const handleRegisterAppModalOpen = useCallback(() => {
    setIsRegisterAppModalOpen(true);
  }, [setIsRegisterAppModalOpen]);

  const handleRegisterAppModalClose = useCallback(() => {
    setIsRegisterAppModalOpen(false);
    setSelectedApp({});
  }, [setIsRegisterAppModalOpen, setSelectedApp]);

  const handleRegisterApp = useCallback(() => {
    handleRegisterAppModalOpen();
    handleChangeMode('create');
  }, [handleRegisterAppModalOpen, handleChangeMode]);

  const getCellProps = useCallback(
    (cell) => {
      return {
        onContextMenu: (e) => {
          setSelectedRow(cell?.row);
          showRowLevelMenus({
            id: `app-menus`,
            event: e,
            props: cell?.row,
          });
        },
        style: {
          backgroundColor:
            cell?.row?.id === selectedRow?.id
              ? theme?.palette?.secondary?.main
              : '',
          position: 'relative',
        },
      };
    },
    [setSelectedRow, theme, showRowLevelMenus, selectedRow]
  );

  const onMenuExit = useCallback(() => {
    setSelectedRow(null);
  }, [setSelectedRow]);

  const handleEditApp = useCallback(
    (appData) => {
      handleChangeMode('edit');
      setSelectedApp(appData);
      handleRegisterAppModalOpen();
    },
    [handleChangeMode, setSelectedApp, handleRegisterAppModalOpen]
  );

  const appMenus = useMemo(
    () => [
      {
        label: 'Delete app',
        id: 'delete_app',
        icon: DeleteIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          deleteAppHandler(props?.original);
        },
      },
      {
        label: 'Edit app',
        id: 'edit_app',
        icon: BorderColorIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          handleEditApp(props?.original);
        },
      },
    ],
    [permission, deleteAppHandler, handleEditApp]
  );

  useEffect(() => {
    handleGetAllApps();
  }, []);

  return (
    <Grid container rowSpacing={2}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'center', sm: 'inherit' }}
        flexWrap="wrap"
        sx={{ background: (_theme) => _theme?.palette?.secondary?.light1 }}
      >
        <Box>
          <Breadcrumb />
        </Box>
        <Box pl={2} pt={1} pb={1} pr={2}>
          <Button
            disabled={permission !== 'Manage'}
            title={
              permission !== 'Manage'
                ? NOT_AUTHORIZED_TO_ACCESS_MESSAGE
                : 'Create template.'
            }
            variant="contained"
            onClick={handleRegisterApp}
          >
            Register App
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} display="flex" gap={2} px={2}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
          }}
        >
          <SearchTextField
            value={common?.appManagementListSearch}
            onChange={handleSearchTextChange}
            placeholder="Search"
          />
        </Box>
      </Grid>
      <Grid item xs={12} p={2}>
        <Box>
          {appList?.length > 0 ? (
            <Slide direction="left" timeout={1000} mountOnEnter in={true}>
              <Box
                display="flex"
                flexGrow={1}
                flexDirection="column"
                overflow="auto"
                height="calc(100vh - 300px)"
              >
                <ReactMuiTableListView
                  data={appList?.length > 0 ? appList : []}
                  columns={columns}
                  getHeaderProps={getHeaderProps}
                  getCellProps={getCellProps}
                  enableRowSelection={true}
                  pageCount={appList?.length}
                  enablePagination={true}
                  initialPageSize={10}
                  initialGlobalFilter={common?.appManagementListSearch}
                  rowsPerPageOptions={[5, 10, 15]}
                />
                <ContextMenus
                  menuId="app-menus"
                  menus={appMenus}
                  onMenuExit={onMenuExit}
                />
              </Box>
            </Slide>
          ) : (
            <Box
              display="flex"
              sx={{ height: 'calc(100vh  - 500px)' }}
              flexGrow={1}
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="subtitle1">No data result found.</Typography>
            </Box>
          )}
        </Box>
      </Grid>
      {isRegisterAppModalOpen && (
        <RegisterAppModal
          open={isRegisterAppModalOpen}
          onClose={handleRegisterAppModalClose}
          mode={mode}
          registerApp={registerApp}
          selectedApp={selectedApp}
          updateApp={updateApp}
          handleGetAllApps={handleGetAllApps}
        />
      )}
      <DeleteModal
        open={isAppDeleteModalOpen}
        handleClose={closeDeleteAppModal}
        deleteConfirm={deleteConfirmApp}
        alertLabelText="Do you want to delete this app?"
        warningText={WARNING_TEXT}
      />
    </Grid>
  );
};

export default AppManagement;
