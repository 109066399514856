import { useState } from 'react';

/**
 * @typedef {object} UseModalDisclosureValue
 * @property {boolean} open
 * @property {() => void} handleOpen
 * @property {() => void} handleClose
 * @property {() => void} toggleOpen
 */

/**
 * Custom hook for handling Modal states
 * @returns {UseModalDisclosureValue}
 * @example
 * const { open: formulaModalOpen, handleOpen: openFormulaModal } = useModalDisclosure();
 * openFormulaModal();
 */
export default function useModalDisclosure() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  return { open, handleOpen, handleClose, toggleOpen };
}
