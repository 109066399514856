import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useUserGroupProvisioning() {
  const getAllGroups = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_GROUPS}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getGroupTasks = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_GROUP_TASKS}`,
        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createGroup = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.CREATE_GROUP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const assignTask = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.ASSIGN_TASK}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateGroup = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.UPDATE_GROUP}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteGroup = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.DELETE_GRPUP}?groupid=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllRoles = () => {
    return axios
      .get(`${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ROLES}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createRole = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ROLES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteRole = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ROLES}?role_id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSingleRoleDetails = (body) =>
    axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ROLES}`,
        { params: body }
      )
      .then(
        (res) => res,
        (error) => {
          throw error;
        }
      );
  const updateRole = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ROLES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getAllGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    getAllRoles,
    createRole,
    deleteRole,
    getSingleRoleDetails,
    updateRole,
    getGroupTasks,
    assignTask,
  };
}
