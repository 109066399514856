// @ts-nocheck
import React from 'react';
import { Popper } from '@mui/material';

const styles = () => ({
  popper: {
    maxWidth: 'fit-content',
  },
});

const CustomPopover = (props) => {
  return <Popper {...props} style={styles.popper} placement="bottom" />;
};

export default CustomPopover;
