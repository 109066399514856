// @ts-nocheck
import React, { useCallback } from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  Button,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTransition from '../Transition/DialogTransition';

const DeleteModal = ({
  open = false,
  handleClose = () => {},
  deleteConfirm = () => {},
  alertLabelText = '',
  warningText = '',
  deleteConfirmText = 'Delete',
}) => {
  const onClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Box>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            'padding-bottom': '24px',
          },
        }}
        TransitionComponent={DialogTransition}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mt={1}
            >
              <Typography variant="h4" align="center">
                {alertLabelText}
              </Typography>
              {warningText ? (
                <Typography variant="body1" align="center" mt={1}>
                  {warningText}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
              <Button onClick={deleteConfirm}>{deleteConfirmText}</Button>
              <Button variant="contained" onClick={onClose}>
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deleteConfirm: PropTypes.func.isRequired,
  alertLabelText: PropTypes.string.isRequired,
  warningText: PropTypes.string.isRequired,
  deleteConfirmText: PropTypes.string,
};
DeleteModal.defaultProps = {
  deleteConfirmText: 'Delete',
};

export default DeleteModal;
