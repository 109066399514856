// @ts-nocheck
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Custom React hook for managing and synchronizing a state variable with URL query parameters.
 *
 * @param {*} initialState - The initial state value.
 * @param {string} paramsName - The name of the query parameter to associate with the state.
 * @param {function} [customSerialize] - (Optional) Function to convert the state value into a string representation for the URL.
 *   Default behavior is to call `toString()` on the state value.
 * @param {function} [customDeserialize] - (Optional) Function to convert a string representation from the URL into the state value.
 *   Default behavior is to use the identity function, which returns the input string as is.
 * @returns {Array} - An array containing the current state value as the first element and a function to update it as the second element.
 *
 * @typedef {function} SerializeFunction
 * @param {*} state - The state value to be converted into a string representation.
 * @returns {string} - The string representation of the state value.
 *
 * @typedef {function} DeserializeFunction
 * @param {string} serializedState - The string representation of the state value from the URL.
 * @returns {*} - The state value parsed from the string representation.
 *
 * @example
 * // Usage with a boolean state variable
 * const [bool, setBool] = useStateParams(false, 'boolean',(s) => (s ? 'true' : 'false'),(s) => s === 'true');
 *
 * // Usage with a numeric state variable
 * const [slider, setSlider] = useStateParams(10, 'slider',(s) => s.toString(),(s) => (Number(s) !== Number.NaN ? Number(s) : 10));
 */
export default function useStateParams(
  initialState,
  paramsName,
  customSerialize = (s) => s.toString(),
  customDeserialize = (s) => s
) {
  let serialize = (s) => s.toString();
  let deserialize = (s) => s;

  switch (typeof initialState) {
    case 'boolean':
      serialize = (s) => (s ? 'true' : 'false');
      deserialize = (s) => s === 'true';
      break;
    case 'number':
      serialize = (s) => s.toString();
      deserialize = (s) =>
        !Number.isNaN(Number(s)) ? Number(s) : initialState;
      break;
    case 'object':
      serialize = (s) => JSON.stringify(s);
      deserialize = (s) => JSON.parse(decodeURIComponent(s));
      break;
    default:
      serialize = customSerialize;
      deserialize = customDeserialize;
  }

  const navigate = useNavigate();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const existingValue = searchParams.get(paramsName);
  const [state, setState] = useState(
    existingValue ? deserialize(existingValue) : initialState
  );

  useEffect(() => {
    // Updates state when the user navigates backwards or forwards in browser history
    if (existingValue && deserialize(existingValue) !== state) {
      setState(deserialize(existingValue));
    }
  }, [existingValue]);
  const onChange = (s) => {
    setState(s);
    const param = typeof s === 'function' ? s(state) : s;
    searchParams.set(paramsName, serialize(param));
    navigate({ search: `?${searchParams.toString()}` });
  };

  return [state, onChange];
}
