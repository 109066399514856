// @ts-nocheck
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Tab, Tabs, Typography, Switch } from '@mui/material';
import propTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { TabPanel } from '../../Common/Tabs';
import Direct from './Direct';
import useNotification from '../../../hooks/Configuration/useNotification';

const MuiTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '30px',
  borderColor: theme.palette.primary.main,
}));

const MuiTab = styled(Tab)(({ theme }) => ({
  minHeight: '25px',
  minWidth: 0,
  padding: 0,
  paddingRight: '10px',
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'flex-start',
  '&.Mui-selected': {
    borderRadius: '3px',
    color: `${theme.palette.primary.main} !important`,
  },
}));

const UnreadNotificationSwitch = ({
  setNotificationsData,
  getNotification,
}) => {
  const [showUnreadNotifications, setShowUnreadNotifications] = useState(false);

  const handleShowUnreadNotifications = useCallback(
    (event) => {
      setShowUnreadNotifications(event.target.checked);

      if (event?.target?.checked) {
        setNotificationsData((prev) =>
          prev.filter((notification) => !notification?.read_status)
        );
      } else {
        getNotification().then((res) => {
          if (res?.data) {
            setNotificationsData(res?.data);
          }
        });
      }
    },
    [setNotificationsData, getNotification, setShowUnreadNotifications]
  );

  return (
    <Box>
      <Typography>{showUnreadNotifications ? 'Unread' : 'All'}</Typography>
      <Switch
        checked={showUnreadNotifications}
        onChange={handleShowUnreadNotifications}
      />
    </Box>
  );
};

UnreadNotificationSwitch.propTypes = {
  getNotification: propTypes.func.isRequired,
  setNotificationsData: propTypes.func.isRequired,
};

const NotificationList = ({
  handleCloseNotifications,
  getNotification,
  calculateUnreadNotifications,
  notificationsData,
  setNotificationsData,
  setUnreadNotificationsCount,
  notificationList,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const { readUnreadNotification, markAllReadNotifications } =
    useNotification();
  const { userData } = useSelector((state) => state?.users);

  const handleChange = useCallback(
    (_event, newValue) => {
      setTabValue(newValue);
    },
    [setTabValue]
  );

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <Box>
      <Grid container item xs={12} rowSpacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4">Notifications</Typography>
            <UnreadNotificationSwitch
              notificationsData={notificationsData}
              setNotificationsData={setNotificationsData}
              getNotification={getNotification}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MuiTabs value={tabValue} onChange={handleChange}>
            <MuiTab title="Direct" label="Direct" />
          </MuiTabs>
          <Box sx={{ height: 'calc(90vh - 105px)', overflowY: 'scroll' }}>
            <TabPanel value={tabValue} index={0}>
              <Direct
                handleCloseNotifications={handleCloseNotifications}
                readUnreadNotification={readUnreadNotification}
                userData={userData}
                markAllReadNotifications={markAllReadNotifications}
                getNotification={getNotification}
                calculateUnreadNotifications={calculateUnreadNotifications}
                notificationsData={notificationsData}
                setNotificationsData={setNotificationsData}
                setUnreadNotificationsCount={setUnreadNotificationsCount}
                notificationList={notificationList}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

NotificationList.propTypes = {
  notificationsData: propTypes.arrayOf(propTypes.oneOfType([propTypes.object]))
    .isRequired,
  notificationList: propTypes.arrayOf(propTypes.oneOfType([propTypes.object]))
    .isRequired,
  handleCloseNotifications: propTypes.func.isRequired,
  getNotification: propTypes.func.isRequired,
  calculateUnreadNotifications: propTypes.func.isRequired,
  setNotificationsData: propTypes.func.isRequired,
  setUnreadNotificationsCount: propTypes.func.isRequired,
};
export default NotificationList;
