import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';

import AccessControl from '../AppHeader/AccessControl';

const MenuItemComponent = ({
  setAnchorEl,
  onItemClick,
  subMenu,
  isFocused,
}) => {
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
    setTimeout(() => {
      onItemClick(subMenu);
    }, 500);
  }, [subMenu, onItemClick, setAnchorEl]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e?.key === 'Enter' || e?.key === ' ') {
        handleCloseMenu();
      }
    },
    [handleCloseMenu]
  );
  return (
    <MenuItem
      sx={{
        pl: 1.5,
        pr: 3,
        minWidth: '130px',
        maxWidth: '200px',
      }}
      divider={true}
      onClick={handleCloseMenu}
      disableRipple
      autoFocus={isFocused}
      onKeyDown={handleKeyDown}
      aria-label={`${subMenu?.name}`}
      tabIndex={isFocused ? 0 : -1}
    >
      <Typography variant="inherit" noWrap title={subMenu?.name}>
        {subMenu?.name}
      </Typography>
    </MenuItem>
  );
};
MenuItemComponent.propTypes = {
  setAnchorEl: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  subMenu: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default function DropdownMenu({ menuItem, onItemClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);

  useEffect(() => {
    const handleDown = (event) => {
      if (
        event?.key === 'Tab' ||
        event?.key === 'ArrowDown' ||
        event?.key === 'ArrowUp'
      ) {
        setIsKeyboardNavigation(true);
      }
    };

    const handleMouseDown = () => {
      setIsKeyboardNavigation(false);
    };

    document.addEventListener('keydown', handleDown);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('keydown', handleDown);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event?.currentTarget);
      setFocusedIndex(0); // Focus the first item when menu opens
    },
    [setAnchorEl, setFocusedIndex]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setTimeout(() => {
      onItemClick(menuItem);
    }, 500);
  }, [menuItem, onItemClick, setAnchorEl]);

  const handleClickItem = useCallback(() => {
    onItemClick(menuItem);
  }, [menuItem, onItemClick]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event?.key === 'ArrowDown') {
        setFocusedIndex((prevIndex) =>
          prevIndex < menuItem.children.length - 1 ? prevIndex + 1 : 0
        );
        event?.preventDefault();
      } else if (event?.key === 'ArrowUp') {
        setFocusedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : menuItem.children.length - 1
        );
        event.preventDefault();
      } else if (event?.key === 'Escape') {
        handleClose();
        event?.preventDefault();
      } else {
        event?.preventDefault();
      }
    },
    [setFocusedIndex, handleClose]
  );

  return (
    <div>
      {menuItem?.children?.length > 0 ? (
        <>
          <Button
            id={`menu-button-${menuItem?.name}`}
            aria-controls={
              anchorEl ? `menu-button-${menuItem?.name}` : undefined
            }
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            variant="text"
            sx={{
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              '&:focus-visible': {
                outline: isKeyboardNavigation
                  ? (_theme) => `2px solid ${_theme?.palette?.primary?.main}`
                  : 'none',
              },
            }}
            disableFocusRipple={true}
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            disabled={menuItem?.disable}
          >
            {menuItem?.name}
          </Button>
          <Menu
            id={`menu-button-${menuItem?.name}`}
            MenuListProps={{
              'aria-labelledby': `menu-button-${menuItem?.name}`,
              role: 'menu',
              onKeyDown: handleKeyDown, // Handle keyboard navigation
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuItem?.children?.map((subMenu, index) => (
              <AccessControl
                allowedPermissions={subMenu?.permissions}
                key={subMenu?.name}
              >
                <MenuItemComponent
                  setAnchorEl={setAnchorEl}
                  onItemClick={onItemClick}
                  subMenu={subMenu}
                  isFocused={focusedIndex === index} // Pass focus state
                />
              </AccessControl>
            ))}
          </Menu>
        </>
      ) : (
        <Button
          id={`menu-button-${menuItem?.name}`}
          aria-controls={`menu-button-${menuItem?.name}`}
          variant="text"
          sx={{
            whiteSpace: 'nowrap',
            textTransform: 'capitalize',
            '&:focus-visible': {
              outline: isKeyboardNavigation
                ? (_theme) => `2px solid ${_theme?.palette?.primary?.main}`
                : 'none',
            },
          }}
          disableFocusRipple={true}
          disableElevation
          onClick={handleClickItem}
          disabled={menuItem?.disable}
        >
          {menuItem?.name}
        </Button>
      )}
    </div>
  );
}

DropdownMenu.propTypes = {
  menuItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onItemClick: PropTypes.func.isRequired,
};
