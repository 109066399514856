// @ts-nocheck
import axios from 'axios';

import API_ENDPOINTS from '../../const/ApiEndPoints';
import useTask from './useTask';

export default function useDashboard() {
  const { getPendingTasks, getOpenTasks, getTaskQueue, getTaskResult } =
    useTask();

  const getNodesEdges = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.GET_NODES}`,
        {
          loader: false,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getFiles = () => {
    return axios
      .get(`${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.BU.GET_FILES}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getFilter = (entityType = '') => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.GET_FILTER}`,
        {
          params: { entityType },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getFilterList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.GET_FILTERED_DATA}`,
        {
          params: body,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAlertData = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.GET_ALERTS}`,
        {
          params: {
            pageIndex: body?.pageIndex,
            pageSize: body?.pageSize,
          },
          loader: body?.isLoading,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getPipelineData = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.PIPELINE}`,
        {
          params: {
            pageIndex: body?.pageIndex,
            pageSize: body?.pageSize,
          },
          loader: body?.isLoading,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getDashboardReports = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.DASHBOARD_REPORTS}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const updateAlert = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.UPDATE_ALERT}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllEvents = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.ALL_EVENTS}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getKibanDashboard = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.GET_KIBAN_DASHBOARD}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getEventsCount = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.DB.EVENTS_COUNT}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    getFilter,
    getFilterList,
    getNodesEdges,
    getFiles,
    getAlertData,
    getDashboardReports,
    updateAlert,
    getAllEvents,
    getPipelineData,
    getKibanDashboard,
    getEventsCount,
    getPendingTasks,
    getOpenTasks,
    getTaskQueue,
    getTaskResult,
  };
}
