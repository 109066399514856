// @ts-nocheck
import React, { useCallback } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const PermissionOptionButton = ({
  option,
  handlePermissionChange,
  moduleKey,
  subModuleKey,
  isTemplate,
  permission,
  mode = 'group',
}) => {
  const onClick = useCallback(
    (e) => {
      // Custom permission button should be non clickable
      e?.stopPropagation();
      if (option !== 'Custom') {
        if (mode === 'group') {
          handlePermissionChange(moduleKey, subModuleKey, option, isTemplate);
        } else {
          handlePermissionChange(moduleKey, subModuleKey, option);
        }
      }
    },
    [moduleKey, option, subModuleKey, isTemplate, mode, handlePermissionChange]
  );

  return (
    <Typography
      onClick={onClick}
      sx={{
        color:
          permission === option
            ? (theme) => theme?.palette?.primary?.dark
            : (theme) => theme?.palette?.other?.white2,
        cursor: 'pointer',
      }}
    >
      {option}
    </Typography>
  );
};

PermissionOptionButton.propTypes = {
  option: PropTypes.string.isRequired,
  moduleKey: PropTypes.string.isRequired,
  subModuleKey: PropTypes.string.isRequired,
  handlePermissionChange: PropTypes.func.isRequired,
  permission: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['group', 'role']).isRequired,
};

export default PermissionOptionButton;
