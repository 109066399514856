// @ts-nocheck
import React from 'react';
import { alpha, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OpenInNew } from '@mui/icons-material';

const ArticleCardRoot = styled(Stack)(({ theme }) => ({
  gap: '8px',
  padding: '8px',
  border: '1px solid transparent',
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '*': {
    textDecoration: 'none',
  },
}));

/**
 * @typedef ArticleCardProps
 * @property {Article} article
 */

/**
 * @param {ArticleCardProps & Omit<import('react-router-dom').LinkProps, 'to' | 'style'>}
 */
const ArticleCard = ({ article, target, ...props }) => {
  return (
    <Link
      to={`/help?articleId=${article.id}`}
      style={{ textDecoration: 'none' }}
      target={target}
      {...props}
    >
      <ArticleCardRoot>
        <Stack direction="row">
          <Typography variant="h6" flexGrow={1}>
            {article.title}
          </Typography>
          {target === '_blank' && <OpenInNew />}
        </Stack>
        <Typography
          component="p"
          sx={{
            fontWeight: 400,
            maxHeight: 200,
            overflow: 'hidden',
          }}
        >
          {`${article.content.slice(0, 200)}...`}
        </Typography>
      </ArticleCardRoot>
    </Link>
  );
};
ArticleCard.propTypes = {
  article: PropTypes.oneOfType([PropTypes.object]).isRequired,
  target: PropTypes.string.isRequired,
};

export default ArticleCard;
