// @ts-nocheck
import { cloneDeep } from 'lodash';

const getDefaultPermission = (objKey) =>
  objKey?.startsWith('TMP_') ? 'None' : 'View';

/**
 * @function addPermissionKey
 * Adds a permission key to an object and its subModules initially.
 *
 * @param {Object} obj - The object to add the permission key to.
 * @returns {Object} The modified object with the permission key added.
 */

function addPermissionKey(obj) {
  Object.keys(obj || {}).forEach((key) => {
    const property = obj[key];
    if (['DBC', 'DBN'].includes(key)) {
      property.permission = 'No';
    } else {
      property.permission = 'View';
    }

    if (Object.hasOwn(property, 'subModules')) {
      addPermissionKey(property?.subModules);
    }
  });

  return obj;
}

/**
 * @function addTemplateModule
 * Adds a template module and its submodules to the moduleSubmoduleTree dynamically.
 *
 * @param {Object} moduleSubmoduleTree - The moduleSubmoduleTree object to add the template module to.
 * @returns {Object} The modified moduleSubmoduleTree object with the template module added.
 */

function addTemplateModule(moduleSubmoduleTree, templateList) {
  const updatedModuleSubmoduleTree = cloneDeep(moduleSubmoduleTree);

  updatedModuleSubmoduleTree.MI.subModules.TMP = {
    objKey: 'TMP',
    label: 'Template Names',
    key: 'MItemplate',
    subModules: {},
    permission: 'View',
  };
  updatedModuleSubmoduleTree.MA.subModules.TMP = {
    objKey: 'TMP',
    label: 'Template Names',
    key: 'MAtemplate',
    subModules: {},
    permission: 'View',
  };
  templateList?.forEach((template) => {
    if (template?.entity_type === 'ModelInventory') {
      if (template?.request_type !== 'AccessRequest') {
        updatedModuleSubmoduleTree.MI.subModules.TMP.subModules[
          `TMP_ModelInventory_${template?.entity_name}`
        ] = {
          objKey: `TMP_ModelInventory_${template?.entity_name}`,
          label: `${template?.entity_name}`,
          key: `${template?.entity_name}`,
          permission: 'View',
        };
      }
    } else {
      updatedModuleSubmoduleTree.MA.subModules.TMP.subModules[
        `TMP_ModelAssociation_${template?.entity_name}`
      ] = {
        objKey: `TMP_ModelAssociation_${template?.entity_name}`,
        label: `${template?.entity_name}`,
        key: `${template?.entity_name}`,
        permission: 'View',
      };
    }
  });
  updatedModuleSubmoduleTree.MA.subModules.TMP.subModules.TMP_ModelAssociation_All =
    {
      objKey: `TMP_ModelAssociation_All`,
      label: `All_ModelAssociation`,
      key: `All`,
      permission: 'View',
    };
  return updatedModuleSubmoduleTree;
}

/**
 * @function transformPermissionJsonObject
 * @description Transforms the permissionJson object and populates the transformedPermissionsObject.
 * @param {Object} permissionJson - The permissionJson.
 */

function transformPermissionJsonObject(permissionJson) {
  const transformedPermissionsObject = {};

  Object.keys(permissionJson || {}).forEach((key) => {
    if (key.includes('_')) {
      const parts = key?.split('_');
      const newKey = parts[parts.length - 1];

      if (newKey === 'template' && parts?.length <= 2) {
        const templateKeyName =
          (parts?.includes('ModelInventory') && 'MItemplate') ||
          (parts?.includes('ModelAssociation') && 'MAtemplate');
        // eslint-disable-next-line no-param-reassign
        transformedPermissionsObject[templateKeyName] = permissionJson?.[key];
      } else {
        // eslint-disable-next-line no-param-reassign
        transformedPermissionsObject[newKey] = permissionJson?.[key];
      }
    } else {
      const parts = key?.split(' ');
      const newKey = parts[0];
      // eslint-disable-next-line no-param-reassign
      transformedPermissionsObject[newKey] = permissionJson?.[key];
    }
  });

  return transformedPermissionsObject;
}

/**
 * @function iterateNestedObject
 * @description Recursively iterates through an object and updates the 'permission' property based on transformedPermissionsObject.
 * @param {Object} obj - The object to iterate and update permissions.
 */

function iterateNestedObject(obj, transformedPermissionsObject) {
  // Iterating obj to change the permissions from permissionJson obj

  if (typeof obj === 'object') {
    Object.keys(obj || {}).forEach((key) => {
      if (key === 'permission') {
        // If obj.key is not present in permissionJson then we are adding default as View (default as None for templates).

        // eslint-disable-next-line no-nested-ternary
        const updatedPermission = transformedPermissionsObject[obj?.key]
          ? transformedPermissionsObject[obj?.key]
          : getDefaultPermission(obj?.objKey);

        // eslint-disable-next-line no-param-reassign
        obj[key] = updatedPermission; // Update the permission property
      }

      iterateNestedObject(obj[key], transformedPermissionsObject);
    });
  }
}

/**
 * @function calculateModulePermission
 * Calculates the module permission based on the submodule permissions.
 *
 * @param {Object} module - The module object containing subModules.
 * @returns {string} The calculated module permission.
 */

const calculateModulePermission = (module) => {
  const moduleCopy = cloneDeep(module);

  // Extracting submodule permissions as an array
  const submodulePermissions = Object.values(moduleCopy?.subModules).map(
    (subModule) => subModule?.permission
  );

  // Helper function to check if all permissions match the target permissions
  const allPermissionsEqual = (permissions, targetPermissions) =>
    permissions?.every((permission) => targetPermissions?.includes(permission));

  // Using a switch statement to handle different permission scenarios
  switch (true) {
    case allPermissionsEqual(submodulePermissions, ['View', 'No']):
      return 'View'; // Return 'View' if all permissions are either 'View' or 'No'
    case allPermissionsEqual(submodulePermissions, ['Manage', 'Yes']):
      return 'Manage'; // Return 'Manage' if all permissions are either 'Manage' or 'Yes'
    case allPermissionsEqual(submodulePermissions, ['None']):
      return 'None'; // Return 'None' if all permissions are 'None'
    default:
      return 'Custom'; // Return 'Custom' for any other combination of permissions
  }
};

/**
 * Recursively update module permissions in an object hierarchy from bottom to top.
 * From bottom to top because we want to make sure we are updating children's permissions first so parents permissions will change accordingly for all levels of hierarchy
 * @param {Object} obj - The object to iterate over.
 * @returns {Object} - The modified object with updated module permissions.
 */

function updateModulePermissions(obj) {
  let result = {};

  if (typeof obj === 'object') {
    Object.keys(obj || {}).forEach((key) => {
      if (typeof obj[key] === 'object') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = updateModulePermissions(obj[key]);
      }
    });

    if (obj?.subModules) {
      // eslint-disable-next-line no-param-reassign
      obj.permission = calculateModulePermission(obj);
    }

    result = obj;
  }

  return result;
}

// Function to recursively process the object and extract the permissions
function extractPermissions(obj, parentKey = '') {
  let result = {};

  // Iterate over the object keys
  Object.keys(obj || {}).forEach((key) => {
    // This objKey is calculated because we are using template in the backend as key for MI and MA templates
    const objKey = ['MItemplate', 'MAtemplate'].includes(obj[key]?.key)
      ? 'template'
      : obj[key]?.key;

    const currentKey = parentKey ? `${parentKey}_${objKey}` : objKey;
    const value = obj[key];

    // Check if the current key represents a submodule
    if (Object.hasOwn(value, 'subModules')) {
      const subModulePermissions = extractPermissions(
        value?.subModules,
        currentKey
      );
      result = { ...result, ...subModulePermissions };
    }

    // Check if the current key has a permission value
    if (Object.hasOwn(value, 'permission')) {
      result[currentKey] = value?.permission;
    }
  });

  return result;
}

const removeModules = (obj, keysToRemove) => {
  // Make a copy of the object to avoid mutating the original
  const updatedObj = { ...obj };

  // Loop through the keys to remove and delete them from the object
  keysToRemove.forEach((key) => {
    delete updatedObj[key];
  });

  return updatedObj;
};

export {
  addPermissionKey,
  addTemplateModule,
  transformPermissionJsonObject,
  iterateNestedObject,
  updateModulePermissions,
  extractPermissions,
  removeModules,
};
