// @ts-nocheck
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useSummary() {
  const getAllSummarySections = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_SUMMARY_SECTIONS}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createSummarySections = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.CREATE_SUMMARY_SECTIONS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateSummarySections = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.UPDATE_SUMMARY_SECTIONS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteSummarySections = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.DELETE_SUMMARY_SECTIONS}?id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    getAllSummarySections,
    deleteSummarySections,
    updateSummarySections,
    createSummarySections,
  };
}
