// @ts-nocheck
import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * @param {import("@mui/material").ButtonProps} props
 */
const StepButton = ({ label, step, setStep, ...props }) => {
  const onClick = useCallback(() => {
    setStep(step);
  }, [setStep, step]);
  return (
    <Button color="primary" onClick={onClick} {...props}>
      {label}
    </Button>
  );
};

StepButton.propTypes = {
  label: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default StepButton;
