const VALUE_EQUAL = { label: 'equal', value: 'equal' };
const VALUE_NOT_EQUAL = { label: 'not equal', value: 'ne' };
const VALUE_GREATER_THAN_EQUAL = { label: 'greater than equal', value: 'gte' };
const VALUE_LESS_THAN_EQUAL = { label: 'less than equal', value: 'lte' };
const VALUE_LESS_THAN = { label: 'less than', value: 'lt' };
const VALUE_GREATER_THAN = { label: 'greater than', value: 'gt' };
const VALUE_CONTAINS = { label: 'contains', value: 'contains' };

export const RELATION_OPTIONS = [
  { label: 'Related to', value: 'Related to' },
  { label: 'Duplicate to', value: 'Duplicate to' },
  { label: 'Parent to', value: 'Parent to' },
  { label: 'Blocks', value: 'Blocks' },
];

export const ENTITY_TYPES = [
  { label: 'ModelInventory', value: 'ModelInventory' },
  { label: 'ModelAssociation', value: 'ModelAssociation' },
];

export const RELATIONS = [
  { label: 'Upstream', value: 'upstream' },
  { label: 'Downstream', value: 'downstream' },
  { label: 'Itself', value: 'itself' },
];

export const MODULE_PATHS = {
  'model-inventory': 'my-model',
  'model-association': 'model-artifact',
  'model-request': 'assessment',
  'access-request': 'accessRequest',
  workflows: 'workflows',
};

export const PRIORITIES = [
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
];

export const DATA_TYPES = [
  { label: 'TEXT', value: 'Character' },
  { label: 'DATE', value: 'Date' },
  { label: 'INTEGER', value: 'Integer' },
  { label: 'FLOAT', value: 'Decimal' },
  { label: 'CHART', value: 'Chart' },
  { label: 'TABLE', value: 'Table' },
  { label: 'USER', value: 'User' },
  { label: 'GROUP', value: 'Group' },
  { label: 'ASSOCIATION', value: 'Association' },
];

export const FREQUENCIES = [
  { label: 'Yearly', value: 'Yearly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Monthly', value: 'Monthly' },
];

export const INPUT_TYPES = [
  { label: 'SINGLE SELECT', value: 'SINGLE_SELECT' },
  { label: 'MULTI SELECT', value: 'MULTI_SELECT' },
  { label: 'TEXTFIELD', value: 'TEXTFIELD' },
  { label: 'IFRAME', value: 'IFRAME' },
  { label: 'TABLE', value: 'TABLE' },
];

export const SHOW_IN_SUMMARY = [
  { label: 'Model Summary', value: 'model_summary' },
  { label: 'Risk Assessment', value: 'risk_assessment' },
  { label: 'Exclude from Summary', value: 'exclude_summary' },
];

export const USER_TYPES = [
  { label: 'Model Developer', value: 'Model Developer' },
  { label: 'Model Validator', value: 'Model Validator' },
];

export const MODEL_STATIC_FIELDS = [
  'accesslevel',
  'type',
  'assignedUser',
  'model_name',
  'model_id',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by',
  'description',
  'status',
  'modelRiskRating',
  'unique_id',
  'currentVersion',
  'due_date',
  'workflowId',
  'workflowName',
  'priority',
  'entityName',
  'entityType',
  'riskRating',
  'entityId',
  'entityName',
  'saveAs',
  'due_date',
  'description',
  'priority',
  'association_name',
  'relation',
  'assignedTo',
  'additional_property',
  'Status',
  'Workflows',
  'Model Id',
  'Assigned User',
  'Model Name',
];

export const INVALID_REGEX_MESSAGE = 'Incorrect input';

export const NOT_AUTHORIZED_TO_ACCESS_MESSAGE =
  'You are not authorized to access this!';

export const MONTH_ORDER_MAP = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const INTEGRATION_PARTNERS = [{ label: 'Nimbus Uno', value: 'Nimbus' }];

export const ISSUE_TYPE = [
  { label: 'Validation', value: 'validation' },
  { label: 'Monitoring', value: 'monitoring' },
  { label: 'Findings', value: 'finding' },
  { label: 'Other Lifecycle Events', value: 'other_lifecycle_event' },
];

export const MENTIONED_ENTITIES = {
  artifact: {
    mentionType: 'artifact:mention',
    mentionTrigger: ['artifact:'],
    entityMutability: 'IMMUTABLE',
  },
  document: {
    mentionType: 'document:mention',
    mentionTrigger: ['document:'],
    entityMutability: 'IMMUTABLE',
  },
  model: {
    mentionType: 'model:mention',
    mentionTrigger: ['model:'],
    entityMutability: 'IMMUTABLE',
  },
  localAttribute: {
    mentionType: 'localAttribute:mention',
    mentionTrigger: ['localAttribute:'],
    entityMutability: 'IMMUTABLE',
  },
  globalAttribute: {
    mentionType: 'globalAttribute:mention',
    mentionTrigger: ['globalAttribute:'],
    entityMutability: 'IMMUTABLE',
  },
  // This is default behavior of draft js plugin
  user: {
    mentionType: 'mention',
    mentionTrigger: ['@'],
    entityMutability: 'SEGMENTED',
  },
};

export const CONDITIONAL_TRANSITION_LINE_COLORS = [
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Indigo',
  'Violet',
];

export const REACTFLOW_NODE_TYPES = {
  WORKFLOW_NODE: 'workflowNode',
  CONDITIONAL_NODE: 'conditionalNode',
};

export const REACTFLOW_NODE_CHOICES = [
  { label: 'Workflow Node', value: REACTFLOW_NODE_TYPES.WORKFLOW_NODE },
  { label: 'Conditional Node', value: REACTFLOW_NODE_TYPES.CONDITIONAL_NODE },
];

export const ATTRIBUTE_OPERATORS = {
  Integer: [
    VALUE_EQUAL,
    VALUE_GREATER_THAN_EQUAL,
    VALUE_LESS_THAN_EQUAL,
    VALUE_LESS_THAN,
    VALUE_GREATER_THAN,
    VALUE_NOT_EQUAL,
  ],
  Decimal: [
    VALUE_EQUAL,
    VALUE_GREATER_THAN_EQUAL,
    VALUE_LESS_THAN_EQUAL,
    VALUE_LESS_THAN,
    VALUE_GREATER_THAN,
    VALUE_NOT_EQUAL,
  ],
  Date: [
    VALUE_EQUAL,
    VALUE_GREATER_THAN_EQUAL,
    VALUE_LESS_THAN_EQUAL,
    VALUE_LESS_THAN,
    VALUE_GREATER_THAN,
    VALUE_NOT_EQUAL,
  ],
  Character: [VALUE_EQUAL, VALUE_CONTAINS, VALUE_NOT_EQUAL],
  SingleSelect: [VALUE_EQUAL, VALUE_NOT_EQUAL],
  Choice: [VALUE_EQUAL, VALUE_CONTAINS, VALUE_NOT_EQUAL],
  Text: [VALUE_EQUAL, VALUE_CONTAINS, VALUE_NOT_EQUAL],
  Bool: [VALUE_EQUAL],
};
