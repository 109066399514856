// @ts-nocheck
import {
  Avatar,
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Drawer,
} from '@mui/material';
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import TaskIcon from '@mui/icons-material/Task';

import RoleList from '../../components/UserManagement/RoleList';
import CreateEditRole from '../../components/UserManagement/CreateEditRole';
import theme from '../../Theme/base';
import MODULE_SUB_MODULE from '../../const/moduleSubmodule';
import { NOT_AUTHORIZED_TO_ACCESS_MESSAGE } from '../../const/CommonConst';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { TabPanel } from '../../components/Common/Tabs';
import useAuth from '../../hooks/Auth/useAuth';
import useUserGroupProvisioning from '../../hooks/UserManagement/useUserGroupProvisioning';
import SearchTextField from '../../components/Common/SearchTextField';
import CreateUser from '../../components/UserManagement/CreateUser';
import CreateEditGroupModal from '../../components/UserManagement/CreateEditGroupModal';
import DeleteModal from '../../components/Common/Modals/DeleteModal';
import SwalToast from '../../components/Common/swalToast';
import useEntity from '../../hooks/Configuration/useEntity';
import useCommon from '../../hooks/useCommon';
import useStateParams from '../../hooks/useStateParams';
import { changeTablePageIndex } from '../../utils/Utils';
import UserList from '../../components/UserManagement/UserList';
import GroupList from '../../components/UserManagement/GroupList';
import TasksModal from '../../components/UserManagement/TasksModal';

const MuiTabPanel = styled(TabPanel)(() => ({
  padding: '0px',
  paddingTop: '0px',
  width: '100%',
}));

const MuiTab = styled(Tab)(() => ({
  minHeight: '25px',
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
  '&.Mui-selected': {
    borderRadius: '3px',
    color: `${theme?.palette?.primary?.main} !important`,
  },
  maxWidth: '200px',
  color: theme?.palette?.primary?.main,
}));

const USER_DETAILS_TITLES = {
  username: 'Username',
  account_id: 'Account Id',
  email: 'Email',
  groups: 'Groups',
};
const UserInfoPanel = ({ open, userDetails, handleClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      BackdropProps={{ invisible: true }}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          top: '230px',
          boxShadow: 'none',
        },
        '& .MuiDrawer-paper': {
          boxShadow: 'none',
        },
      }}
    >
      <Box
        width="408px"
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="hidden"
        flexGrow={1}
        py="24px"
        px="22px"
        sx={{
          border: `1px solid ${theme?.palette?.primary?.main}`,
        }}
      >
        <Box display="flex" flexDirection="row" mb="15px">
          <Box mr="20px">
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h3">About user</Typography>
        </Box>
        <Box display="flex" flexDirection="row" mb="12px">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            marginLeft="45px"
          >
            <Avatar
              sx={{
                color: 'text.primary',
                backgroundColor: 'primary.main',
              }}
            >
              {userDetails?.username
                ?.match(/(\b\S)?/g)
                ?.join('')
                ?.toUpperCase()}
            </Avatar>
            <Typography variant="body1" color="primary" marginTop="15px">
              {userDetails?.username}
            </Typography>
            <Typography variant="body2" marginTop="16px">
              {userDetails?.email}
            </Typography>
          </Box>
        </Box>
        <Grid
          container
          flex="display"
          flexDirection="row"
          marginBottom="16px"
          marginLeft="45px"
        >
          {Object.entries(userDetails)?.map(([key, value]) =>
            USER_DETAILS_TITLES[key] ? (
              <>
                <Grid item xs={3} mb={1}>
                  <Typography variant="body2" color="other.grey2">
                    {USER_DETAILS_TITLES[key]}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {key === 'groups' ? (
                    <Typography variant="body2">
                      {value?.length
                        ? value?.map((group) => group?.groupName).join(', ')
                        : 'No Groups'}
                    </Typography>
                  ) : (
                    <Typography variant="body2">{value}</Typography>
                  )}
                </Grid>
              </>
            ) : null
          )}
        </Grid>
      </Box>
    </Drawer>
  );
};
UserInfoPanel.propTypes = {
  userDetails: PropTypes.oneOfType([PropTypes.object]).isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const WARNING_TEXT = 'This might impact existing models and related entities.';

const UserManagement = () => {
  const { permissions } = useSelector((state) => state?.users);
  const permission =
    permissions?.[
      `${[MODULE_SUB_MODULE?.AO?.key]}_${
        MODULE_SUB_MODULE?.AO?.subModules?.UM?.key
      }`
    ];
  const { customEntityList } = useEntity();
  const [tabValue, setTabValue] = useStateParams(
    window.env.REACT_APP_KEYCLOAK_LOGIN ? 1 : 0,
    'value'
  );
  const [userList, setUserList] = useState([]);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroupForDelete, setSelectedGroupForDelete] = useState('');
  const [selectedGroupForEdit, setSelectedGroupForEdit] = useState({});
  const [selectedUserForEdit, setSelectedUserForEdit] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [groupMode, setGroupMode] = useState('create');
  const [userMode, setUserMode] = useState('create');
  const [templateList, setTemplateList] = useState([]);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [userRoleOpen, setUserRoleOpen] = useState(false);
  const [roleMode, setRoleMode] = useState('create');
  const [selectedRole, setSelectedRole] = useState({});
  const [rolePermission, setRolePermission] = useState({});
  const [isRoleDeleteModalOpen, setIsRoleDeleteModalOpen] = useState(false);
  const [selectedRoleForDelete, setSelectedRoleForDelete] = useState('');
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  // This ref is used to check if data of table is manipulated using external actions or internal table actions. It is going to be true when we change the table values from external actions like delete, update, etc.
  const tableStateUpdateRef = useRef(false);

  const { getAllUsers, createUser, suspendUser, updateUser } = useAuth();
  const {
    getAllGroups,
    createGroup,
    updateGroup,
    deleteGroup,
    getAllRoles,
    createRole,
    deleteRole,
    getSingleRoleDetails,
    updateRole,
    getGroupTasks,
    assignTask,
  } = useUserGroupProvisioning();
  const {
    common,
    dispatch,
    setGroupListSearch,
    setUserListSearch,
    setUserRoleListSearch,
  } = useCommon();

  const getUserDetails = useCallback(() => {
    getAllUsers().then((res) => {
      if (res) {
        setUserList(res?.data);
      }
    });
  }, [getAllUsers, setUserList]);

  /**
   * @function getAllUserRoles
   * @description to get all user role list
   */
  const getAllUserRoles = () => {
    getAllRoles().then((res) => {
      if (res) {
        setRoleList(res?.data);
      }
    });
  };

  /**
   * @function deleteUserRole
   * @description to deleted user role
   */

  const deleteUserRole = (id) => {
    deleteRole(id)?.then(() => {
      SwalToast({
        icon: 'success',
        title: 'User role deleted successfully.',
      });
      getAllUserRoles();
    });
  };

  /**
   * @function updateUserRole
   * @description to update user role permission
   */
  const updateUserRole = useCallback(
    (body) => {
      updateRole(body).then((res) => {
        if (res) {
          SwalToast({
            icon: 'success',
            title: 'User role updated successfully.',
          });
          getAllUserRoles();
        }
      });
    },
    [updateRole, SwalToast, getAllUserRoles]
  );

  /**
   * @function getSingleRole
   * @description to get single user info and its permission
   */
  const getSingleRole = (id) => {
    getSingleRoleDetails({ role_id: id }).then((res) => {
      if (res) {
        setRolePermission(res?.data);
      }
    });
  };

  const createUserRole = useCallback(
    (body) => {
      createRole(body).then((res) => {
        if (res) {
          SwalToast({
            icon: 'success',
            title: 'User role created successfully.',
          });
          getAllUserRoles();
        }
      });
    },
    [createRole, SwalToast, getAllUserRoles]
  );

  const getGroupDetails = useCallback(() => {
    getAllGroups().then((res) => {
      if (res) {
        setGroupList(res?.data?.Groups);
      }
    });
  }, [getAllGroups, setGroupList]);

  const getTemplateList = () => {
    customEntityList().then((res) => {
      if (res) {
        setTemplateList(res?.data);
      }
    });
  };

  const editGroupHandler = (groupid) => {
    setGroupMode('edit');
    const selectedgroup = groupList?.find((group) => group?.id === groupid);
    setSelectedGroupForEdit(selectedgroup);
    setCreateGroupOpen(true);
  };

  const handleOpenTasksModal = () => {
    setIsTaskModalOpen(true);
  };

  const handleCloseTasksModal = useCallback(() => {
    setIsTaskModalOpen(false);
  }, [setIsTaskModalOpen]);

  const deleteGroupHandler = (groupid) => {
    setSelectedGroupForDelete(groupid);
    setIsDeleteModalOpen(true);
  };
  const deleteRoleHandler = (roleId) => {
    setSelectedRoleForDelete(roleId);
    setIsRoleDeleteModalOpen(true);
  };

  const closeDeleteRoleModal = useCallback(() => {
    setIsRoleDeleteModalOpen(false);
  }, [setIsRoleDeleteModalOpen]);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, [setIsDeleteModalOpen]);

  const deleteConfirm = useCallback(() => {
    deleteGroup(selectedGroupForDelete).then((res) => {
      if (res) {
        SwalToast({
          icon: 'success',
          title: 'Group deleted successfully.',
        });
        getGroupDetails();
        closeDeleteModal();
        // turn on the flag to not reset the page
        setSkipPageReset(true);

        // Set 'tableStateUpdateRef' to 'true' to indicate that the update is by external table action i.e. delete operation.
        tableStateUpdateRef.current = true;
      }
    });
  }, [
    deleteGroup,
    SwalToast,
    getGroupDetails,
    closeDeleteModal,
    setSkipPageReset,
    selectedGroupForDelete,
  ]);

  const deleteConfirmRole = useCallback(() => {
    deleteRole(selectedRoleForDelete)?.then(() => {
      SwalToast({
        icon: 'success',
        title: 'User role deleted successfully.',
      });
      getAllUserRoles();
      closeDeleteRoleModal();
    });
  }, [
    deleteRole,
    selectedRoleForDelete,
    SwalToast,
    getAllUserRoles,
    closeDeleteRoleModal,
  ]);

  const onTableStateChange = useCallback(
    ({ pageIndex, data, pageSize, gotoPage }) => {
      if (tableStateUpdateRef.current) {
        changeTablePageIndex(pageIndex, data, pageSize, gotoPage);
      }
      // Set 'tableStateUpdateRef' to 'false' to indicate that the table state is changed by internal table actions.
      tableStateUpdateRef.current = false;
    },
    [changeTablePageIndex]
  );

  const handleViewUserDetails = (user) => {
    setOpenUserInfo(true);
    setCurrentUser(user);
  };

  const editUserHandler = (user) => {
    setUserMode('edit');
    setSelectedUserForEdit(user);
    setCreateUserOpen(true);
  };

  const handleSuspendUser = (suspendAction, id) => {
    suspendUser(suspendAction, id).then((res) => {
      if (res) {
        SwalToast({
          icon: 'success',
          title: res?.data?.msg,
        });
        getUserDetails();
        // turn on the flag to not reset the page
        setSkipPageReset(true);
      }
    });
  };

  useEffect(() => {
    if ([0, 1]?.includes(tabValue)) {
      getUserDetails();
      getGroupDetails();
      getTemplateList();
    }
    if (tabValue === 2) {
      getAllUserRoles();
    }
  }, [tabValue]);

  useEffect(() => {
    // // turn off the flag to reset the page for next operations like global filter, etc.
    setSkipPageReset(false);

    // Set 'tableStateUpdateRef' to 'false' to reset.
    tableStateUpdateRef.current = false;
  }, [userList, groupList]);

  const userMenus = useMemo(
    () => [
      {
        label: 'View user details',
        id: 'about_user',
        icon: VisibilityIcon,
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          handleViewUserDetails(props?.original);
        },
      },
      {
        label: 'Suspend user',
        id: 'block_user',
        icon: PersonOffIcon,
        isMenuDisabled: ({ props }) =>
          // eslint-disable-next-line react/prop-types
          permission !== 'Manage' || !props?.original?.is_active,
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          handleSuspendUser('block', props?.original?.user_id);
        },
      },
      {
        label: 'Unsuspend user',
        id: 'unblock_user',
        icon: PersonIcon,
        isMenuDisabled: ({ props }) =>
          // eslint-disable-next-line react/prop-types
          permission !== 'Manage' || props?.original?.is_active,
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          handleSuspendUser('unblock', props?.original?.user_id);
        },
      },
      {
        label: 'Edit user',
        id: 'edit_user',
        icon: BorderColorIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          editUserHandler(props?.original);
        },
      },
    ],
    [permission, handleViewUserDetails, handleSuspendUser, editUserHandler]
  );

  const groupMenus = useMemo(
    () => [
      {
        label: 'Delete group',
        id: 'delete_group',
        icon: DeleteIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          deleteGroupHandler(props?.original?.id);
        },
      },
      {
        label: 'Edit group',
        id: 'edit_group',
        icon: BorderColorIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          editGroupHandler(props?.original?.id);
        },
      },
      {
        label: 'Tasks',
        id: 'tasks',
        icon: TaskIcon,
        isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          handleOpenTasksModal();
          // eslint-disable-next-line react/prop-types
          setSelectedGroupForEdit(props?.original);
        },
      },
    ],
    [
      permission,
      deleteGroupHandler,
      editGroupHandler,
      setSelectedGroupForEdit,
      handleOpenTasksModal,
    ]
  );

  const editUserRole = (role) => {
    setRoleMode('edit');
    getSingleRole(role?.role_id);
    setSelectedRole(role);
    setUserRoleOpen(true);
  };

  const roleMenu = useMemo(
    () => [
      {
        label: 'Delete role',
        id: 'delete_role',
        icon: DeleteIcon,
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          deleteRoleHandler(props?.original?.role_id);
        },
      },
      {
        label: 'Edit role',
        id: 'edit_role',
        icon: BorderColorIcon,
        // isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          editUserRole(props?.original);
        },
      },
    ],
    [permission, deleteUserRole, editUserRole]
  );

  /**
   * Handles the change event for user search input element.
   * If the input value starts with a space character, prevents the default behavior.
   * Otherwise, dispatches an action to update the user search.
   * @param {Event} e - The change event object.
   * @returns {void}
   */
  const handleUserChange = useCallback(
    (e) => {
      if (e?.target?.value?.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setUserListSearch(e?.target?.value));
    },
    [dispatch, setUserListSearch]
  );
  /**
   * Handles the change event for group search input element.
   * If the input value starts with a space character, prevents the default behavior.
   * Otherwise, dispatches an action to update the group search.
   * @param {Event} e - The change event object.
   * @returns {void}
   */
  const handleGroupChange = useCallback(
    (e) => {
      if (e?.target?.value?.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setGroupListSearch(e?.target?.value));
    },
    [dispatch, setGroupListSearch]
  );

  const handleUserRoleChange = useCallback(
    (e) => {
      if (e?.target?.value?.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setUserRoleListSearch(e?.target?.value));
    },
    [dispatch, setUserRoleListSearch]
  );

  /**
   * Closes the user creation dialog and resets related state.
   * @function
   * @name handleCloseUser
   * @returns {void}
   */
  const handleCloseUser = useCallback(() => {
    setCreateUserOpen(false);
    setSelectedUserForEdit({});
    setUserMode('create');
  }, [setUserMode, setSelectedUserForEdit, setCreateUserOpen]);

  /**
   * Closes the group creation dialog and resets related state.
   * @function
   * @name handleCloseGroup
   * @returns {void}
   */
  const handleCloseGroup = useCallback(() => {
    setCreateGroupOpen(false);
    setSelectedGroupForEdit({});
    setGroupMode('create');
  }, [setCreateGroupOpen, setSelectedGroupForEdit, setGroupMode]);
  /**
   * Closes the role creation dialog and resets related state.
   * @function
   * @name handleCloseRoleModal
   * @returns {void}
   */
  const handleCloseRoleModal = useCallback(() => {
    setUserRoleOpen(false);
    setRoleMode('create');
  }, [setUserRoleOpen, setRoleMode]);

  const handleTabChange = useCallback(
    (_event, newValue) => setTabValue(newValue),
    [setTabValue]
  );

  const openAddUserModal = useCallback(() => {
    setCreateUserOpen(true);
  }, [setCreateUserOpen]);

  const openCreateGroupModal = useCallback(() => {
    setCreateGroupOpen(true);
  }, [setCreateGroupOpen]);

  const openAddRoleModal = useCallback(() => {
    setUserRoleOpen(true);
  }, [setUserRoleOpen]);

  const closeUserInfoPanel = useCallback(
    () => setOpenUserInfo(false),
    [setOpenUserInfo]
  );

  return (
    <Box sx={{ background: () => theme?.palette?.primary }}>
      <Grid container xs={12} rowGap={2}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'center', sm: 'inherit' }}
          flexWrap="wrap"
          sx={{ background: (_theme) => _theme?.palette?.secondary?.light1 }}
        >
          <Box>
            <Breadcrumb />
          </Box>
          <Box
            pl={2}
            pr={1}
            overflow="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              sx={{
                border: 'none',
                '& .MuiTabs-indicator ': {
                  // display: 'none',
                },
              }}
            >
              {!window.env.REACT_APP_KEYCLOAK_LOGIN && (
                <MuiTab title="Users" label="Users" value={0} />
              )}
              <MuiTab title="Groups" label="Groups" value={1} />
              <MuiTab title="User Roles" label="User Roles" value={2} />
            </Tabs>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          flexDirection="column"
          justifyContent="space-evenly"
          px={2}
        >
          <MuiTabPanel value={tabValue} index={0}>
            <Grid
              spacing={2}
              container
              xs={12}
              item
              mb={2}
              justifyContent="space-between"
            >
              <Grid item xs={7} sm={8} md={9} lg={10} xl={11} flexGrow>
                <SearchTextField
                  value={common?.userListSearch}
                  onChange={handleUserChange}
                  placeholder="Search"
                />
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={2}
                xl={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  disabled={permission !== 'Manage'}
                  title={
                    permission !== 'Manage'
                      ? NOT_AUTHORIZED_TO_ACCESS_MESSAGE
                      : 'Add user.'
                  }
                  onClick={openAddUserModal}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
            <UserList
              userList={userList}
              tabValue={tabValue}
              userMenus={userMenus}
              skipPageReset={skipPageReset}
              common={common}
            />
          </MuiTabPanel>
          <MuiTabPanel value={tabValue} index={1}>
            <Grid
              spacing={2}
              container
              xs={12}
              item
              mb={2}
              justifyContent="space-between"
            >
              <Grid item xs={7} sm={8} md={9} lg={10} xl={11} flexGrow>
                <SearchTextField
                  value={common?.groupListSearch}
                  onChange={handleGroupChange}
                  placeholder="Search"
                />
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={2}
                xl={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  disabled={permission !== 'Manage'}
                  title={
                    permission !== 'Manage'
                      ? NOT_AUTHORIZED_TO_ACCESS_MESSAGE
                      : 'Add group.'
                  }
                  onClick={openCreateGroupModal}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Add Group
                </Button>
              </Grid>
            </Grid>
            <GroupList
              groupList={groupList}
              tabValue={tabValue}
              menus={groupMenus}
              skipPageReset={skipPageReset}
              common={common}
              onTableStateChange={onTableStateChange}
            />
          </MuiTabPanel>
          <MuiTabPanel value={tabValue} index={2}>
            <Grid
              spacing={2}
              container
              xs={12}
              item
              mb={2}
              justifyContent="space-between"
            >
              <Grid item xs={7} sm={8} md={9} lg={10} xl={11} flexGrow>
                <SearchTextField
                  value={common?.userRoleListSearch}
                  onChange={handleUserRoleChange}
                  placeholder="Search"
                />
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={2}
                xl={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  // disabled={permission !== 'Manage'}
                  title={
                    permission !== 'Manage'
                      ? NOT_AUTHORIZED_TO_ACCESS_MESSAGE
                      : 'Add role.'
                  }
                  onClick={openAddRoleModal}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Add Role
                </Button>
              </Grid>
            </Grid>
            <RoleList
              roleList={roleList}
              tabValue={tabValue}
              menus={roleMenu}
              skipPageReset={skipPageReset}
              common={common}
            />
          </MuiTabPanel>

          <DeleteModal
            open={isDeleteModalOpen}
            handleClose={closeDeleteModal}
            deleteConfirm={deleteConfirm}
            alertLabelText="Do you want to delete this Group?"
          />
          <DeleteModal
            open={isRoleDeleteModalOpen}
            handleClose={closeDeleteRoleModal}
            deleteConfirm={deleteConfirmRole}
            alertLabelText="Do you want to delete this user role?"
            warningText={WARNING_TEXT}
          />

          {createUserOpen ? (
            <CreateUser
              open={createUserOpen}
              handleClose={handleCloseUser}
              createUser={createUser}
              getUserDetails={getUserDetails}
              groupList={groupList}
              mode={userMode}
              currentUser={selectedUserForEdit}
              updateUser={updateUser}
              suspendUser={suspendUser}
              setSkipPageReset={setSkipPageReset}
            />
          ) : null}
          {createGroupOpen ? (
            <CreateEditGroupModal
              open={createGroupOpen}
              handleClose={handleCloseGroup}
              userList={userList}
              getGroupDetails={getGroupDetails}
              createGroup={createGroup}
              updateGroup={updateGroup}
              mode={groupMode}
              selectedGroup={selectedGroupForEdit}
              templateList={templateList}
              setSkipPageReset={setSkipPageReset}
            />
          ) : null}
          {userRoleOpen ? (
            <CreateEditRole
              open={userRoleOpen}
              handleClose={handleCloseRoleModal}
              roleList={roleList}
              mode={roleMode}
              selectedRole={selectedRole}
              createUserRole={createUserRole}
              rolePermission={rolePermission}
              updateUserRole={updateUserRole}
            />
          ) : null}

          <UserInfoPanel
            open={openUserInfo}
            handleClose={closeUserInfoPanel}
            userDetails={currentUser}
          />
          {isTaskModalOpen ? (
            <TasksModal
              open={isTaskModalOpen}
              handleClose={handleCloseTasksModal}
              selectedGroupForEdit={selectedGroupForEdit}
              getGroupTasks={getGroupTasks}
              assignTask={assignTask}
              userList={userList}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTransaction('UserManagement', 'component')(UserManagement);
