// @ts-nocheck
import React, { useCallback } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import {
  MODULE_PERMISSIONS_OPTIONS,
  SUB_MODULE_PERMISSIONS_OPTIONS,
  VALUE_MAPPINGS,
} from '../../../const/UserManagement';
import PermissionOptionButton from '../../UserManagement/PermissionOptionButton';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme?.palette?.other?.white,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme?.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme?.spacing(2),
  backgroundColor: theme?.palette?.other?.white,
}));

const GroupsPermissionStep = ({ moduleSubModules, setModuleSubModules }) => {
  /**
   * @function updateTemplatePermission
   * Updates the permission of an object with a specific objKey to a newPermission value.
   *
   * @param {Object|Array} obj - The object to update the permission.
   * @param {string} objKey - The objKey to match for updating the permission.
   * @param {string} newPermission - The new permission value to set.
   * @returns {void}
   */

  function updateTemplatePermission(obj, objKey, newPermission) {
    if (typeof obj === 'object' && obj !== null) {
      if (Object.hasOwn(obj, 'objKey') && obj?.objKey === objKey) {
        // eslint-disable-next-line no-param-reassign
        obj.permission = newPermission;
      }
      Object.keys(obj).forEach((key) => {
        updateTemplatePermission(obj[key], objKey, newPermission);
      });
      return;
    }
    if (Array.isArray(obj)) {
      obj?.forEach((item) => {
        updateTemplatePermission(item, objKey, newPermission);
      });
    }
  }

  /**
   * @function calculateModulePermission
   * Calculates the module permission based on the submodule permissions.
   *
   * @param {Object} module - The module object containing subModules.
   * @returns {string} The calculated module permission.
   */

  const calculateModulePermission = (module) => {
    const moduleCopy = cloneDeep(module);

    // Extracting submodule permissions as an array
    const submodulePermissions = Object.values(moduleCopy?.subModules).map(
      (subModule) => subModule?.permission
    );

    // Helper function to check if all permissions match the target permissions
    const allPermissionsEqual = (permissions, targetPermissions) =>
      permissions?.every((permission) =>
        targetPermissions?.includes(permission)
      );

    // Using a switch statement to handle different permission scenarios
    switch (true) {
      case allPermissionsEqual(submodulePermissions, ['View', 'No']):
        return 'View'; // Return 'View' if all permissions are either 'View' or 'No'
      case allPermissionsEqual(submodulePermissions, ['Manage', 'Yes']):
        return 'Manage'; // Return 'Manage' if all permissions are either 'Manage' or 'Yes'
      case allPermissionsEqual(submodulePermissions, ['None']):
        return 'None'; // Return 'None' if all permissions are 'None'
      default:
        return 'Custom'; // Return 'Custom' for any other combination of permissions
    }
  };

  /**
   * @function handlePermissionChange
   * Handles the change of permissions for a module or submodule.
   *
   * @param {string} moduleKey - The key of the module.
   * @param {string} subModuleKey - The key of the submodule. Pass null or undefined if changing the module permission.
   * @param {string} option - The new permission option to apply.
   * @param {boolean} [isTemplate=false] - Indicates if the permission change is for a template.
   * @returns {void}
   */

  const handlePermissionChange = useCallback(
    (moduleKey, subModuleKey, option, isTemplate = false) => {
      const updatedModuleSubModules = cloneDeep(moduleSubModules);

      const module = updatedModuleSubModules[moduleKey];
      const subModule = subModuleKey
        ? module?.subModules?.[subModuleKey]
        : null;

      if (isTemplate) {
        // If we select template to change permission of it then updating the templates permission.
        updateTemplatePermission(updatedModuleSubModules, subModuleKey, option);
        module.subModules.TMP.permission = calculateModulePermission(
          module?.subModules?.TMP
        );

        // Updating module permission based on templates permissions.
        module.permission = calculateModulePermission(module);
        setModuleSubModules(cloneDeep(updatedModuleSubModules));
        return;
      }

      switch (true) {
        case Boolean(subModule):
          if (subModuleKey === 'TMP') {
            // If we click on templates parent module, i.e. Templates (objKey : 'TMP') then change all the templates permissions to the permission of module.
            Object.values(subModule?.subModules).forEach((template) => {
              // eslint-disable-next-line no-param-reassign
              template.permission = option;
            });
          }

          subModule.permission = option;
          // Updating module permission based on subModules permissions.
          module.permission = calculateModulePermission(module);
          break;

        case Boolean(module):
          module.permission = option;
          // Updating Submodules permission when we change module permission

          Object.values(module?.subModules).forEach((subModuleItem) => {
            if (['DBC', 'DBN'].includes(subModuleItem?.objKey)) {
              // eslint-disable-next-line no-param-reassign
              subModuleItem.permission = VALUE_MAPPINGS?.[option];
            } else {
              // eslint-disable-next-line no-param-reassign
              subModuleItem.permission = option;
            }
          });

          Object.values(module?.subModules?.TMP?.subModules || {}).forEach(
            (template) => {
              // eslint-disable-next-line no-param-reassign
              template.permission = option;
            }
          );
          break;

        default:
          break;
      }

      setModuleSubModules(cloneDeep(updatedModuleSubModules));
    },
    [
      setModuleSubModules,
      cloneDeep,
      moduleSubModules,
      updateTemplatePermission,
      calculateModulePermission,
    ]
  );

  const renderPermissions = (
    permission,
    moduleKey,
    subModuleKey,
    isTemplate = false
  ) => {
    const options = subModuleKey
      ? SUB_MODULE_PERMISSIONS_OPTIONS?.[subModuleKey] ||
        SUB_MODULE_PERMISSIONS_OPTIONS?.Default
      : MODULE_PERMISSIONS_OPTIONS?.[moduleKey] ||
        MODULE_PERMISSIONS_OPTIONS?.Default;

    return (
      <Box ml={2}>
        <Stack direction="row" spacing={0.5}>
          {options?.map((option, index) => (
            <div key={option}>
              <PermissionOptionButton
                handlePermissionChange={handlePermissionChange}
                isTemplate={isTemplate}
                option={option}
                moduleKey={moduleKey}
                subModuleKey={subModuleKey}
                permission={permission}
                mode="group"
              />
              {/* This condition is for not showing slash(/) for last element. */}
              {options?.length !== index + 1 && (
                <Typography
                  sx={{
                    color: (theme) => theme?.palette?.other?.white2,
                  }}
                >
                  /
                </Typography>
              )}
            </div>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <Grid item xs={12}>
      <Box p={2}>
        <Grid container spacing={2}>
          {Object.values(moduleSubModules).map((module) => (
            <Accordion key={module?.objKey}>
              <AccordionSummary
                expandIcon={<KeyboardArrowRightIcon />}
                aria-controls={module?.objKey}
              >
                <Box display="flex" alignItems="center">
                  <Typography>{module?.label}</Typography>
                </Box>
                {renderPermissions(module?.permission, module?.objKey, null)}
              </AccordionSummary>
              <AccordionDetails>
                <Box ml={4}>
                  {Object.values(module?.subModules).map((subModule) => {
                    return subModule?.objKey === 'TMP' ? (
                      // Rendering Template Accordion
                      <Accordion key={subModule?.objKey}>
                        <AccordionSummary
                          expandIcon={<KeyboardArrowRightIcon />}
                          aria-controls={subModule?.objKey}
                          sx={{
                            '&.MuiAccordionSummary-root': {
                              paddingLeft: 0,
                            },
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography>{subModule?.label}</Typography>
                          </Box>
                          {renderPermissions(
                            subModule?.permission,
                            module?.objKey,
                            subModule?.objKey
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box ml={4}>
                            {Object.values(subModule?.subModules)?.map(
                              // eslint-disable-next-line no-shadow
                              (subSubModule) => (
                                <Box key={subSubModule?.objKey} display="flex">
                                  <Typography
                                    mr={2}
                                    sx={{
                                      color: 'other.gray2',
                                    }}
                                  >
                                    {subSubModule?.label} :
                                  </Typography>
                                  {renderPermissions(
                                    subSubModule?.permission,
                                    module?.objKey,
                                    subSubModule?.objKey,
                                    true
                                  )}
                                </Box>
                              )
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <Box key={subModule?.objKey} display="flex">
                        <Typography
                          mr={2}
                          sx={{
                            color: (theme) => theme?.palette?.other?.gray2,
                          }}
                        >
                          {subModule?.label} :
                        </Typography>
                        {renderPermissions(
                          subModule?.permission,
                          module?.objKey,
                          subModule?.objKey
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

GroupsPermissionStep.propTypes = {
  moduleSubModules: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setModuleSubModules: PropTypes.func.isRequired,
};

export default GroupsPermissionStep;
