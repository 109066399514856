const MODULE_SUB_MODULE = {
  MI: {
    objKey: 'MI',
    label: 'Models',
    key: 'ModelInventory',
    subModules: {
      MI: {
        objKey: 'MI',
        label: 'My Models',
        key: 'modelInventory',
      },
      DB: {
        objKey: 'DB',
        label: 'Dashboard',
        key: 'dashboard',
      },
      DBC: {
        objKey: 'DBC',
        label: 'Reports',
        key: 'dashboardReports',
      },
      DBN: {
        objKey: 'DBN',
        label: 'Model Architecture',
        key: 'dashboardNetwork',
      },
      MR: {
        objKey: 'MR',
        label: 'Assessment',
        key: 'modelRequest',
      },
    },
  },
  MA: {
    objKey: 'MA',
    label: 'Model Artifacts',
    key: 'ModelAssociation',
    subModules: {},
  },
  DI: {
    objKey: 'DI',
    label: 'Documents',
    key: 'DocumentInventory',
    subModules: {
      DI: {
        objKey: 'DI',
        label: 'Documents',
        key: 'documentInventory',
      },
    },
  },
  RR: {
    objKey: 'RR',
    label: 'Rules & Reports',
    key: 'RulesReports',
    subModules: {
      RU: {
        objKey: 'RU',
        label: 'Rules',
        key: 'rules',
      },
      RP: {
        objKey: 'RP',
        label: 'Reports',
        key: 'report',
      },
    },
  },
  BA: {
    objKey: 'BA',
    label: 'Bulk Actions',
    key: 'BulkActions',
    subModules: {
      BU: {
        objKey: 'BU',
        label: 'Bulk upload',
        key: 'bulkUpload',
      },
      BT: {
        objKey: 'BT',
        label: 'Bulk transition',
        key: 'bulkTransition',
      },
    },
  },
  CNF: {
    objKey: 'CNF',
    label: 'Configuration',
    key: 'Configuration',
    subModules: {
      WF: {
        objKey: 'WF',
        label: 'Workflows',
        key: 'workflow',
      },
      TL: {
        objKey: 'TL',
        label: 'Templates',
        key: 'templateList',
      },
      GA: {
        objKey: 'GA',
        label: 'Attributes',
        key: 'globalAttributes',
      },
      DT: {
        objKey: 'DT',
        label: 'Document Template',
        key: 'documentTemplate',
      },
      DC: {
        objKey: 'DC',
        label: 'Dynamic Chart',
        key: 'dynamicChart',
      },
      AUT: {
        objKey: 'AUT',
        label: 'Automation',
        key: 'automation',
      },
    },
  },
  AO: {
    objKey: 'AO',
    label: 'Administrative Options',
    key: 'AdministrativeOptions',
    subModules: {
      UM: {
        objKey: 'UM',
        label: 'User management',
        key: 'userManagement',
      },
      AM: {
        objKey: 'AM',
        label: 'App management',
        key: 'appManagement',
      },
    },
  },
  AR: {
    objKey: 'AR',
    label: 'Access Request',
    key: 'accessRequest',
    subModules: {},
  },
};

const DYNAMIC_MODULE_SUBMODULE = {
  MI: {
    objKey: 'MI',
    label: 'Models',
    key: 'ModelInventory',
    subModules: {},
  },
  MA: {
    objKey: 'MA',
    label: 'Model Artifacts',
    key: 'ModelAssociation',
    subModules: {},
  },
};

export default MODULE_SUB_MODULE;

export { DYNAMIC_MODULE_SUBMODULE };
