// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { init as initApm } from '@elastic/apm-rum';
import Store, { persistor } from './store/Store';

import './index.css';
import App from './App';
import packageJson from '../package.json';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

try {
  // eslint-disable-next-line no-unused-vars
  const apm = initApm({
    serviceName: `${packageJson.name}-${window.env.REACT_APP_ENV}`,

    serverUrl: `${window.env.REACT_APP_ERROR_LOGGER}`,

    // Set service version (required for sourcemap feature)
    serviceVersion: packageJson.version,
    environment: window.env.REACT_APP_ENV,
    active: process.env.NODE_ENV === 'production',
    distributedTracingOrigins: window.location.pathname,
    logLevel: 'warn',
  });
  window.apm = apm;
} catch (e) {
  // Do nothing
}

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
