// @ts-nocheck
/* eslint-disable camelcase */
import * as Yup from 'yup'; // NOSONAR
import {
  map,
  omit,
  isEmpty,
  upperCase,
  toNumber,
  isArray,
  uniq,
  flatten,
  isString,
} from 'lodash';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import Keycloak from 'keycloak-js';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { matchPath } from 'react-router-dom';

import API_ENDPOINTS from '../const/ApiEndPoints';
import SwalToast from '../components/Common/swalToast';
import { PATH_TAGS } from '../const/HelpAndSupport';

const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';

const getDataType = (inputControlType, dataType) => {
  const dataTypeMap = {
    Character: 'string',
    Integer: 'number',
    Decimal: 'number',
    Date: 'date',
    Paragraph: 'object',
  };

  let jsDataType = dataTypeMap?.[dataType];

  if (inputControlType === 'MULTI_SELECT') {
    jsDataType = 'array';
  }

  return jsDataType;
};

const createYupSchema = (schema, config) => {
  const {
    name,
    data_type,
    is_null,
    input_control_type,
    yup_schema = [],
    is_derived,
    isAttributeDisabled,
  } = config;
  // old created model and association breaking this reason added condition
  if (!isArray(yup_schema)) {
    return schema;
  }

  // If the attribute is derived then just skip the creation of Yup schema for that attribute
  if (is_derived) {
    return schema;
  }

  // If the isAttributeDisabled then just skip the creation of Yup schema for that attribute
  if (isAttributeDisabled) {
    return schema;
  }

  const dataType = getDataType(input_control_type, data_type);

  if (!Yup[dataType]) {
    return schema;
  }
  let validator = Yup[dataType]();
  if (is_null) {
    validator = validator.nullable();
  }
  if (dataType === 'number') {
    validator = validator
      .min(
        Number.MIN_SAFE_INTEGER,
        `The value falls short of the minimum allowable range ${Number.MIN_SAFE_INTEGER}`
      )
      .max(
        Number.MAX_SAFE_INTEGER,
        `The value exceeds the maximum allowable range of ${Number.MAX_SAFE_INTEGER}`
      );
  }
  yup_schema?.forEach((validation) => {
    const { params, type } = validation;

    if (!validator[type]) {
      return;
    }
    if (params) {
      validator = validator[type](...params);
    }
  });
  // eslint-disable-next-line no-param-reassign
  schema[name] = validator;
  return schema;
};

/**
 * 📌 Note: This custom implementation is used instead of lodash's `isEmpty` function
 *       due to a known issue with lodash's handling of numbers, false, true values.
 *       Refer to https://github.com/lodash/lodash/issues/496 for more information.
 *
 * The function checks if a value is considered empty, handling various data types.
 * @param value - The value parameter is a variable that can hold any data type (undefined, null,
 * object, string, number, boolean, etc.) and is used to check if it is empty or not.
 * @returns The function `isValueEmpty` takes in a parameter `value` and returns a boolean value. It
 * returns `true` if the value is `undefined`, `null`, an empty object, or a string with only
 * whitespace characters. Otherwise, it returns `false`.
 */

const isValueEmpty = (value) => {
  switch (true) {
    case value === undefined:
    case value === null:
      return true;
    case typeof value === 'object':
      return !Object.keys(value).length;
    case typeof value === 'string':
      return !value.trim().length;
    default:
      return false;
  }
};

/**
 * Rounds a given number to a specified number of decimal places.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} [roundOfNumber=3] - The number of decimal places to round to. Defaults to 3 if not specified.
 * @returns {number} The rounded number.
 *
 * @example
 * roundToDecimals(3.1415926535);
 * // returns 3.142
 *
 * @example
 * roundToDecimals(3.1415926535, 2);
 * // returns 3.14
 *
 * @example
 * roundToDecimals(3.1, 5);
 * // returns 3.1
 */
function roundToDecimals(value, roundOfNumber = 3) {
  if (typeof value === 'number') {
    return parseFloat(value.toFixed(roundOfNumber));
  }
  return value;
}

/**
 * @function isDateValid
 * @description Checks if a date string is valid. Supports both UTC and "yyyy-mm-dd" formats.
 *
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} `true` if the date string is valid, `false` otherwise.
 */
const isDateValid = (dateString) => {
  const FORMAT = [
    DATE_FORMAT_DD_MM_YYYY,
    DATE_FORMAT_MM_DD_YYYY,
    DATE_FORMAT_YYYY_MM_DD,
    moment.ISO_8601,
  ];
  if (!Number.isNaN(Number(dateString))) {
    return false;
  }
  if (!isString(dateString)) {
    return false;
  }
  return moment(dateString, FORMAT, true).isValid();
};

/**
 * Replaces the specified keys in the given item with their corresponding labels based on the provided fieldLabels configuration.
 *
 * The function iterates through the keys in `fieldLabels` and uses regular expressions to replace
 * each key in the `item` with the corresponding `label`. It can be configured to either match whole words
 * or allow partial matches using the `fullWordMatch` flag.
 *
 * @param {string} item - The string item in which the replacements need to be made.
 * @param {Object} fieldLabels - An object where each key is the string to be replaced and its value is an object containing:
 * @param {string} fieldLabels.label - The label to replace the key with.
 * @param {boolean} fieldLabels.fullWordMatch - A boolean indicating whether the key should be replaced only when it matches the whole word.
 *
 * @returns {string} - The transformed string item with replacements made based on `fieldLabels`.
 *
 * @example
 * // Define the fieldLabels configuration
 * const fieldLabels = {
 *   type: { label: 'Template Name', fullWordMatch: true },
 *   association: { label: 'ARTIFACT', fullWordMatch: false }
 * };
 *
 * // Example item string
 * const item = "This type is associated with the association system.";
 *
 * // Replace using the fieldLabels configuration
 * const result = replaceLabel(item, fieldLabels);
 * console.log(result); // Output: "This Template Name is associated with the ARTIFACT system."
 *
 * @example
 * // Example with partial word matching
 * const fieldLabels = {
 *   entity: { label: 'Object', fullWordMatch: false },
 * };
 * const item = "entity_id, entity_code, entity_alias";
 * const result = replaceLabel(item, fieldLabels);
 * console.log(result); // Output: "Object_id, Object_code, Object_alias"
 */
const replaceLabel = (item, fieldLabels) => {
  // If the item is not added in the fieldsLabel object then we will not replace it
  if (!fieldLabels[item]) {
    return item;
  }
  Object.keys(fieldLabels).forEach((key) => {
    const { label, fullWordMatch } = fieldLabels[key];
    const regex = new RegExp(fullWordMatch ? `\\b${key}\\b` : key, 'gi');
    // eslint-disable-next-line no-param-reassign
    item = item.replace(regex, label);
  });
  return item;
};

const FIELD_LABELS = {
  type: { label: 'TEMPLATE NAME', fullWordMatch: true },
  association_name: { label: 'ARTIFACT NAME', fullWordMatch: true },
  association_id: { label: 'ARTIFACT ID', fullWordMatch: true },
};

/**
 * @function createDynamicColumns
 * @description function calculate calculate dynamic columns for table and return array of columns
 * @param {boolean} data -array of colums names
 * @param {number} width -minimum width of column
 *  @param {function} HeaderCell - type of header
 * @returns  {Array} array of dynamic columns
 */
const createDynamicColumns = (
  data,
  width,
  HeaderCell,
  excludeCols = [],
  disabledOrderingCols = []
) => {
  const FORMAT = [
    DATE_FORMAT_DD_MM_YYYY,
    DATE_FORMAT_MM_DD_YYYY,
    DATE_FORMAT_YYYY_MM_DD,
  ];

  // Helper function to get the color based on health status
  const getHealthColor = (healthStatus, theme) => {
    switch (healthStatus) {
      case 'Low':
        return theme?.palette?.success?.light;
      case 'Medium':
        return theme?.palette?.other?.modelPriorityMedium;
      case 'High':
        return theme?.palette?.error?.main;
      default:
        return theme?.palette?.text?.primary;
    }
  };

  // Helper function to get entity ID based on entity type
  const getEntityId = (row) => {
    return (
      row?.original?.entity_code ||
      (row?.original?.entity_type === 'ModelInventory'
        ? row?.original?.model_id
        : row?.original?.association_id)
    );
  };

  // Helper function to get entity name based on entity type
  const getEntityName = (row) => {
    if (row?.original?.entity_alias) {
      return row?.original?.entity_alias;
    }
    if (row?.original?.entity_type === 'ModelInventory') {
      return row?.original?.model_name;
    }
    return row?.original?.association_name;
  };

  // Helper function to format date
  const formatDate = (date) => {
    return moment(date, FORMAT).format(DATE_FORMAT_MM_DD_YYYY);
  };

  return data
    ?.filter((item) => !excludeCols?.includes(item))
    ?.map((item, index) => {
      return {
        Header: HeaderCell,
        id: item,
        accessor: (value) => {
          if (item === 'model_id' || item === 'association_id') {
            return (
              value?.entity_code ||
              (value?.entity_type === 'ModelInventory'
                ? value?.model_id
                : value?.association_id)
            );
          }

          if (item === 'model_name' || item === 'association_name') {
            return (
              value?.entity_alias ||
              (value?.entity_type === 'ModelInventory'
                ? value?.model_name
                : value?.association_name)
            );
          }
          return value[item];
        },
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const cellValue = row?.original?.[item];

          if (item === 'Health') {
            return (
              <CircleIcon
                fontSize="small"
                sx={{ color: (theme) => getHealthColor(cellValue, theme) }}
              />
            );
          }

          if (item === 'model_id' || item === 'association_id') {
            return getEntityId(row);
          }

          if (item === 'model_name' || item === 'association_name') {
            return getEntityName(row);
          }

          if (isArray(cellValue)) {
            return cellValue?.map((attr) => attr.value)?.join(', ');
          }
          // If value is in date format it will be converted into MM/DD/YYYY
          if (isDateValid(cellValue)) {
            return formatDate(cellValue);
          }

          if (typeof cellValue === 'number') {
            return isValueEmpty(cellValue)
              ? '-'
              : roundToDecimals(cellValue, 3);
          }

          return cellValue;
        },
        ...(width ? { width } : {}),
        heading: upperCase(replaceLabel(item, FIELD_LABELS)), // Convert item to a more readable label format and make also in uppercase (e.g., "due_date" -> "DUE DATE") ,
        disableOrdering: disabledOrderingCols.includes(item),
        index,
      };
    });
};

const createDynamicFilters = (data, excludedCols) => {
  return map(omit(data, excludedCols), (value) => {
    const dataType = value?.dataType;
    let operators;

    if (dataType === 'Character') {
      if (value?.fieldType === 'MULTI_SELECT') {
        operators = [
          { name: '!=', label: '!=' },
          { name: '=', label: '=' },
          { name: 'in', label: 'In' },
          { name: 'not in', label: 'Not in' },
        ];
      } else {
        operators = [
          { name: '!=', label: '!=' },
          { name: '=', label: '=' },
        ];
      }
    } else if (dataType === 'Date') {
      operators = [
        { name: '<', label: '<' },
        { name: '>', label: '>' },
        { name: '!=', label: '!=' },
        { name: '=', label: '=' },
      ];
    } else if (dataType === 'Integer' || dataType === 'Decimal') {
      operators = [
        { name: '>=', label: '>=' },
        { name: '<=', label: '<=' },
        { name: '<', label: '<' },
        { name: '>', label: '>' },
        { name: '!=', label: '!=' },
        { name: '=', label: '=' },
      ];
    } else {
      operators = [
        { name: '!=', label: '!=' },
        { name: '=', label: '=' },
      ];
    }

    return {
      name: value?.field,
      label: upperCase(replaceLabel(value?.field, FIELD_LABELS)), // Convert value?.field to a more readable label format and make also in uppercase (e.g., "due_date" -> "DUE DATE")
      datatype: dataType,
      operators,
    };
  });
};

const blockInvalidNumbers = (e, type) => {
  if (type === 'Integer') {
    return ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  }
  return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};

const isJsonString = (str) => {
  if (isEmpty(str)) {
    return true;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * The function converts a default attribute value to a number if the attribute data type is Integer or
 * Decimal.
 * @returns The function `convertDefaultValueToNumber` returns a number value if the
 * `attributeDataType` is either "Integer" or "Decimal" and the `attributeDefaultValue` is a valid
 * number. If the `attributeDefaultValue` is not a valid number, an empty string is returned. If the
 * `attributeDataType` is not "Integer" or "Decimal", the `attributeDefaultValue` is returned as is
 */

const convertDefaultValueToNumber = (
  attributeDataType,
  attributeDefaultValue
) => {
  if (attributeDataType === 'Integer' || attributeDataType === 'Decimal') {
    if (
      !isValueEmpty(attributeDefaultValue) &&
      attributeDefaultValue?.trim() !== ''
    ) {
      const numberValue = toNumber(attributeDefaultValue);
      if (!Number.isNaN(numberValue)) {
        return numberValue;
      }
    }
    return '';
  }
  return attributeDefaultValue;
};

const getUniqueUsersList = (usersList) => {
  const uniqueUsers = {};

  return usersList?.filter((user) => {
    if (user?.is_grp) {
      return false;
    }
    if (!uniqueUsers[user?.user]) {
      uniqueUsers[user?.user] = true;
      return true;
    }
    return false;
  });
};

/**
 * @function extractMonth
 *@description  Extracts the month from a date string in UTC format.
 * @param {string} dateString - The date string in UTC format.
 * @returns {string} The full month name extracted from the date.
 */

const extractMonth = (dateString) => {
  const utcDate = moment.utc(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
  return utcDate.format('MMMM'); // or "MMM" for abbreviated month
};

/**
 * Checks if a given value is present in a string with comma-separated options of drop-down options.
 *
 * @param {string} value - The value to check.
 * @param {string} dropDownOptions - A string with comma-separated options of drop-down options.
 * @returns {boolean} `true` if the value is present in the options or if the value is undefined, `false` otherwise.
 *
 * @example
 * // Example usage:
 * const value = 'abc';
 * const options = 'abc,def,ghi';
 * const result = isValueInDropDownOptions(value, options); // Returns true
 *
 * // Note: The `dropDownOptions` string is split by commas to ensure accurate matching.
 * // This is done to handle cases where default values contain a trailing comma (e.g., 'abc,').
 */
const isValueInDropDownOptions = (value, dropDownOptions) => {
  return !(
    dropDownOptions &&
    value &&
    !dropDownOptions
      ?.split(',')
      .map((item) => item?.trim())
      .filter((item) => item?.length > 0)
      .includes(value?.trim())
  ); // Valid if value is undefined or in dropDownOptions
};

/**
 * Converts comma separated string to array, removes preceding and trailing spaces
 * @param {string} value - comma separated string to be converted to array
 * @returns {Array} Array of strings by removing all preceding and trailing spaces
 */
const toMultiselectArray = (value) => {
  if (!value) {
    return [];
  }
  return value?.split(',').map((item) => item.trim()) || [];
};

/**
 * @description Format the default value based on data type.
 * @param {object} values - Object containing data type and default value.
 * @returns {string} - Formatted default value.
 */
const formatDefaultValue = (values) => {
  if (values?.data_type === 'Date' && !isEmpty(values?.default_value)) {
    return moment(values?.default_value).format(DATE_FORMAT_YYYY_MM_DD);
  }
  if (values?.data_type === 'Character' && !isEmpty(values?.default_value)) {
    return values?.default_value?.trim();
  }
  return values?.default_value;
};

/**
 * @description Changes the table page index based on the current data, page size, using provided function to go to a page.
 * @param {number} pageIndex - The current page index.
 * @param {Array} data - The data displayed in the table.
 * @param {number} pageSize - The number of items displayed per page.
 * @param {Function} gotoPage - The function to navigate to a specific page.
 */
const changeTablePageIndex = (pageIndex, data, pageSize, gotoPage) => {
  // If we have items equal to page size on the page, we will not going to change the page
  if (data && data.length % pageSize === 0) {
    // Check if the current page index is less than or equal to 0.
    if (pageIndex - 1 <= 0) {
      // If so, go to the first page (index 0).
      gotoPage(0);
    } else {
      // Otherwise, go to the previous page (decrement pageIndex by 1).
      gotoPage(pageIndex - 1);
    }
  }
};

/**
 * Converts a file to Base64 format.
 * @param {File} file - The file to convert to Base64.
 * @returns {Promise<string>} A promise that resolves to the Base64 representation of the file.
 */
const convertFileToBase64 = (file, split = true) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = split ? reader.result.split(',')[1] : reader.result;
      resolve(base64Data);
    };
    reader.onerror = (error) => {
      reject(new Error(error));
    };
    reader.readAsDataURL(file);
  });
};

/**
 * @function renameDuplicates
 * @description Function to rename duplicate columns
 * @param {array}- name of columns
 */
const renameDuplicates = (columns) => {
  const columnCount = {};
  return columns?.map((col) => {
    if (columnCount[col]) {
      columnCount[col] += 1;
      return `${col}_${columnCount[col]}`;
    }
    columnCount[col] = 1;
    return col;
  });
};

/**
 * @function convertToNormalValue
 * @description Converts a given input to a normal value (either a parsed JSON object or the original input if not in JSON format).
 *
 * @param {string} input - The input to be converted to a normal value.
 * @returns {any} The converted value. It could be a JSON-parsed object or the original input.
 */
const convertToNormalValue = (input) => {
  try {
    return JSON.parse(input);
  } catch (error) {
    return input;
  }
};

/**
 * @function convertData
 * @description Parses a CSV file and returns the parsed data as an array of objects.
 * @param {File} file - The CSV file to parse.
 * @param {Object} maxROws - number of max rows conversion allowed.
 * @parm {Promise<Object[]>} - for rejecting promise errors
 * @returns{array} - An array of object.
 * @returns {Promise<Object[]>} A promise that resolves to the parsed data as an array of objects.
 */
const convertData = (csvData, maxRows, reject) => {
  const rows = csvData?.split('\n');
  const formattedHeaders = rows[0]?.split(',')?.map((col) => {
    return convertToNormalValue(col);
  });
  const headers = renameDuplicates(formattedHeaders);
  const jsonData = [];
  if (maxRows < rows?.length) {
    reject({ message: `Max ${maxRows} rows allowed in file.` });
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < rows?.length; i++) {
    const values = rows[i]?.split(',');
    if (values?.length === headers?.length) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < headers?.length; j++) {
        obj[headers[j]] = convertToNormalValue(values[j]);
      }
      jsonData.push(obj);
    }
  }
  if (isArray(jsonData)) {
    return jsonData;
  }
  return [];
};
/**
 * @function convertCsvToJson
 * @description Parses a CSV file and returns the parsed data as an array of objects.
 * @param {File} file - The CSV file to parse.
 * @param {Object} maxRws - number of max rows conversion allowed by default value is 100.
 * @returns {Promise<Object[]>} A promise that resolves to the parsed data as an array of objects.
 */
const convertCsvToJson = (file, maxRows = 100) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvData = e?.target?.result;
        const data = convertData(csvData, maxRows, reject);
        resolve(data);
      };

      reader.onerror = (error) => {
        reject(new Error(`FileReader error: ${error.message}`));
      };
      // Read the file as text.
      reader.readAsText(file, 'utf8');
    }
  });
};

/**
 * Finds orphan nodes in a graph.
 *
 * @param {Array} nodes - List of nodes with unique IDs.
 * @param {Array} edges - List of edges with source and target IDs.
 * @returns {Array} - Orphan nodes (nodes without connecting edges).
 */
const checkOrphanNodes = (nodes, edges) => {
  const nodeIdsWithEdges = new Set();

  edges.forEach((edge) => {
    nodeIdsWithEdges?.add(edge?.source);
    nodeIdsWithEdges?.add(edge?.target);
  });

  return nodes?.filter((node) => !nodeIdsWithEdges?.has(node?.id));
};

/**
 * Checks whether the nodes in a graph have incoming edges.
 * @param {Array} nodes - An array of node objects.
 * @param {Array} edges - An array of edge objects.
 * @param {string} startNodeId - The ID of the node marked as the start node.
 * @returns {boolean} Returns true if all nodes, except the start node, have incoming edges; false otherwise.
 */
const checkIncomingEdges = (nodes, edges, startNodeId) => {
  const targetNodes = new Set(edges?.map((edge) => edge?.target));
  // Check if nodes other than the start node have incoming edges
  const nodesWithIncomingEdges = nodes?.filter(
    (node) => node?.id !== startNodeId && targetNodes?.has(node.id)
  );

  return nodesWithIncomingEdges?.length === nodes.length - 1;
};

/**
 * Checks if there is a path from the start node to the end node in a graph.
 *
 * @param {Array} allNodes - An array of all nodes in the graph.
 * @param {Array} allEdges - An array of all edges in the graph.
 * @param {string} start - The ID of the starting node.
 * @param {string} end - The ID of the ending node.
 * @param {Set} [visitedNodes=new Set()] - A Set to keep track of visited nodes (optional, default is an empty Set).
 * @returns {boolean} - Returns true if a path is found, false otherwise.
 */
const checkPath = (
  allNodes,
  allEdges,
  start,
  end,
  visitedNodes = new Set()
) => {
  visitedNodes?.add(start);
  if (end?.includes(start)) {
    return true;
  }
  const outgoingEdges = allEdges?.filter((edge) => edge?.source === start);
  let pathFound = false;
  outgoingEdges?.forEach((edge) => {
    if (
      !visitedNodes.has(edge?.target) &&
      checkPath(allNodes, allEdges, edge?.target, end, visitedNodes)
    ) {
      pathFound = true;
    }
  });
  if (!pathFound && start === allNodes[0]?.id) {
    return false;
  }

  return pathFound;
};

/**
 * Checks for overlapping nodes in a collection and returns new coordinates
 * with a specified buffer if overlaps are found.
 *
 * @param {Array<Object>} allNodes - An array of node objects to check for overlaps.
 * @param {number} x - The x-coordinate to check for overlaps.
 * @param {number} y - The y-coordinate to check for overlaps.
 * @param {number} buffer - The buffer distance to use for checking overlaps.
 * @returns {Object} - An object containing new coordinates {x, y} adjusted for overlaps.
 */
const checkOverlappingNodes = (allNodes, x, y, buffer) => {
  const isBetween = (a, b, buffer1) => a > b - buffer1 && a < b + buffer1;

  /**
   * Checks if any nodes in an array overlap with specified coordinates (x1, y1).
   *
   * @param {Array<Object>} nodes - An array of node objects to check for overlaps.
   * @param {number} x1 - The x-coordinate to check for overlaps.
   * @param {number} y1 - The y-coordinate to check for overlaps.
   * @returns {boolean} - True if any node overlaps, false otherwise.
   */
  const overlap = (nodes, x1, y1) =>
    nodes.some(
      (node) =>
        isBetween(node?.position?.x, x1, buffer) &&
        isBetween(node?.position?.y, y1, buffer)
    );

  /**
   * Recursively adjusts coordinates (x1, y1) with a buffer to avoid overlaps.
   *
   * @param {Array<Object>} nodes1 - An array of node objects to check for overlaps.
   * @param {number} x1 - The x-coordinate to check for overlaps.
   * @param {number} y1 - The y-coordinate to check for overlaps.
   * @returns {Object} - An object containing new adjusted coordinates {x, y}.
   */
  const overlapChecks = (nodes1, x1, y1) => {
    if (nodes1 && overlap(nodes1, x1, y1)) {
      return overlapChecks(nodes1, x1 + buffer, y1 + buffer);
    }
    return { x: x1, y: y1 };
  };

  return overlapChecks(allNodes, x, y);
};

function isBase64(base64String) {
  try {
    const decodedData = atob(base64String);
    return ['\x89PNG', 'GIF', 'JFIF', 'WEBP', 'RIFF'].some((header) =>
      decodedData.startsWith(header)
    );
  } catch (error) {
    return false;
  }
}

function isArrayofObjects(arr) {
  if (!Array.isArray(arr)) {
    return false;
  }
  if (!arr.length) {
    return false;
  }
  return arr?.every(
    (item) =>
      typeof item === 'object' &&
      item !== null &&
      !('label' in item && 'value' in item)
  );
}

function getMonthStartEndDates(date) {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return {};
  }

  // Extract month and year from the input date
  const monthNumber = momentDate.month() + 1; // Add 1 to get the actual month number
  const year = momentDate.year();

  // Create a moment object for the first day and last day of the month
  const startDate = moment(`${year}-${monthNumber}-01`);
  const endDate = startDate.clone().endOf('month');

  return {
    startDate: startDate.format(DATE_FORMAT_YYYY_MM_DD),
    endDate: endDate.format(DATE_FORMAT_YYYY_MM_DD),
  };
}

/**
 * @function scrollToBottom
 * @description Scrolls the containerRef to the bottom with specified behavior.
 * @param {string} [behavior='instant'] - The scroll behavior ('auto', 'smooth', or 'instant').
 * @returns {void}
 */
const scrollToBottom = (containerRef, behavior = 'instant') => {
  if (containerRef.current) {
    containerRef.current.scroll({
      top: containerRef.current.scrollHeight,
      behavior,
    });
  }
};

/**
 * Calculates the date based on the given frequency.
 * @param {string} frequency - The frequency to calculate the date for.
 *   Possible values are 'Monthly', 'Quarterly', or 'Yearly'.
 * @param {Date} date - The reference date.
 * @returns {Date} The calculated date based on the given frequency.
 */
const calculateFrequencyDate = (frequency, date) => {
  let calculatedDate = date;
  switch (frequency) {
    case 'Monthly':
      calculatedDate = moment(date).add(1, 'M');
      break;
    case 'Quarterly':
      calculatedDate = moment(date).add(3, 'M');
      break;
    case 'Yearly':
      calculatedDate = moment(date).add(1, 'Y');
      break;
    default:
      break;
  }

  return calculatedDate;
};

/**
 * Extract user options from model team response.
 * @param {object[]} modelTeam array of model team members
 * @returns {string[]} All unique users in the model team flattened
 */
function extractUserOptionsFromModelTeam(modelTeam) {
  return uniq(
    flatten(
      modelTeam?.map((obj) => (obj?.is_grp ? obj?.group_member : obj?.user))
    )
  );
}

/**
 * Create dynamic template strings
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals#tagged_templates_and_escape_sequences}
 * @returns {string} formatted string
 * @example
 * const t2Closure = stringFromTemplate`${0} ${"foo"}!`;
 * // const t2Closure = stringFromTemplate([""," ","!"],0,"foo");
 * t2Closure("Hello", { foo: "World" }); // "Hello World!"
 */
function stringFromTemplate(strings, ...keys) {
  return (...values) => {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
}

const transformGridLayoutArray = (gridLayout) => {
  const len = gridLayout.length;
  let sum = 0;
  const ans = Array.from({ length: len }, () => 0);
  let pointer = 0;
  let i;

  for (i = 0; i < len; i += 1) {
    sum += gridLayout[i];

    if (sum === 12) {
      let j;
      for (j = pointer; j <= i; j += 1) {
        ans[j] = gridLayout[j];
      }
      pointer = j;
      sum = 0;
    }

    if (sum > 12) {
      const element = 12 / (i - pointer);
      let j;
      for (j = pointer; j < i; j += 1) {
        ans[j] = Math.floor(element);
      }
      pointer = j;
      sum = 0;
      i -= 1; // NOSONAR
    }
  }

  if (pointer < len) {
    const element = 12 / (i - pointer);
    let j;
    for (j = pointer; j < i; j += 1) {
      ans[j] = Math.floor(element);
    }
  }

  return ans;
};

/**
 * Checks if message content is JSON or string and returns an EditorState accordingly.
 * @param {string} value
 * @returns {EditorState} EditorState for TextEditor component
 */
const getEditorStateFromStringOrJSON = (value) => {
  if (isValueEmpty(value)) {
    return EditorState.createEmpty();
  }
  if (isJsonString(value)) {
    return EditorState.push(
      EditorState.createEmpty(),
      convertFromRaw(JSON.parse(value)),
      'remove-range'
    );
  }
  return EditorState.createWithContent(ContentState.createFromText(value));
};

const hostNeedsOrg = (reqUrl) => {
  return API_ENDPOINTS.HOSTS_NEEDS_TOKEN.includes(
    new URL(reqUrl).host.split(':')[0]
  );
};
const initKeycloak = async () => {
  const initOptions = {
    url: window.env.REACT_APP_KEYCLOACK_URL,
    realm: localStorage.getItem('REALM_ID'),
    clientId: 'Vault',
    onLoad: 'check-sso', // check-sso | login-required
    KeycloakResponseType: 'code',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  };

  const keycloakConfig = new Keycloak(initOptions);
  window.KEYCLOAK_CONFIG = keycloakConfig;
  return keycloakConfig
    .init({
      onLoad: 'login-required',
      KeycloakResponseType: 'code',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      checkLoginIframe: false,
      pkceMethod: 'S256',
    })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        localStorage.setItem('auth', auth);
        document.dispatchEvent(
          new CustomEvent('KEYCLOAK_LOGIN_SUCCESS', {
            detail: { auth, keycloakConfig },
          })
        );
      }
      return auth;
    })
    .catch((e) => {
      SwalToast({
        icon: 'error',
        title: e.error,
      });
    });
};

const getErrorHelperText = (error, touched) => {
  return touched ? error : null;
};

/**
 * Get tags for current route. Returns all the tags if no pathname provided
 * @param {string | undefined} pathname
 * @returns {string[]} List of matching tags
 */
export const getTagsFromRoute = (pathname) => {
  const tags = new Set();
  Object.keys(PATH_TAGS).forEach((pathPattern) => {
    if (!pathname) {
      tags.add(...PATH_TAGS[pathPattern]);
      return;
    }
    const match = matchPath(pathPattern, pathname);
    if (match) {
      tags.add(...PATH_TAGS[pathPattern]);
    }
  });
  return Array.from(tags);
};

/**
 * Converts a condition object to a Python expression string.
 *
 * @param {Object} condition - The condition object to convert.
 * @param {Array} condition.rules - The rules within the condition.
 * @returns {string} - The Python expression string.
 */
const convertConditionToPythonExpression = (condition) => {
  /**
   * Processes a field object to a string representation.
   *
   * @param {Object} field - The field object to process.
   * @param {Object} field.fieldType - The type of the field.
   * @param {Object} field.section - The section of the field.
   * @param {Object} field.fieldValue - The value of the field.
   * @returns {string} - The string representation of the field.
   */
  const processField = (field) => {
    const { fieldType, section, fieldValue } = field;
    const sectionPart = section ? `${section?.value}.` : '';
    return `{{${fieldType?.value}.${sectionPart}${fieldValue?.value}}}`;
  };

  /**
   * Processes a rule object to its value representation.
   *
   * @param {Object} rule - The rule object to process.
   * @param {Object} rule.field - The field within the rule.
   * @param {string} rule.value - The value of the rule.
   * @returns {string|number|Array} - The processed value.
   */
  const processValue = (rule) => {
    const dataType = rule?.field?.fieldValue?.data_type;
    const inputControlType = rule?.field?.fieldValue?.input_control_type;

    if (dataType === 'Character') {
      if (
        inputControlType === 'TEXTFIELD' ||
        inputControlType === 'SINGLE_SELECT'
      ) {
        return `"${rule?.value}"`;
      }
      if (inputControlType === 'MULTI_SELECT') {
        return Array.isArray(rule?.value)
          ? `[${rule?.value.map((option) => option?.value)}]`
          : `[]`;
      }
    }

    if (dataType === 'Integer' || dataType === 'Decimal') {
      return Number(rule?.value);
    }

    if (dataType === 'Date') {
      return `"${rule?.value}"`;
    }

    return rule?.value;
  };

  /**
   * Processes a complex rule object to a string representation.
   *
   * @param {Object|string} rule - The rule object or string to process.
   * @returns {string} - The string representation of the complex rule.
   */
  const processComplexRule = (rule) => {
    if (typeof rule === 'string') {
      return rule;
    }

    if (rule?.field === 'userInputKey') {
      return rule?.value;
    }

    if (rule?.field === 'ifConditionKey') {
      const { ifCondition, ifBody, elseBody } = rule?.value || {};
      const ifConditionPart = ifCondition?.rules
        ? // eslint-disable-next-line no-use-before-define
          `if ${processNestedRules(ifCondition?.rules)}`
        : '';
      // eslint-disable-next-line no-use-before-define
      const ifBodyPart = ifBody?.rules ? processNestedRules(ifBody?.rules) : '';
      const elseBodyPart = elseBody?.rules
        ? // eslint-disable-next-line no-use-before-define
          `else ${processNestedRules(elseBody?.rules)}`
        : '';
      return `(${ifBodyPart} ${ifConditionPart} ${elseBodyPart})`.trim();
    }

    return `${processField(rule?.field)} ${rule?.operator} ${processValue(
      rule
    )}`;
  };

  /**
   * Processes nested rules to a string representation.
   *
   * @param {Array} nestedRules - The nested rules to process.
   * @returns {string} - The string representation of the nested rules.
   */
  const processNestedRules = (nestedRules) => {
    const result = nestedRules?.map((rule) => {
      if (rule?.rules) {
        return `(${processNestedRules(rule?.rules)})`; // Ensures grouping at all levels
      }
      return processComplexRule(rule);
    });

    // Wrap the entire group in parentheses if it contains more than one rule
    return result.length > 1 ? `(${result.join(' ')})` : result.join(' ');
  };

  /**
   * Processes the main condition object to a string representation.
   *
   * @param {Object} _condition - The main condition object to process.
   * @returns {string} - The string representation of the condition.
   */
  const processCondition = (_condition) => {
    return _condition?.rules
      .map((rule) => {
        if (rule?.rules) {
          return processNestedRules(rule?.rules);
        }
        return processComplexRule(rule);
      })
      .join(' ');
  };

  return isValueEmpty(condition?.rules) ? '' : processCondition(condition);
};

/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text - The text to be copied.
 * @return {Promise<void>} - A promise that resolves when the text is successfully copied.
 */
const copyToClipboard = async (text) => {
  try {
    await window.navigator.clipboard.writeText(text);
  } catch (err) {
    // Do nothing
  }
};

const updateYupSchemaValidation = (yupSchema, isRemoveRequired = false) => {
  // Check if schema exists and is an array, otherwise default to an empty array
  const schema = JSON.parse(yupSchema || { schema: [{}] })?.schema || [{}];

  if (isRemoveRequired) {
    let updatedSchema = schema;
    const isRequiredPresent = updatedSchema?.some(
      (validation) => validation?.type === 'required'
    );
    if (isRequiredPresent) {
      updatedSchema = updatedSchema?.filter(
        (validation) => validation?.type !== 'required'
      );
    }
    if (updatedSchema?.length === 0) {
      updatedSchema?.push({});
    }
    return {
      schema: updatedSchema,
    };
  }

  // to remove empty object form array
  const filteredSchema = schema?.filter(
    (validation) => Object.keys(validation || {})?.length > 0
  );

  // Check if the 'required' validation type exists in the schema array
  const isRequiredPresent = filteredSchema?.some(
    (validation) => validation?.type === 'required'
  );

  // If 'required' is not present, add it to the schema
  if (!isRequiredPresent) {
    filteredSchema?.push({
      type: 'required',
      params: ['This field is required'], // Message for required field
    });
  }

  // Return the updated schema with 'required' validation if necessary
  return {
    schema: filteredSchema,
  };
};
export {
  createYupSchema,
  isValueEmpty,
  createDynamicColumns,
  blockInvalidNumbers,
  isJsonString,
  convertDefaultValueToNumber,
  createDynamicFilters,
  getUniqueUsersList,
  isDateValid,
  extractMonth,
  isValueInDropDownOptions,
  toMultiselectArray,
  formatDefaultValue,
  changeTablePageIndex,
  convertFileToBase64,
  convertCsvToJson,
  isBase64,
  isArrayofObjects,
  checkPath,
  checkOverlappingNodes,
  checkOrphanNodes,
  checkIncomingEdges,
  getMonthStartEndDates,
  scrollToBottom,
  calculateFrequencyDate,
  roundToDecimals,
  extractUserOptionsFromModelTeam,
  stringFromTemplate,
  transformGridLayoutArray,
  getEditorStateFromStringOrJSON,
  hostNeedsOrg,
  initKeycloak,
  getErrorHelperText,
  convertConditionToPythonExpression,
  copyToClipboard,
  updateYupSchemaValidation,
};
