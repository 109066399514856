// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { cloneDeep } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';

import REGEX from '../../const/Regex';
import { INVALID_REGEX_MESSAGE } from '../../const/CommonConst';
import DialogTransition from '../Common/Transition/DialogTransition';
import StepButton from '../UserManagement/StepButton';
import GroupsPermissionStep from '../Configuration/AdministrativeOptions/GroupsPermissionStep';
import MODULE_SUB_MODULE, {
  DYNAMIC_MODULE_SUBMODULE,
} from '../../const/moduleSubmodule';
import {
  addPermissionKey,
  extractPermissions,
  iterateNestedObject,
  removeModules,
  transformPermissionJsonObject,
  updateModulePermissions,
} from '../Configuration/AdministrativeOptions/groupPermissionUtils';
import swalToast from '../Common/swalToast';
import { copyToClipboard } from '../../utils/Utils';

const TITLE_MAPPINGS = {
  create: 'Register App',
  edit: 'Manage Permissions',
};

const getDisplayPropsForStep = (step, currentStep) => ({
  display: step === currentStep ? 'flex' : 'none',
});

const STEP_TITLES_CREATE_MODE = {
  first: 'Create App',
  second: 'Manage Permissions',
  third: 'Client Secret Key',
};

const RegisterAppModal = ({
  open,
  onClose,
  mode,
  selectedApp,
  registerApp,
  updateApp,
  handleGetAllApps,
}) => {
  const [step, setStep] = useState('first');
  const [moduleSubModules, setModuleSubModules] = useState({});
  const [appDetails, setAppDetails] = useState({});

  const handleCloseModal = useCallback(() => {
    onClose();
    setStep('first');
    setAppDetails({});
  }, [onClose]);

  const formValidationSchema = object({
    name: string()
      .max(70, 'max characters should be 70')
      .required('Required')
      .matches(REGEX.SPECIAL_CHARACTERS_NOT_ALLOWED, INVALID_REGEX_MESSAGE)
      .matches(REGEX.CANNOT_START_WITH_NUMBERS, INVALID_REGEX_MESSAGE),
  });

  const appRegistartionFormikForm = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const permissionJson = extractPermissions(moduleSubModules);

      const body = {
        scope: permissionJson,
        name: values?.name?.trim(),
      };

      if (mode === 'create') {
        // eslint-disable-next-line no-use-before-define
        handleRegisterApp(body);
      }
      if (mode === 'edit') {
        body.client_id = selectedApp?.client_id;
        // eslint-disable-next-line no-use-before-define
        handleUpdateApp(body);
      }
    },
  });

  const handleRegisterApp = useCallback(
    (body) => {
      registerApp(body).then((res) => {
        if (res?.data) {
          swalToast({
            icon: 'success',
            title: 'App registered successfully.',
          });
          appRegistartionFormikForm?.handleReset();
          setStep('third');
          setAppDetails(res?.data?.Data);
          handleGetAllApps();
        }
      });
    },
    [
      appRegistartionFormikForm,
      registerApp,
      handleGetAllApps,
      setStep,
      swalToast,
      setAppDetails,
    ]
  );

  const handleUpdateApp = useCallback(
    (body) => {
      updateApp(body).then((res) => {
        if (res?.data) {
          swalToast({
            icon: 'success',
            title: 'App updated successfully.',
          });
          appRegistartionFormikForm?.handleReset();
          handleCloseModal();
          setStep('first');
          setAppDetails(res?.data?.Data);
          handleGetAllApps();
        }
      });
    },
    [
      appRegistartionFormikForm,
      updateApp,
      handleGetAllApps,
      swalToast,
      handleCloseModal,
      setStep,
      setAppDetails,
    ]
  );

  /**
   * Handles the click event for a specific action.
   * Validates the form and updates the step or submits the form accordingly.
   * @function
   * @name handleClick
   * @returns {void}
   */
  const handleClick = useCallback(() => {
    appRegistartionFormikForm.validateForm().then((validate) => {
      if (
        appRegistartionFormikForm?.values?.name.length <= 150 &&
        Object.keys(validate).length === 0
      ) {
        setStep('second');
      } else {
        appRegistartionFormikForm?.handleSubmit();
      }
    });
  }, [
    appRegistartionFormikForm?.validateForm,
    appRegistartionFormikForm?.handleSubmit,
    appRegistartionFormikForm?.handleSubmit,
    setStep,
  ]);

  /**
   * Copies the selected smart value to the clipboard.
   * @name handleCopyText
   * @function
   * @returns {void}
   * @example handleCopyText()
   */
  const handleCopyText = useCallback(() => {
    copyToClipboard(appDetails?.client_secret);
    swalToast({
      icon: 'success',
      title: 'Client secret key copied successfully.',
    });
  }, [copyToClipboard, appDetails]);

  let transformedPermissionsObject = {};

  useEffect(() => {
    switch (mode) {
      case 'create': {
        let initialDefaultPermissionsModules;
        if (window.env.REACT_APP_KEYCLOAK_LOGIN) {
          initialDefaultPermissionsModules = addPermissionKey(
            cloneDeep(DYNAMIC_MODULE_SUBMODULE)
          );
        } else {
          initialDefaultPermissionsModules = addPermissionKey(
            cloneDeep(removeModules(MODULE_SUB_MODULE, ['AR', 'MA']))
          );
        }

        setModuleSubModules(cloneDeep(initialDefaultPermissionsModules));
        break;
      }
      case 'edit': {
        let initialDefaultPermissionsModulesEdit;
        if (window.env.REACT_APP_KEYCLOAK_LOGIN) {
          initialDefaultPermissionsModulesEdit = addPermissionKey(
            cloneDeep(DYNAMIC_MODULE_SUBMODULE)
          );
        } else {
          initialDefaultPermissionsModulesEdit = addPermissionKey(
            cloneDeep(removeModules(MODULE_SUB_MODULE, ['AR', 'MA']))
          );
        }
        const updatedModuleSubModuleEdit = initialDefaultPermissionsModulesEdit;

        if (selectedApp) {
          appRegistartionFormikForm?.setValues({
            name: selectedApp?.name,
          });

          if (selectedApp?.scope) {
            // Transforming the permission object received from backend.
            transformedPermissionsObject = transformPermissionJsonObject(
              selectedApp?.scope
            );

            // Iterate through every nested module and subModules and updating the permission according to backend permission json
            iterateNestedObject(
              updatedModuleSubModuleEdit,
              transformedPermissionsObject
            );

            let result = {};
            // Iterate through every nested module and subModules from bottom to top and updating the all modules permissions according to newly added objects (i.e templates or modules)
            result = updateModulePermissions(
              cloneDeep(updatedModuleSubModuleEdit)
            );

            setModuleSubModules({ ...cloneDeep(result) });
          }
        }
        break;
      }
      default:
        break;
    }
  }, [mode, selectedApp]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onClose={handleCloseModal}
      TransitionComponent={DialogTransition}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          'padding-bottom': '42px',
        },
      }}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h3">
              {step === 'first'
                ? TITLE_MAPPINGS[mode]
                : `${STEP_TITLES_CREATE_MODE[step]}`}
            </Typography>
          </Box>
        </Grid>
        {/* first step */}
        <Grid
          container
          item
          rowSpacing={5}
          xs={12}
          {...getDisplayPropsForStep('first', step)}
        >
          <Grid
            item
            container
            xs={12}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                name="name"
                value={appRegistartionFormikForm?.values?.name}
                {...appRegistartionFormikForm?.getFieldProps('name')}
                placeholder="Enter app name."
                label="App name"
                disabled={mode === 'edit'}
                helperText={
                  appRegistartionFormikForm?.errors?.name &&
                  appRegistartionFormikForm?.touched?.name
                    ? appRegistartionFormikForm?.errors?.name
                    : null
                }
                error={
                  Boolean(appRegistartionFormikForm?.errors?.name) &&
                  appRegistartionFormikForm?.touched?.name
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
                <Button onClick={appRegistartionFormikForm?.handleReset}>
                  RESET
                </Button>
                <Button onClick={handleClick} variant="contained">
                  NEXT
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {/* Second Step */}
        <Grid container item {...getDisplayPropsForStep('second', step)}>
          <Grid xs={12} item textAlign="center">
            <Box mb={2}>
              <Typography textAlign="center">
                Please select permissions.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <GroupsPermissionStep
              moduleSubModules={moduleSubModules}
              setModuleSubModules={setModuleSubModules}
            />
          </Grid>
          <Stack
            width="100%"
            direction="row"
            spacing={3}
            justifyContent="center"
            mt={3}
          >
            <StepButton label="BACK" step="first" setStep={setStep} />
            <Button
              color="primary"
              variant="contained"
              onClick={appRegistartionFormikForm?.handleSubmit}
            >
              {mode === 'create' ? 'CREATE APP' : 'UPDATE APP'}
            </Button>
          </Stack>
        </Grid>
        {/* Third Step */}
        <Grid
          container
          item
          {...getDisplayPropsForStep('third', step)}
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <InfoIcon color="error" />
              <Typography variant="body4">
                {` Important: Make sure to copy and securely store this key now.
                You won't be able to access it again later.`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
            <Typography
              component="div"
              variant="body6"
              sx={{ wordBreak: 'break-word' }}
            >
              {appDetails?.client_secret}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            mt={2}
            justifyContent="center"
          >
            <Grid item>
              <Button onClick={handleCloseModal} variant="outlined">
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleCopyText} variant="contained">
                Copy
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

RegisterAppModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  selectedApp: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool])
    .isRequired,
  registerApp: PropTypes.func.isRequired,
  updateApp: PropTypes.func.isRequired,
  handleGetAllApps: PropTypes.func.isRequired,
};

export default RegisterAppModal;
