// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { array, string, object, ref } from 'yup';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Multiselect from '../Common/Multiselect';
import SwalToast from '../Common/swalToast';
import DialogTransition from '../Common/Transition/DialogTransition';
import REGEX from '../../const/Regex';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';
import { INVALID_REGEX_MESSAGE } from '../../const/CommonConst';
import { getErrorHelperText } from '../../utils/Utils';

const getOptionLabel = (option) => option?.label;
const isOptionEqualToValue = (option, val) => option?.label === val?.label;

/**
 * Check if password is similar to rest of the fields
 * @param {string} password
 * @param  {...string} fields
 */
const isPasswordSimilarTo = (password, ...fields) =>
  fields?.some((field) => password === field);

const formValidationSchema = object({
  mode: string().required().default('create'),
  username: string().when('mode', {
    is: 'create',
    then: (schema) =>
      schema
        .max(320, 'maximum 320 characters allowed for username')
        .matches(REGEX.USERNAME, INVALID_REGEX_MESSAGE)
        .matches(REGEX.NOT_ONLY_NUMBER, INVALID_REGEX_MESSAGE)
        .required('Required'),
    otherwise: (schema) => schema.required('Required'),
  }),
  first_name: string()
    .max(30, 'maximum 30 characters allowed for firstname')
    .matches(REGEX.NOT_ONLY_NUMBER, INVALID_REGEX_MESSAGE)
    .required('Required'),
  last_name: string()
    .max(30, 'maximum 30 characters allowed for lastname')
    .matches(REGEX.NOT_ONLY_NUMBER, INVALID_REGEX_MESSAGE)
    .test(
      'unique-last-name',
      'First name and last name should not be same',
      (value, context) => value !== context?.parent?.first_name
    )
    .required('Required'),
  email: string()
    .email()
    .max(254, 'maximum 254 characters allowed for email')
    .required('Required'),

  groupList: array()
    .min(1, 'Required')
    .of(
      object().shape({
        label: string().required('require'),
      })
    )
    .required('Required'),
  password: string().when('mode', {
    is: 'create',
    then: (schema) =>
      schema
        .required('Required')
        .test(
          'password-match',
          'Password is too similar to personal information',
          (value, context) => {
            // eslint-disable-next-line camelcase
            const { username, first_name, last_name, email } = context.parent;
            return !isPasswordSimilarTo(
              value,
              username,
              first_name,
              last_name,
              email
            );
          }
        ),
    otherwise: (schema) => schema.optional(),
  }),
  confirmPassword: string().when('mode', {
    is: 'create',
    then: (schema) =>
      schema
        .required('Required')
        .oneOf([ref('password'), null], 'Passwords must match'),
    otherwise: (schema) => schema.optional(),
  }),
});

const title = {
  create: 'Create User',
  edit: 'Edit User',
};

const CreateUser = ({
  open,
  handleClose,
  createUser,
  getUserDetails,
  groupList,
  mode = 'create',
  currentUser,
  updateUser,
  setSkipPageReset,
}) => {
  const [hidden, setHidden] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);

  const formikForm = useFormik({
    initialValues: {
      mode,
      username: currentUser?.username || '',
      first_name: '',
      last_name: '',
      email: '',
      groupList: [],
      ...(mode === 'create' ? { password: '', confirmPassword: '' } : {}),
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      if (mode === 'create') {
        const { confirmPassword, ...body } = values;
        const updatedBody = {
          ...body,
          username: values?.username?.trim(),
          groupList: values?.groupList?.map((item) => item?.value),
        };
        createUser(updatedBody).then((res) => {
          if (res) {
            SwalToast({
              icon: 'success',
              title: res?.data?.msg,
            });
          }
          getUserDetails();
          handleClose(res?.data?.entity_name);
        });
        formikForm?.handleReset();
      } else {
        const { username, password, confirmPassword, ...body } = values;
        const updatedBody = {
          ...body,
          groupList: values?.groupList?.map((item) => item?.value),
          userid: currentUser?.user_id,
          username: username?.trim(),
        };
        updateUser(updatedBody).then((res) => {
          if (res) {
            SwalToast({
              icon: 'success',
              title: 'Profile updated successfully.',
            });
          }
          getUserDetails();
          handleClose(res?.data?.entity_name);
          // turn on the flag to not reset the page
          setSkipPageReset(true);
        });
        formikForm?.handleReset();
      }
    },
  });

  const setUserValues = async () => {
    if (mode === 'edit' && currentUser) {
      await formikForm?.setValues({
        username: currentUser?.username,
        first_name: currentUser?.firstName,
        last_name: currentUser?.lastName,
        email: currentUser?.email,
        groupList: currentUser?.groups?.map((group) => ({
          label: group?.groupName,
          value: group?.groupId,
        })),
        mode,
      });
    }
  };
  useEffect(() => {
    setUserValues();
  }, [mode, currentUser]);

  /**
   * Closes the dialog and resets form.
   * @function
   * @name handleCloseAndResetForm
   * @returns {void}
   */
  const handleCloseAndResetForm = useCallback(() => {
    formikForm?.handleReset();
    handleClose();
  }, [formikForm?.handleReset, handleClose]);

  const togglePasswordVisibility = useCallback(() =>
    setHidden((prev) => !prev)
  );
  const toggleConfirmPasswordVisibility = useCallback(() =>
    setHiddenConfirmPassword((prev) => !prev)
  );

  const renderGroupListInput = useCallback(
    (params) => (
      <TextField
        required
        {...params}
        label="Groups"
        placeholder={
          formikForm?.values?.groupList?.length ? '' : 'Select groups.'
        }
        helperText={getErrorHelperText(
          formikForm?.errors?.groupList,
          formikForm?.touched?.groupList
        )}
        error={Boolean(
          getErrorHelperText(
            formikForm?.errors?.groupList,
            formikForm?.touched?.groupList
          )
        )}
      />
    ),
    [
      formikForm?.values?.groupList,
      formikForm?.touched?.groupList,
      formikForm?.errors?.groupList,
    ]
  );
  const handleGroupListChange = useCallback(
    (value) => {
      formikForm?.setFieldValue('groupList', value);
    },
    [formikForm?.setFieldValue]
  );

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleCloseAndResetForm}
        TransitionComponent={DialogTransition}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={handleCloseAndResetForm}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h3">{title[mode]}</Typography>
            </Box>
          </Grid>

          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            padding={2}
          >
            <Grid item xs={6}>
              <TextField
                required
                name="username"
                value={formikForm?.values?.username}
                {...formikForm.getFieldProps('username')}
                helperText={getErrorHelperText(
                  formikForm?.errors?.username,
                  formikForm?.touched?.username
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.username,
                    formikForm?.touched?.username
                  )
                )}
                placeholder="Enter user name."
                label="Username"
                disabled={mode === 'edit'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="email"
                value={formikForm?.values?.email}
                {...formikForm.getFieldProps('email')}
                helperText={getErrorHelperText(
                  formikForm?.errors?.email,
                  formikForm?.touched?.email
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.email,
                    formikForm?.touched?.email
                  )
                )}
                placeholder="Enter email."
                label="Email"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="first_name"
                value={formikForm?.values?.first_name}
                {...formikForm.getFieldProps('first_name')}
                helperText={getErrorHelperText(
                  formikForm?.errors?.first_name,
                  formikForm?.touched?.first_name
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.first_name,
                    formikForm?.touched?.first_name
                  )
                )}
                placeholder="Enter first name."
                label="First name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="last_name"
                value={formikForm?.values?.last_name}
                {...formikForm.getFieldProps('last_name')}
                helperText={getErrorHelperText(
                  formikForm?.errors?.last_name,
                  formikForm?.touched?.last_name
                )}
                error={Boolean(
                  getErrorHelperText(
                    formikForm?.errors?.last_name,
                    formikForm?.touched?.last_name
                  )
                )}
                placeholder="Enter last name."
                label="Last name"
              />
            </Grid>
            {mode === 'create' ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    required
                    name="password"
                    label="Password"
                    type={!hidden ? 'text' : 'password'}
                    value={formikForm?.values?.password}
                    {...formikForm.getFieldProps('password')}
                    helperText={getErrorHelperText(
                      formikForm?.errors?.password,
                      formikForm?.touched?.password
                    )}
                    error={Boolean(
                      getErrorHelperText(
                        formikForm?.errors?.password,
                        formikForm?.touched?.password
                      )
                    )}
                    placeholder="Enter password."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                            size="large"
                          >
                            {!hidden ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    name="confirmPassword"
                    label="Confirm Password"
                    type={!hiddenConfirmPassword ? 'text' : 'password'}
                    value={formikForm?.values?.confirmPassword}
                    {...formikForm.getFieldProps('confirmPassword')}
                    helperText={getErrorHelperText(
                      formikForm?.errors?.confirmPassword,
                      formikForm?.touched?.confirmPassword
                    )}
                    error={Boolean(
                      getErrorHelperText(
                        formikForm?.errors?.confirmPassword,
                        formikForm?.touched?.confirmPassword
                      )
                    )}
                    placeholder="Confirm Password."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="primary"
                            aria-label="toggle confirmPassword visibility"
                            onClick={toggleConfirmPasswordVisibility}
                            edge="end"
                            size="large"
                          >
                            {!hiddenConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <Multiselect
                name="userList"
                options={
                  groupList?.length > 0
                    ? groupList?.map((group) => {
                        return { label: group?.groupName, value: group?.id };
                      })
                    : []
                }
                value={
                  isEmpty(formikForm?.values?.groupList)
                    ? []
                    : formikForm?.values?.groupList
                }
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                noOptionsMessage="No group found."
                popupIcon={<DropdownArrow />}
                renderInput={renderGroupListInput}
                onChange={handleGroupListChange}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Stack direction="column" spacing={3} mt={1} width="300px">
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                  mt={3}
                >
                  <Button onClick={formikForm?.handleReset}>RESET</Button>
                  <Button
                    onClick={formikForm?.handleSubmit}
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};
CreateUser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  groupList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  mode: PropTypes.string.isRequired,
  currentUser: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateUser: PropTypes.func.isRequired,
  setSkipPageReset: PropTypes.func.isRequired,
};

export default CreateUser;
