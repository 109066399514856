const SidebarConfig = [
  {
    name: 'Models',
    key: 'ModelInventory',
    path: 'my-model',
    permissions: [
      'ModelInventory_dashboard:View',
      'ModelInventory_bulkUpload:View',
      'ModelInventory_modelInventory:View',
      'ModelInventory_modelRequest:View',

      'ModelInventory_dashboard:Manage',
      'ModelInventory_bulkUpload:Manage',
      'ModelInventory_modelInventory:Manage',
      'ModelInventory_modelRequest:Manage',
    ],
    children: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        route: '/dashboard?value=0',
        permissions: [
          'ModelInventory_dashboard:View',
          'ModelInventory_dashboard:Manage',
        ],
      },
      {
        name: 'My Models',
        route: '/my-model',
        key: 'modelInventory',
        permissions: [
          'ModelInventory_modelInventory:View',
          'ModelInventory_modelInventory:Manage',
        ],
      },
      {
        name: 'Assessment',
        route: '/assessment',
        key: 'modelRequest',
        permissions: [
          'ModelInventory_modelRequest:View',
          'ModelInventory_modelRequest:Manage',
        ],
      },
      // {
      //   name: 'Create Model Inventory',
      //   key: 'createModelInventory',
      //   route: '/model-inventory/create-model-Inventory',
      // },
      // {
      //   name: 'Import From Excel',
      //   key: 'importFromExcel',
      //   route: '/model-inventory/import-from-excel',
      // },
    ],
  },
  {
    name: 'Model Artifacts',
    path: 'model-artifact',
    key: 'ModelAssociation',
    permissions: ['ModelAssociation:View', 'ModelAssociation:Manage'],
    children: [
      // {
      //   name: 'Model Association',
      //   route: '/model-association',
      //   key: 'modelAssociation',
      // },
      // {
      //   name: 'Model Validation',
      //   key: 'modelValidation',
      //   route: '/model-validation',
      // },
    ],
  },
  {
    name: 'Documents',
    key: 'DocumentInventory',
    permissions: [
      'DocumentInventory:View',
      'DocumentInventory_documentInventory:View',

      'DocumentInventory:Manage',
      'DocumentInventory_documentInventory:Manage',
    ],
    children: [
      {
        name: 'Documents',
        key: 'DocumentInventory',
        route: '/document-inventory',
        permissions: [
          'DocumentInventory_documentInventory:View',
          'DocumentInventory_documentInventory:Manage',
        ],
      },
    ],
  },
  {
    name: 'Rules & Reports',
    key: 'RulesReports',
    permissions: [
      'RulesReports:View',
      'RulesReports_rules:View',
      'RulesReports_report:View',

      'RulesReports:Manage',
      'RulesReports_rules:Manage',
      'RulesReports_report:Manage',
    ],
    children: [
      {
        name: 'Rules',
        key: 'rules',
        route: '/rules',
        permissions: ['RulesReports_rules:View', 'RulesReports_rules:Manage'],
      },
      {
        name: 'Reports',
        key: 'report',
        route: '/report',
        permissions: ['RulesReports_report:View', 'RulesReports_report:Manage'],
      },
    ],
  },
  {
    name: 'Bulk Actions',
    key: 'BulkActions',
    permissions: [
      'BulkActions:View',
      'BulkActions_bulkUpload:View',
      'BulkActions_bulkTransition:View',

      'BulkActions:Manage',
      'BulkActions_bulkUpload:Manage',
      'BulkActions_bulkTransition:Manage',
    ],
    children: [
      {
        name: 'Bulk Upload',
        key: 'bulkUpload',
        route: '/bulk-upload',
        permissions: [
          'BulkActions_bulkUpload:View',
          'BulkActions_bulkUpload:Manage',
        ],
      },
      {
        name: 'Bulk Transition',
        key: 'bulkTransition',
        route: '/bulk-transition',
        permissions: [
          'BulkActions_bulkTransition:View',
          'BulkActions_bulkTransition:Manage',
        ],
      },
    ],
  },
  {
    name: 'Configuration',
    key: 'Configuration',
    path: 'configuration',
    permissions: [
      'Configuration:View',
      'Configuration_workflow:View',
      'Configuration_templateList:View',
      'Configuration_globalAttributes:View',
      'Configuration_documentTemplate:View',
      'Configuration_dynamicChart:View',

      'Configuration:Manage',
      'Configuration_workflow:Manage',
      'Configuration_templateList:Manage',
      'Configuration_globalAttributes:Manage',
      'Configuration_documentTemplate:Manage',
      'Configuration_dynamicChart:Manage',
    ],
    children: [
      // {
      //   name: 'Configuration',
      //   route: '/configuration',
      //   key: 'configuration',
      // },
      {
        name: 'Attributes',
        key: 'globalAttributes',
        route: '/global-attributes',
        permissions: [
          'Configuration_globalAttributes:View',
          'Configuration_globalAttributes:Manage',
        ],
      },
      {
        name: 'Workflows',
        key: 'workflow',
        route: '/workflows',
        permissions: [
          'Configuration_workflow:Manage',
          'Configuration_workflow:View',
        ],
      },
      {
        name: 'Templates',
        key: 'templateList',
        route: '/templates',
        permissions: [
          'Configuration_templateList:View',
          'Configuration_templateList:Manage',
        ],
      },
      {
        name: 'Document Template',
        key: 'documentTemplate',
        route: '/document-template',
        permissions: [
          'Configuration_documentTemplate:View',
          'Configuration_documentTemplate:Manage',
        ],
      },
      {
        name: 'Dynamic Chart',
        key: 'dynamicChart',
        route: '/dynamic-chart',
        permissions: [
          'Configuration_dynamicChart:View',
          'Configuration_dynamicChart:Manage',
        ],
      },
      {
        name: 'Automation',
        key: 'automation',
        route: '/automation',
        permissions: [
          'Configuration_automation:View',
          'Configuration_automation:Manage',
        ],
      },
      // {
      //   name: 'Help and Support',
      //   key: 'helpAndSupport',
      //   route: '/help',
      //   permissions: [],
      //   target: '_blank',
      // },
    ],
  },
  {
    name: 'Administrative Options',
    key: 'AdministrativeOptions',
    permissions: [
      'AdministrativeOptions:View',
      'AdministrativeOptions_userManagement:View',
      'AdministrativeOptions_appManagement:View',

      'AdministrativeOptions:Manage',
      'AdministrativeOptions_userManagement:Manage',
      'AdministrativeOptions_appManagement:Manage',
    ],
    children: [
      {
        name: 'User Management',
        key: 'userManagement',
        route: '/user-management',
        permissions: [
          'AdministrativeOptions_userManagement:View',
          'AdministrativeOptions_userManagement:Manage',
        ],
      },
      {
        name: 'App Management',
        key: 'appManagement',
        route: '/app-management',
        permissions: [
          'AdministrativeOptions_appManagement:View',
          'AdministrativeOptions_appManagement:Manage',
        ],
      },
    ],
  },
  {
    name: 'Access Request',
    key: 'accessRequest',
    route: '/accessRequest',
    children: [],
  },
];

export default SidebarConfig;
