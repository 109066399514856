// @ts-nocheck
import axios from 'axios';

import API_ENDPOINTS from '../../const/ApiEndPoints';
import useIssueType from './useIssueType';
import useSummary from './useSummary';
import useLocalAttribute from './useLocalAttribute';
import useTemplate from './useTemplate';
import useSection from './useSection';

export default function useEntity() {
  const {
    createIssueType,
    deleteIssueType,
    getAllIssueTypesList,
    updateIssueType,
  } = useIssueType();
  const {
    getAllSummarySections,
    deleteSummarySections,
    updateSummarySections,
    createSummarySections,
  } = useSummary();
  const { addAttribute, deleteAttribute, updateAttribute } =
    useLocalAttribute();
  const {
    checkTemplateForAffectedEntities,
    reorderTemplate,
    deleteTemplate,
    getCustomEntityTemplate,
    getSingleEntity,
    customEntityList,
    createCustomEntity,
    updateCustomEntity,
    entityList,
    sectionData,
    setSectionData,
  } = useTemplate();
  const { createSection, deleteSection } = useSection();

  const getModelsDetailsOfWorkflowTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_MODEL_DETAILS_OF_WORKFLOW_CHANGES}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const transitionAllModels = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.TRANSITION_ALL_MODELS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSectionsDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_SECTIONS_DETAILS}`,
        { params: body }
      )
      .then((res) => {
        setSectionData(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const attachWorkflow = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.ATTACH_WORKFLOW}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getAllCustomEntityList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_ALL_CUSTOM_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getWorkflowRoles = (workflowName) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_WORKFLOW_ROLES}`,
        { params: workflowName }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const importTemplate = (payload) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.IMPORT_TEMPLATE}`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const downloadSampleTemplate = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.DOWNLOAD_SAMPLE_TEMPLATE}`,
        {
          responseType: 'arraybuffer', // Set responseType to 'arraybuffer' to handle binary data
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const exportTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.EXPORT_TEMPLATE}`,
        {
          params: body, // Place params here in the config object
          responseType: 'arraybuffer', // Set responseType to 'arraybuffer' to handle binary data
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  return {
    customEntityList,
    createCustomEntity,
    getSectionsDetails,
    createSection,
    sectionData,
    setSectionData,
    deleteSection,
    getCustomEntityTemplate,
    addAttribute,
    deleteAttribute,
    attachWorkflow,
    deleteTemplate,
    updateAttribute,
    reorderTemplate,
    entityList,
    updateCustomEntity,
    getSingleEntity,
    getAllCustomEntityList,
    createIssueType,
    updateIssueType,
    getAllIssueTypesList,
    deleteIssueType,
    getAllSummarySections,
    createSummarySections,
    updateSummarySections,
    deleteSummarySections,
    getModelsDetailsOfWorkflowTemplate,
    transitionAllModels,
    getWorkflowRoles,
    checkTemplateForAffectedEntities,
    importTemplate,
    downloadSampleTemplate,
    exportTemplate,
  };
}
