// @ts-nocheck
import React from 'react';
import { Box, Stack } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { withTransaction } from '@elastic/apm-rum-react';

import useAuth from '../../hooks/Auth/useAuth';
import AppHeader from '../../components/AppHeader/AppHeader';
import SidebarConfig from '../../components/Sidebar/SidebarConfig';
import TopNavBar from '../../components/AppHeader/TopNavBar';
import useEntity from '../../hooks/Configuration/useEntity';
import useNotification from '../../hooks/Configuration/useNotification';
import useDashboard from '../../hooks/ModelInventory/useDashboard';
import useCommon from '../../hooks/useCommon';

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setUserSetting, dispatch } = useCommon();
  const { getNotification, notificationList, clearAllNotification } =
    useNotification();
  const { fetchUserPermissions, users, getUserSetting } = useAuth();

  const getSettingsData = () => {
    getUserSetting().then((res) => {
      if (res) {
        dispatch(setUserSetting(res?.data?.user_setting));
      }
    });
  };

  const { getAllCustomEntityList } = useEntity();
  const { getKibanDashboard } = useDashboard();
  const [menus, setMenus] = React.useState(SidebarConfig);

  const getDashboardUrl = () => {
    let ORG;
    if (window.env.REACT_APP_KEYCLOAK_LOGIN) {
      ORG = localStorage.getItem('REALM_ID');
    } else {
      ORG = JSON.parse(localStorage.getItem('profile_data'))?.org;
    }
    getKibanDashboard({ org: ORG }).then((res) => {
      localStorage.setItem('kibanaDashboard', res?.data);
    });
  };
  React.useEffect(() => {
    if (pathname === '/') {
      navigate('/dashboard?value=0');
    }
    const copyOfMenu = cloneDeep(menus);
    copyOfMenu[1].permissions = [
      ...copyOfMenu[1].permissions,
      `ModelAssociation_template_All:View`,
      `ModelAssociation_template_All:Manage`,
    ];
    copyOfMenu[1].children = [
      {
        name: 'All Artifacts',
        route: '/model-artifact',
        key: 'modelAssociation',
        permissions: [
          `ModelAssociation_template_All:View`,
          `ModelAssociation_template_All:Manage`,
        ],
      },
    ];
    getDashboardUrl();
    getAllCustomEntityList({ entityType: 'ModelAssociation' }).then(
      (resp) => {
        if (resp?.data) {
          resp?.data?.forEach((association) => {
            copyOfMenu[1].children?.push({
              name: association,
              route: `/model-artifact?templateName=${association}`,
              key: 'modelAssociation',
              permissions: [
                `ModelAssociation_template_${association}:View`,
                `ModelAssociation_template_${association}:Manage`,
              ],
            });
            if (
              !copyOfMenu[1].permissions?.includes(
                `ModelAssociation_template_${association?.entity_name}:View`
              )
            ) {
              copyOfMenu[1].permissions = [
                ...copyOfMenu[1].permissions,
                `ModelAssociation_template_${association?.entity_name}:View`,
              ];
            }
          });

          setMenus([...copyOfMenu]);
        }
      },
      () => {
        // single route
        setMenus([...copyOfMenu]);
      }
    );
    fetchUserPermissions();
    getSettingsData();
  }, []);

  return (
    <Stack direction="column">
      <Box
        position="fixed"
        width="100%"
        sx={{ zIndex: (theme) => theme?.zIndex?.appBar }}
      >
        <AppHeader
          getNotification={getNotification}
          notificationList={notificationList}
          clearAllNotification={clearAllNotification}
        />
        <TopNavBar SidebarConfig={menus} userPermissions={users?.permissions} />
      </Box>

      <Box
        mt={15}
        sx={{ background: (theme) => theme?.palette?.primary?.contrastText }}
      >
        <Box
          sx={{
            minHeight: '300px',
            border: (_theme) => `1px solid ${_theme?.palette?.primary?.light}`,
            height: `calc(100vh - 152px)`,
            background: (_theme) => _theme?.palette?.secondary?.contrastText,
            overflow: 'auto',
          }}
          m={2}
        >
          <Outlet />
        </Box>
      </Box>
    </Stack>
  );
};

export default withTransaction('Layout', 'component')(Layout);
