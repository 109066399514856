// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { debounce } from 'lodash';

import ArticleCard from './ArticleCard';
import useHelpAndSupport from '../../../hooks/HelpAndSupport/useHelpAndSupport';

/**
 * @typedef ArticleSearchModalProps
 * @property {string[]} [tags] Tags of currently opened module
 * @property {boolean} [openLinksInNewTab = false] if true, articles will open in new tab
 */

const getSearchParams = (searchQuery, tags) => {
  const params = {};
  if (searchQuery) {
    params.search = searchQuery;
  }
  if (tags.length > 0) {
    params.tags = tags.join(',');
  }
  return params;
};

/**
 * @param {ArticleSearchModalProps & import("@mui/material").DialogProps} props
 */
const ArticleSearchModal = ({
  tags = [],
  onClose,
  openLinksInNewTab = false,
  open,
  ...props
}) => {
  const { searchArticles } = useHelpAndSupport();
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const noArticlesFound = searchQuery.length > 0 && results?.length === 0;
  const noSearchParam = tags?.length === 0 && searchQuery?.length === 0;

  const handleSearch = () => {
    // if modal not open, do not trigger search
    if (!open) {
      return;
    }
    const params = getSearchParams(searchQuery, tags);
    if (!params.search && !params.tags) {
      return;
    }
    searchArticles(params).then((res) => {
      setResults(res.data);
    });
  };
  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (open) {
      handleSearch();
    }
  }, [open]);

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery]);

  const handleClose = useCallback(
    (e) => {
      setSearchQuery('');
      setResults([]);
      if (onClose) {
        onClose(e, 'backdropClick');
      }
    },
    [onClose, setSearchQuery, setResults]
  );

  const handleSearchQueryChange = useCallback(
    (e) => {
      setSearchQuery(e?.target?.value);
    },
    [setSearchQuery]
  );

  return (
    <Dialog {...props} open={open} onClose={handleClose} disableRestoreFocus>
      <DialogTitle component={Stack} direction="row" alignItems="center">
        <Typography flexGrow={1}>Search Articles</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <TextField
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchQueryChange}
            autoFocus
          />
          <Divider />
          <Stack>
            {noArticlesFound && (
              <Stack alignItems="center">
                <Typography>No article found</Typography>
              </Stack>
            )}
            {noSearchParam && (
              <Stack alignItems="center">
                <Typography>Enter a query to begin search</Typography>
              </Stack>
            )}
            {results.map((article) => (
              <ArticleCard
                key={article.id}
                article={article}
                target={openLinksInNewTab ? '_blank' : '_self'}
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
ArticleSearchModal.propTypes = {
  searchDocuments: PropTypes.instanceOf(Promise).isRequired,
  onClose: PropTypes.func.isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
  openLinksInNewTab: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};
ArticleSearchModal.defaultProps = {
  openLinksInNewTab: false,
};

export default ArticleSearchModal;
