// @ts-nocheck
import React, { useCallback } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

const DropDown = React.memo(
  ({
    label,
    name,
    value,
    onChange,
    helperText,
    children,
    required = false,
    disabled,
    size = 'medium',
    styles = {},
    selectStyles = {},
    ...props
  }) => {
    const theme = useTheme();

    const getIconComponent = useCallback(
      (_props) => <DropdownArrow {..._props} aria-hidden="true" />,
      []
    );
    return (
      <FormControl
        required={required}
        fullWidth
        sx={{
          '& .MuiFormLabel-root': {
            ...(helperText ? { color: 'error.main' } : {}),
          },
          ...styles,
        }}
        size={size}
        aria-required={required}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disabled}
          label={label}
          name={name}
          value={value || ''}
          onChange={onChange}
          IconComponent={getIconComponent}
          error={Boolean(helperText)}
          sx={{
            ...selectStyles,
            '& > .MuiOutlinedInput-notchedOutline legend span': {
              ...(helperText ? { color: 'error.main' } : {}),
            },
            '& > svg path': {
              ...(helperText ? { stroke: theme.palette.error.main } : {}),
            },
          }}
          {...props}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: {
                  maxHeight: 'calc(100% - 60vh)',
                },
              },
            },
          }}
        >
          {children}
        </Select>
        <FormHelperText>
          <Typography color="error" variant="subtitle">
            {helperText}
          </Typography>
        </FormHelperText>
      </FormControl>
    );
  }
);
const DropdownItem = React.memo(({ children, ...props }) => (
  <MenuItem {...props}>
    <div
      style={{
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
      }}
    >
      <Typography variant="body2">{children}</Typography>
    </div>
  </MenuItem>
));
DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
};
DropDown.defaultProps = {
  required: false,
  disabled: false,
  size: 'medium',
  styles: {},
  selectStyles: {},
};
DropDown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  styles: PropTypes.oneOfType([PropTypes.object]),
  selectStyles: PropTypes.oneOfType([PropTypes.object]),
};

export { DropdownItem, DropDown };
