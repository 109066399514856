// @ts-nocheck
/* eslint-disable react/prop-types */
import { Autocomplete, Box, Chip, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import CustomPopover from './CustomPopover';

const SELECT_ALL_OPTIONS = {
  label: 'Select all options',
  value: 'mui_select_all',
};

const DefaultChipComponent = ({ index, option, getTagProps }) => {
  return <Chip {...getTagProps({ index })} label={option?.label} />;
};

const Multiselect = ({
  options,
  limitTags = 1,
  CustomChipComponent = DefaultChipComponent,
  noOptionsMessage = 'No options present.',
  disableSelectAll = false,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);

  /**
   * @function renderTags
   * @description Renders multiselect tags using Material-UI Chip components.
   * @param {Array} value - Array of tag options.
   * @param {Function} getTagProps - Function to get tag props.
   * @returns {JSX.Element}
   */

  const renderTags = useCallback(
    (value, getTagProps) => {
      const numTags = value?.length;

      if (isFocus) {
        return (
          <Box
            sx={{ display: 'flex', overflow: 'scroll', alignItems: 'center' }}
          >
            {value?.map((option, index) => (
              <CustomChipComponent
                key={option?.value}
                index={index}
                option={option}
                getTagProps={getTagProps}
              />
            ))}
          </Box>
        );
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 'calc(100% - 20px)',
          }}
        >
          {value?.slice(0, limitTags).map((option, index) => (
            <CustomChipComponent
              key={option?.value}
              index={index}
              option={option}
              getTagProps={getTagProps}
            />
          ))}
          {numTags > limitTags ? <Box>{` +${numTags - limitTags}`}</Box> : null}
        </Box>
      );
    },
    [isFocus, limitTags]
  );

  const handleOnFocus = useCallback(() => setIsFocus(true), [setIsFocus]);
  const handleOnBlur = useCallback(() => setIsFocus(false), [setIsFocus]);

  // When we want to disable the select all option the disableSelectAll prop is used
  const optionsData = useMemo(() => {
    if (disableSelectAll) {
      return options?.length ? [...options] : [];
    }
    return options?.length ? [SELECT_ALL_OPTIONS, ...options] : [];
  }, [disableSelectAll, options, SELECT_ALL_OPTIONS]);

  const handleChange = useCallback(
    (_e, value) => {
      if (value.includes(SELECT_ALL_OPTIONS)) {
        return props.onChange(options);
      }
      return props.onChange(value);
    },
    [props?.onChange, options]
  );
  return (
    <Autocomplete
      {...props}
      fullWidth
      options={optionsData}
      limitTags={limitTags}
      onChange={handleChange}
      noOptionsText={
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.other.grey2 }}
        >
          {noOptionsMessage}
        </Typography>
      }
      multiple
      renderTags={renderTags}
      sx={{
        '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
          flexWrap: 'nowrap',
        },
      }}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      PopperComponent={CustomPopover}
    />
  );
};

export default Multiselect;
