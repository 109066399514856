const VAULT_BASE_URL = '/vault';
const BULK_UPLOAD_URL = `${VAULT_BASE_URL}/bulkupload`;
const AUTH_URL = `${VAULT_BASE_URL}/auth`;
const O_AUTH_URL = `${VAULT_BASE_URL}/oauth`;
const CUSTOM_ENTITY_URL = `${VAULT_BASE_URL}/custom-entity`;
const MODEL_ENTITY_URL = `${VAULT_BASE_URL}/model-entity`;
const BULK_TRANSITION_URL = `${VAULT_BASE_URL}/bulktransition`;
const CUSTOM_REPORTS_URL = `${VAULT_BASE_URL}/customreports`;
const DYNAMIC_CHARTS_URL = `${VAULT_BASE_URL}/dynamiccharts`;
const LLM_URL = `${VAULT_BASE_URL}/llm`;
const AUTOMATION_URL = `${VAULT_BASE_URL}/automation`;
const AUTOMATED_PIPELINE_URL = '/ad/automated_pipeline';
const ME_ESTIMATION_URL = '/me/mestimation/vault';
const RESOURCE_TEAM = `${VAULT_BASE_URL}/team`;
const RESOURCE_PLANING = `${RESOURCE_TEAM}/effort-management`;

const API_ENDPOINTS = {
  HOSTS_NEEDS_TOKEN: [window.env.REACT_APP_HOSTS_NEEDS_TOKEN],
  MODULE_BASE_URL: {
    AUTH: window.env.REACT_APP_VAULT_BASE_URL,
  },
  AUTH: {
    LOGIN_USER: `${AUTH_URL}/login/`,
    LOGOUT_USER: `${AUTH_URL}/logout/`,
    GET_ALL_USER: `${AUTH_URL}/getAlluser`,
    CREATE_USER: `${AUTH_URL}/register/`,
    GET_ALL_ROLES: `${VAULT_BASE_URL}/roles`,
    USER_PERMISSION: `${AUTH_URL}/getUserPermission`,
    UPDATE_USER: `${VAULT_BASE_URL}/profile/update/`,
    SUSPEND_USER: `${AUTH_URL}/suspend`,
    REFRESH_TOKEN: `${AUTH_URL}/token/refresh/`,
    FORGET_PASSWORD: `${AUTH_URL}/request_reset_email/`,
    RESET_PASSWORD: `${AUTH_URL}/password_reset_complete/`,
    USER_SETTING: `${AUTH_URL}/user-settings`,
    IS_LOGGED_IN_USER_ADMIN_OF_GROUP: `${AUTH_URL}/isAdmin`,
  },
  NOTIFICATIONS: {
    GET_NOTIFICATION: `${VAULT_BASE_URL}/notification/getAll`,
    CLEAR_NOTIFICATION: `${VAULT_BASE_URL}/notification/clearAll`,
    READ_UNREAD: `${VAULT_BASE_URL}/notification/Marksingleread`,
    MARK_ALL_READ: `${VAULT_BASE_URL}/notification/MarkAsRead`,
  },
  CE: {
    CREATE_ENTITY: CUSTOM_ENTITY_URL,
    GET_ALL_ENTITY: CUSTOM_ENTITY_URL,
    GET_SINGLE_ENTITY: CUSTOM_ENTITY_URL,
    CREATE_SECTION: `${CUSTOM_ENTITY_URL}/section`,
    GET_SECTIONS_DETAILS: `${CUSTOM_ENTITY_URL}/section`,
    REMOVE_SECTION: `${CUSTOM_ENTITY_URL}/section`,
    CREATE_ATTRIBUTES: `${CUSTOM_ENTITY_URL}/attribute`,
    GET_ATTRIBUTES: `${VAULT_BASE_URL}/get-attribute`,
    REMOVE_ATTRIBUTE: `${CUSTOM_ENTITY_URL}/attribute`,
    ATTACH_WORKFLOW: `${CUSTOM_ENTITY_URL}/workflow`,
    DELETE_TEMPLATE: CUSTOM_ENTITY_URL,
    REORDER_TEMPLATE: `${VAULT_BASE_URL}/orderRearrange`,
    GET_ALL_CUSTOM_ENTITY: `${CUSTOM_ENTITY_URL}/getAll`,
    GET_MODEL_DETAILS_OF_WORKFLOW_CHANGES: `${CUSTOM_ENTITY_URL}/updateworkflow`,
    TRANSITION_ALL_MODELS: `${CUSTOM_ENTITY_URL}/updateworkflow`,
    GET_WORKFLOW_ROLES: `${VAULT_BASE_URL}/workflow/getAllRoles`,
    CHECK_TEMPLATE: `${CUSTOM_ENTITY_URL}/checkTemplate`,
    IMPORT_TEMPLATE: `${CUSTOM_ENTITY_URL}/importTemplate`,
    DOWNLOAD_SAMPLE_TEMPLATE: `${CUSTOM_ENTITY_URL}/sampleTemplateExcel`,
    EXPORT_TEMPLATE: `${CUSTOM_ENTITY_URL}/exportTemplate`,
  },
  MI: {
    READ_ALL_MODEL_ENTITY: `${MODEL_ENTITY_URL}/readAll`,
    CREATE_MODEL_ENTITY_FIRST_STEP: `${MODEL_ENTITY_URL}/createModel`,
    CREATE_MODEL_ENTITY_SECOND_STEP: `${MODEL_ENTITY_URL}/updateModel/attribute`,
    CREATE_MODEL_ENTITY_THIRD_STEP: `${MODEL_ENTITY_URL}/createModel/addModelAssociaton`,
    CREATE_MODEL_ENTITY_FORTH_STEP: `${MODEL_ENTITY_URL}/createModel/addModelTeam`,
    ATTRIBUTE_DETAILS: `${MODEL_ENTITY_URL}/read`,
    TEAM_DETAILS: `${MODEL_ENTITY_URL}/modelTeam`,
    ASSOCIATION_DETAILS: `${MODEL_ENTITY_URL}/read/modelAssociation`,
    ADD_SINGLE_TEAM_MEMBER: `${MODEL_ENTITY_URL}/createModel/addSingleTeam`,
    DELETE_ENTITY: `${MODEL_ENTITY_URL}/removeModel`,
    MODEL_APPROVE: `${VAULT_BASE_URL}/workflow/NextStage`,
    SAVE_FILTER: `${VAULT_BASE_URL}/savesearch`,
    REMOVE_TEAM_MEMBER: `${MODEL_ENTITY_URL}/removeModel/teamMember`,
    REMOVE_MODEL_ASSOCIATION: `${MODEL_ENTITY_URL}/removeModel/association`,
    GET_SINGLE_ENTITY: `${MODEL_ENTITY_URL}/readSingle`,
    HISTORY: `${MODEL_ENTITY_URL}/modelHistory`,
    GET_LOG_ACTIONS: `${MODEL_ENTITY_URL}/getLogactions`,
    GET_LOG_ACTIONS_ATTRIBUTES: `${MODEL_ENTITY_URL}/getLogattributes`,
    GET_NOTES: `${MODEL_ENTITY_URL}/modelNotes`,
    GET_REPLIES: `${VAULT_BASE_URL}/replies`,
    GET_TRANSITION_STATES: `${VAULT_BASE_URL}/transitionstates`,
    GET_LIFECYCLE_NOTES: `${VAULT_BASE_URL}/lifecycle-note`,
    NOTES: `${VAULT_BASE_URL}/notes`,
    ADD_NOTES: `${MODEL_ENTITY_URL}/updateModel/addNote`,
    DELETE_FILTER: `${VAULT_BASE_URL}/savesearch`,
    EDIT_FILTER: `${VAULT_BASE_URL}/search`,
    GET_ALERTS: `${VAULT_BASE_URL}/alerts`,
    UPDATE_MODEL: `${MODEL_ENTITY_URL}/updateModel`,
    EXCEL_IMPORT: `${VAULT_BASE_URL}/bulkupload/template`,
    EXCEL_UPLOAD: `${VAULT_BASE_URL}/bulkupload/upload/`,
    GET_VERSIONS: `${VAULT_BASE_URL}/versions`,
    ROLLBACK_VERSION: `${VAULT_BASE_URL}/versions_rollback`,
    VERSIONS_COMPARISON: `${VAULT_BASE_URL}/versions_comparison`,
    GET_REPORTS: `${VAULT_BASE_URL}/reports`,
    GET_CURRENT_STATES: `${MODEL_ENTITY_URL}/status`,
    GET_MODEL_LINAGE: `${VAULT_BASE_URL}/graphplotforsingleentity/getnodes`,
    WORKFLOW_CHECKLIST: `${VAULT_BASE_URL}/workflow/checklist`,
    ALERT_EVENT: `${MODEL_ENTITY_URL}/alert_events`,
    MODEL_AVAILABLE: `${MODEL_ENTITY_URL}/available`,
    GET_FIELDS_DATA_TYPES: `${MODEL_ENTITY_URL}/getDataType`,
    GET_STATE_DETAILS: `${MODEL_ENTITY_URL}/StatesDetails`,
    GET_SHOW_IN_SUMMARY: `${MODEL_ENTITY_URL}/showInSummary`,
    GET_TEMPLATE_ASSOCIATIONS: `${MODEL_ENTITY_URL}/getLifecycleDetails`,
    GET_PARENT_ASSOCIATION: `${MODEL_ENTITY_URL}/read/parentAssociation`,
    ISSUE_DATA: `${MODEL_ENTITY_URL}/read/validationArtifacts`,
    SEARCH_MODELS: `${VAULT_BASE_URL}/search-notes-entity`,
    GET_GLOBAL_ATTRIBUTE_VALUE: `${VAULT_BASE_URL}/globalattribute-value`,
    GET_LOCAL_ATTRIBUTE_VALUE: `${VAULT_BASE_URL}/attribute-value`,
    GET_MODEL_PREVIOUS_STATE: `${MODEL_ENTITY_URL}/getModelPreviousState`,
    RECLAIM_STATE: `${MODEL_ENTITY_URL}/modelReclaim`,
    DOWNLOAD_LOGS: `${MODEL_ENTITY_URL}/modelHistoryDownload`,
    GET_USER_ROLE_PERMISSIONS: `${MODEL_ENTITY_URL}/getUserPermission`,
    GET_MAPPED_VALUES: `${MODEL_ENTITY_URL}/getMappedValues`,
    DOWNLOAD_REPORTS_DOCUMENT: `${VAULT_BASE_URL}/activityReport`,
    GET_ALL_ASSOCIATIONS: `${MODEL_ENTITY_URL}/get_all_associations`,
  },
  DOC: {
    MODEL_DOCUMENTS: `${VAULT_BASE_URL}/document`,
    UPLOAD_DOCUMENTS: `${VAULT_BASE_URL}/upload-document`,
    CREATE_DOCUMENT: `${VAULT_BASE_URL}/create-document`,
    EDIT_DOCUMENTS: `${VAULT_BASE_URL}/edit_document`,
    UPLOAD_TEMPLATE: `${VAULT_BASE_URL}/documenttemplateupload`,
    DOCUMENT_TEMPLATE: `${VAULT_BASE_URL}/documenttemplate`,
    DOCUMENT_ONEDRIVE_URL: `${VAULT_BASE_URL}/documentonedriveview`,
    DOCUMENT_TREE: `${VAULT_BASE_URL}/doc_tree`,
    DOWNLOAD_DOCUMENT: `${VAULT_BASE_URL}/export-document`,
    REPLACE_DOCUMENT: `${VAULT_BASE_URL}/update-document`,
    GET_TAGS: `${VAULT_BASE_URL}/documentTags`,
    DOWNLOAD_WEAKNESS_ASSESSMENT: `${VAULT_BASE_URL}/llm/doc-assess`,
  },
  RR: {
    RULES: `${VAULT_BASE_URL}/rules`,
  },
  WF: {
    CREATE_WORKFLOW: `${VAULT_BASE_URL}/workflow/create`,
    CLONE_WORKFLOW: `${VAULT_BASE_URL}/workflow/clone`,
    GET_WORKFLOW_LIST: `${VAULT_BASE_URL}/workflow/viewAll`,
    COMPILE_FLOW: `${VAULT_BASE_URL}/workflow/recompile`,
    UPDATE_FLOW: `${VAULT_BASE_URL}/workflow/update`,
    GET_SINGLE_WORKFLOW: `${VAULT_BASE_URL}/workflow/view`,
    GET_ALL_STATES: `${VAULT_BASE_URL}/workflow/Allstate`,
    NEXT_POSSIBLE_STATE: `${VAULT_BASE_URL}/workflow/getNextState`,
    DELETE_WORKFLOW: `${VAULT_BASE_URL}/workflow/delete`,
    CHECK_WORKFLOW_STATE_DELETABLE: `${VAULT_BASE_URL}/workflow/checkstate`,
  },
  GA: {
    GLOBAL_ATTRIBUTES: `${VAULT_BASE_URL}/globalattributes`,
  },
  DB: {
    GET_FILTER: `${VAULT_BASE_URL}/savesearch`,
    GET_FILTERED_DATA: `${VAULT_BASE_URL}/search`,
    GET_NODES: `${VAULT_BASE_URL}/graphplot/getnodes/`,
    PLOT_GRAPH: `${VAULT_BASE_URL}/graphplot/plotgraph/`,
    FULL_SEARCH: `${VAULT_BASE_URL}/fullsearch`,
    PIPELINE: `${VAULT_BASE_URL}/pipeline`,
    DASHBOARD_REPORTS: `${VAULT_BASE_URL}/dashreports`,
    UPDATE_ALERT: `${VAULT_BASE_URL}/update_alert_field`,
    ALL_EVENTS: `${VAULT_BASE_URL}/allevents`,
    EVENTS_COUNT: `${VAULT_BASE_URL}/get_events_count`,
    GET_ALERTS: `${VAULT_BASE_URL}/alert`,
    GET_KIBAN_DASHBOARD: `${VAULT_BASE_URL}/kibanadashboard`,
    GET_PENDING_TASKS: `${MODEL_ENTITY_URL}/MyTask`,
    GET_OPEN_TASKS: `${VAULT_BASE_URL}/auth/openTasks`,
    GET_TASK_QUEUE: `${VAULT_BASE_URL}/worker-queue`,
    GET_TASK_RESULT: `${VAULT_BASE_URL}/get-single-task`,
    TOGGLE_RULE: `${MODEL_ENTITY_URL}/toggleDelegation`,
    SAVE_RULE: `${MODEL_ENTITY_URL}/saveRules`,
    CLAIM_DELEGATION: `${MODEL_ENTITY_URL}/claimDelegation`,
    DELEGATE_TASK: `${MODEL_ENTITY_URL}/taskDelegate`,
    GET_DELEGATED_TASK: `${MODEL_ENTITY_URL}/getDelegatedTasks`,
    GET_DELEGATED_RULE: `${MODEL_ENTITY_URL}/saveRules`,
    GET_TEAM_EFFORT_LIST: `${RESOURCE_PLANING}/members`,
    GET_MEMBER_TASKS: `${RESOURCE_PLANING}/detail`,
    GET_OPEN_TASK_COUNT: `${RESOURCE_PLANING}/opentask-count`,
    GET_TEMPLATES: `${RESOURCE_TEAM}/status-management`,
    GET_TEMPLATES_TASK_DETAILS: `${RESOURCE_TEAM}/status-management/detail`,
  },
  DI: {
    GET_INVENTORY: `${VAULT_BASE_URL}/docinv`,
    DOC_SEARCH: `${VAULT_BASE_URL}/documentsearch`,
    GET_DOC_MARKDOWN: `${VAULT_BASE_URL}/file-content`,
    GET_VERSION_DETAILS: `${VAULT_BASE_URL}/doc-version`,
    ROLL_BACK_VERSION: `${VAULT_BASE_URL}/rollback-document`,
  },
  BU: {
    GET_FILES: `${BULK_UPLOAD_URL}/files/`,
    CREATE_MODEL: `${BULK_UPLOAD_URL}/create-model/`,
    DELETE_FILE: `${BULK_UPLOAD_URL}/files/delete`,
  },
  UM: {
    CREATE_GROUP: `${AUTH_URL}/creategroup`,
    DELETE_GRPUP: `${AUTH_URL}/deletegroup`,
    GET_GROUPS: `${AUTH_URL}/readgroup`,
    UPDATE_GROUP: `${AUTH_URL}/updategroup`,
    GET_GROUP_TASKS: `${AUTH_URL}/getGroupTask`,
    ASSIGN_TASK: `${AUTH_URL}/assignTask`,
    GET_ROLES: `${VAULT_BASE_URL}/roles`,
    GET_ISSUE_TYPE: `${CUSTOM_ENTITY_URL}/issue-type`,
    CREATE_ISSUE_TYPE: `${CUSTOM_ENTITY_URL}/issue-type`,
    UPDATE_ISSUE_TYPE: `${CUSTOM_ENTITY_URL}/issue-type`,
    DELETE_ISSUE_TYPE: `${CUSTOM_ENTITY_URL}/issue-type`,
    GET_SUMMARY_SECTIONS: `${MODEL_ENTITY_URL}/modelSummarySection`,
    CREATE_SUMMARY_SECTIONS: `${MODEL_ENTITY_URL}/modelSummarySection`,
    UPDATE_SUMMARY_SECTIONS: `${MODEL_ENTITY_URL}/modelSummarySection`,
    DELETE_SUMMARY_SECTIONS: `${MODEL_ENTITY_URL}/modelSummarySection`,
  },
  AM: {
    REGISTER_APP: `${O_AUTH_URL}/register`,
    GET_ALL_APPS: `${O_AUTH_URL}/all-applications`,
  },
  BA: {
    GET_ALL_MODELS: `${BULK_TRANSITION_URL}/allModel`,
    GET_STATES: `${BULK_TRANSITION_URL}/getState`,
    BULK_TRANSITION: `${BULK_TRANSITION_URL}/transition`,
  },
  DC: {
    GET_ALL_REPORT_DETAILS: CUSTOM_REPORTS_URL,
    SAVE_CHART: CUSTOM_REPORTS_URL,
    DELETE_CHART: CUSTOM_REPORTS_URL,
    GET_COLUMNS_NAMES: `${DYNAMIC_CHARTS_URL}/columns`,
    GET_COLUMNS_METADATA: DYNAMIC_CHARTS_URL,
    CHECK_CHART_EXIST: `${VAULT_BASE_URL}/checkdynamicchartexists`,
  },
  NUI: {
    GET_ALL_MODELS: `${ME_ESTIMATION_URL}/allmodels`,
    GET_ALL_PROJECTS: `${MODEL_ENTITY_URL}/getnimbusprojectlist`,
    INTEGRATE_ENTITY: `${MODEL_ENTITY_URL}/enablemodelintegration`,
    GET_ALL_PIPELINES: `${AUTOMATED_PIPELINE_URL}/pipelinelist`,
    RUN_PIPELINE: `${AUTOMATED_PIPELINE_URL}/runpipeline`,
    PIPELINE_STATUS: `${AUTOMATED_PIPELINE_URL}/pipelinestatus`,
  },
  LLM: {
    DOC_CHAT: `${LLM_URL}/chat`,
    ENTITY_CHAT: `${LLM_URL}/modelchat`,
    DOC_AVAILABLE: `${LLM_URL}/check`,
    DOC_UPLOAD: `${LLM_URL}/document`,
    ENTITY_UPLOAD: `${LLM_URL}/modeltrain`,
  },
  AUT: {
    CREATE_AUTOMATION: AUTOMATION_URL,
    UPDATE_AUTOMATION: AUTOMATION_URL,
    GET_AUTOMATION: AUTOMATION_URL,
    DELETE_AUTOMATION: AUTOMATION_URL,
  },
  HELP_SUPPORT: {
    DOCUMENTS: `${VAULT_BASE_URL}/admin-help`,
    RESTRUCTURE: `${VAULT_BASE_URL}/admin-help/restructure`,
  },
};
export default API_ENDPOINTS;
