export const PATH_TAGS = {
  '/my-model': ['Model Inventory', 'My Models'],
  '/my-model/:uniqueId': ['Model Inventory', 'Edit Model'],
  '/dashboard': ['Dashboard'],
  '/bulk-upload': ['Bulk Actions', 'Bulk Upload'],
  '/bulk-transition': ['Bulk Actions', 'Bulk Transition'],
  '/model-artifact': ['Model Artifacts'],
  '/model-artifact/:uniqueId': ['Model Artifacts', 'Edit Model Artifacts'],
  '/model-validation': ['Model Validation'],
  '/templates/create-template': [
    'Configuration',
    'Templates',
    'Create Template',
  ],
  '/templates/edit-template': ['Configuration', 'Templates', 'Edit Template'],
  '/workflows': ['Configuration', 'Workflows', 'Edit Workflow'],
  '/workflows/edit-workflow': ['Configuration', 'Workflows', 'Edit Workflow'],
  '/templates': ['Configuration', 'Templates', 'Edit Template'],
  '/global-attributes': [
    'Configuration',
    'Global Attributes',
    'Edit Global Attribute',
  ],
  '/rules': ['Rules'],
  '/report': ['Reports'],
  '/user-management': ['Administrative Options', 'User Management'],
  '/app-management': ['Administrative Options', 'App Management'],
  '/document-inventory': ['Document Inventory'],
  '/document-template': ['Document Inventory', 'Document Template'],
  '/dynamic-chart': ['Configuration', 'Dynamic Chart'],
  '/automation': ['Configuration', 'Automation'],
  '/automation/create-automation': [
    'Configuration',
    'Automation',
    'Create Automation',
  ],
  '/automation/update-automation': [
    'Configuration',
    'Automation',
    'Update Automation',
  ],
  '/not-authorized': [],
  '/tool-request': ['Model Inventory', 'Tool Request'],
  '/tool-request/:uniqueId': ['Model Inventory', 'Edit Tool Request'],
};
