import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

const useHelpAndSupport = () => {
  const getArticleTree = () =>
    axios.get(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.DOCUMENTS}`
    );

  const searchArticles = (params) =>
    axios.get(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.DOCUMENTS}`,
      {
        params,
      }
    );

  const restructureArticleTree = (body) =>
    axios.patch(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.RESTRUCTURE}`,
      body
    );

  const updateArticle = (body) =>
    axios.patch(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.DOCUMENTS}`,
      body
    );

  const deleteArticle = (body) =>
    axios.delete(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.DOCUMENTS}`,
      {
        data: body,
      }
    );

  const createArticle = (body) =>
    axios.post(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.HELP_SUPPORT.DOCUMENTS}`,
      body
    );

  return {
    getArticleTree,
    searchArticles,
    restructureArticleTree,
    updateArticle,
    deleteArticle,
    createArticle,
  };
};

export default useHelpAndSupport;
